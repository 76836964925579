import React, { useEffect, useState } from "react";
import "./GroupingTaskList.css";
import Cookies from "js-cookie";
import Filter from "../Filters/Filter";
import UserFilter from "../Filters/UserFilter";
import "./GroupingTaskList.css";
import DepartmentManager from "../Filters/DepartmentManager";
import axios from "axios";

export default function TaskList() {
  const Permission = Cookies.get("permission");
  const UserID = Cookies.get("userID");
  const Department = Cookies.get("departments");

  const [data, setdata] = useState([]);
  // const [localData, setLocalData] = useState(localStorage.getItem("guncel"));

  const loadTasks = async () => {
    const storedFilters = JSON.parse(localStorage.getItem("guncel"));
    const storedFiltersData = JSON.parse(
      localStorage.getItem("selectedUserData")
    );
    try {
      if (storedFilters) {
        // console.log("local var");
        // console.log(storedFilters);
        axios
          .post(
            process.env.react_app_productMode === "true"
              ? process.env.react_app_productUrl + "task/filter"
              : process.env.react_app_localUrl + "task/filter",
            storedFilters
          )
          .then(({ data }) => {
            const HelperArray = data?.map((el) => ({ ...el, id: el._id }));
            setdata(HelperArray);
          });
      } else if (storedFiltersData) {
        // console.log("multiuserData");
        const HelperArray = storedFiltersData?.map((el) => ({
          ...el,
          id: el._id,
        }));
        setdata(HelperArray);
        // setdata(storedFiltersData);
      } else {
        const userCheck = {
          Permission: Permission,
          UserID: UserID,
          Department: Department,
        };

        // console.log(userCheck + "local yok");
        axios
          .post(
            process.env.react_app_productMode === "true"
              ? process.env.react_app_productUrl + "tasks"
              : process.env.react_app_localUrl + "tasks",
            userCheck
          )
          .then(({ data }) => {
            const HelperArray = data?.map((el) => ({ ...el, id: el._id }));
            setdata(HelperArray);
          });
      }
    } catch (error) {
      console.log(error);
      localStorage.removeItem("guncel");
      localStorage.removeItem("selectedUserFilter");
      localStorage.removeItem("selectedUserFilterDate");

      localStorage.removeItem("selectedUserData");
      localStorage.removeItem("selectedUserLocal");
    }
  };

  const permission = Cookies.get("permission");
  let [users, setUsers] = useState([]);
  let [department, setDepartments] = useState([]);

  const LoadUser = async () => {
    try {
      var axios = require("axios");
      var config = {
        method: "get",

        url:
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "Users"
            : process.env.react_app_localUrl + "Users",

        headers: {},
      };
      const Result = await axios(config);
      const Users = Result.data;
      const EditUsers = Users.map((el) => ({
        ...el,
        label: el.Username,
        value: el.Username,
      }));
      setUsers(EditUsers);
    } catch (error) {
      console.log(error);
    }
  };
  const LoadDepartment = async () => {
    try {
      var axios = require("axios");
      var config = {
        method: "get",

        url:
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "departments"
            : process.env.react_app_localUrl + "departments",
        headers: {},
      };
      const Result = await axios(config);
      const Department = Result.data;
      const EditDepartment = Department.map((el) => ({
        ...el,
        label: el.Department,
        value: el.Department,
      }));
      setDepartments(EditDepartment);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    loadTasks();
    LoadUser();
    LoadDepartment();
  }, []);

  if (permission === "admin") {
    return (
      <div>
        <Filter data={data} setdata={setdata} />
      </div>
    );
  }
  if (permission === "DepartmentManager") {
    return (
      <div>
        <DepartmentManager data={data} setdata={setdata} />
      </div>
    );
  } else {
    return (
      <div className="task-list">
        <UserFilter
          data={data}
          setdata={setdata}
          users={users}
          department={department}
        />
      </div>
    );
  }
}
