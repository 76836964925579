import React, { useState, useEffect } from "react";
import { Box, Paper } from "@mui/material/";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import UserEdit from "./UserEdit";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import "./Table.css";

function Row(props) {
  const { row, setUsers } = props;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const permission = Cookies.get("permission");

  const UserEdit = (_id) => {
    navigate(`userEdit/${row._id}`);
  };

  const RemoveTask = (_id) => {
    // setUsers((prevRows) => prevRows.filter((row) => row.id !== _id));
    const id = row._id;

    try {
      fetch(
        process.env.react_app_productMode === "true"
          ? process.env.react_app_productUrl + `useredit/delete/` + id
          : process.env.react_app_localUrl + `useredit/delete/` + id,

        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
        }
      );
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } catch (error) {
      alert("Başarısız oldu ");
    }
  };

  const AlertDelete = (_id) => {
    Swal.fire({
      title: "Emin Misiniz?",
      text: "Bu kullanıcıyı silmek üzeresiniz bunu geri alamazsınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "İptal",
      confirmButtonText: "Evet, Sil!",
    }).then((result) => {
      if (result.isConfirmed) {
        RemoveTask(row._id);
        Swal.fire("Silindi!", "Bu Görev Başarıyla Silindi.", "success");
      }
    });
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row">
          {row.Username}
        </TableCell>
        <TableCell>{row.Department}</TableCell>
        <TableCell>{row.Permission}</TableCell>

        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(params) => UserEdit(params._id)}
          >
            {<EditIcon />}
          </IconButton>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => AlertDelete()}
          >
            {<DeleteIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                color="#121212"
              >
                İletişim Bilgileri :
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ bgcolor: "#EE315D", color: "white" }}>
                      Telefon
                    </TableCell>
                    <TableCell sx={{ bgcolor: "#EE315D", color: "white" }}>
                      Mail
                    </TableCell>
                    <TableCell sx={{ bgcolor: "#EE315D", color: "white" }}>
                      Adres
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.Phone}>
                    <TableCell component="th" scope="row" sx={{ border: 0 }}>
                      {row.Phone}
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ border: 0 }}>
                      {row.Mail}
                    </TableCell>
                    <TableCell sx={{ border: 0 }}>{row.Address}</TableCell>
                    {/* <TableCell>{historyRow.amount}</TableCell>
                      <TableCell align="right" className="border-white">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ShowUsers() {
  let [Users, setUsers] = useState([]);
  const permission = Cookies.get("permission");
  const department = Cookies.get("departments");

  const LoadUser = async () => {
    try {
      var axios = require("axios");
      var config = {
        method: "get",
        url:
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "Users"
            : process.env.react_app_localUrl + "Users",

        headers: {},
      };
      const Result = await axios(config);
      const Users = Result.data;
      const EditUsers = Users.map((el) => ({
        ...el,
        label: el.Username,
        value: el.Username,
        UserID: el._id,
      }));
      setUsers(EditUsers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    LoadUser();
  }, []);

  return (
    <div className="table-display">
      <br />

      <TableContainer sx={{ mt: 5 }} component={Paper}>
        <Table>
          <caption>© 2022 Görev Takip Sistemi Kullanıcı Listesi</caption>
          <TableHead>
            <TableRow>
              <TableCell>İletişim Detay</TableCell>
              <TableCell>Ad-Soyad</TableCell>
              <TableCell>Departman</TableCell>
              {/* <TableCell className="p-2 text-left">Başlangıç Tarihi</TableCell>
              <TableCell className="p-2 text-left">Doğum Tarihi</TableCell> */}
              <TableCell>Statu</TableCell>
              <TableCell>Seçenekler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="flex-1 sm:flex-none ">
            {permission == "DepartmentManager"
              ? Users.filter((el) => el.Department == department).map((row) => (
                  <Row key={row.Username} row={row} setUsers={setUsers} />
                ))
              : Users.map((row) => (
                  <Row key={row.Username} row={row} setUsers={setUsers} />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
