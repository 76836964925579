import { AuthContext } from "../Contexts/AuthContext";
import { useContext } from "react";

export const useAuthContext=(error)=>{

    const context=useContext(AuthContext)
    if (!context) {
        throw new error.message('Context bulunamadı')
    }
    return context;
}