import * as React from "react";
import "./UserAdd.css";
import { Tabs, Tab } from "@mui/material/";
import ShowUsers from "./ShowUsers";
import Cookies from "js-cookie";

import Users from "./Users";
import DeptUserAdd from "./DeptUserAdd";

function TabPanel(props) {
  const { children, value, index } = props;
  //if 'value'(current user selection) and 'index'(the corresponding index of the tab) is equal, then display the component
  return <div>{value === index && <div>{children}</div>}</div>;
}

export default function AddUserTAb() {
  const Permission = Cookies.get("permission");
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="Tabs">
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Kullanıcı Ekle" />
          <Tab label="Tüm Kullanıcılar" />
        </Tabs>
      </div>
      {Permission === "admin" && (
        <TabPanel value={value} index={0}>
          <Users />
        </TabPanel>
      )}
      {Permission === "DepartmentManager" && (
        <TabPanel value={value} index={0}>
          <DeptUserAdd />
        </TabPanel>
      )}
      <TabPanel value={value} index={1}>
        <ShowUsers />
      </TabPanel>
    </div>
  );
}
