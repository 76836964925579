import Button from "@mui/material/Button";
import { TextField, Box } from "@mui/material/";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
} from "@mui/material/";
import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import { TaskContext } from "../../Contexts/TaskContext";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
function RedBar() {
  return (
    <Box
      sx={{
        height: 20,
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? "rgba(255, 0, 0, 0.1)"
            : "rgb(255 132 132 / 25%)",
      }}
    />
  );
}

export default function Popup() {
  const navigate = useNavigate();
  const { id } = useParams();
  const userid = Cookies.get("userID");
  const username = Cookies.get("username");
  const departments = Cookies.get("departments");
  const context = useContext(TaskContext);
  const [open, setOpen] = React.useState(false);
  const [data, setdata] = useState([]);

  const [notes, setNotes] = useState([]);
  const [oldNotes, setOldNotes] = useState([]);
  const [note, setNote] = useState("");
  const [currentDate, setCurrentDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm:ss")
  );

  const loadNotes = async () => {
    const result = await axios.get(
      process.env.react_app_productMode === "true"
        ? process.env.react_app_productUrl + `task/note/${id}`
        : process.env.react_app_localUrl + `task/note/${id}`
    );

    const Notes = result.data;
    const EditNote = Notes.map((el) => ({ ...el }));
    setOldNotes(EditNote);
  };

  useEffect(() => {
    loadTask();
    loadNotes();
  }, []);

  const onSubmit = async (e) => {
    try {
      var axios = require("axios");

      if (note !== "") {
        var data = JSON.stringify({
          TaskID: id,
          UserID: userid,

          Date:
            new Date(
              moment(currentDate).format("YYYY-MM-DD HH:mm:ss")
            ).getTime() / 1000,

          Note: note,
          Username: username,
          Department: departments,
        });

        var config = {
          method: "post",

          url:
            process.env.react_app_productMode === "true"
              ? process.env.react_app_productUrl + `task/note/new`
              : process.env.react_app_localUrl + `task/note/new`,

          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        const result = await axios(config);
        setdata(result);
        if (result.data.msg === "not oluşturuldu!") {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Not Oluşturuldu!",
            showConfirmButton: false,
            timer: 3000,
          });
        }

        navigate("/");
      } else {
        console.log("değer giriniz");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  const loadTask = () => {
    axios
      .get(
        process.env.react_app_productMode === "true"
          ? process.env.react_app_productUrl + `task/note/${id}`
          : process.env.react_app_localUrl + `task/note/${id}`
      )
      .then((res) => setNotes(res.data));
  };

  useEffect(() => {
    loadTask();
    setOpen(true);
  }, []);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>NOT</DialogTitle>
        <DialogContent>
          <DialogContentText width={400}>
            * Görev Hakkında Not Ekleyebilirsiniz.
          </DialogContentText>
          <RedBar />
          {/* <label name='id'>Görev ID:{id}</label> */}
          <TextField
            variant="standard"
            name="currentdate"
            size="small"
            disabled
            value={currentDate}
            id="currentdate"
            type="datetime-local"
            onChange={(e) => setCurrentDate(e.target.value)}
          />
          <br />
          <TextField
            sx={{
              wordWrap: "break-word",
              wordBreak: "break-all",
              flex: 1,
              flexWrap: "wrap",
              flexDirection: "row",
              color: "#EE315D",
            }}
            required
            name="note"
            value={note}
            margin="dense"
            id="note"
            label="Notunuz"
            fullWidth
            variant="standard"
            multiline
            maxRows={6}
            minRows={4}
            onChange={(e) => setNote(e.target.value)}
          />
          <RedBar />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>İptal</Button>
          <Button
            type="submit"
            onClick={(e) => onSubmit(e)}
            variant="contained"
          >
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
