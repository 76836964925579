import React from "react";
import "./Layout.css";
import { Route, Routes, useLocation, NavLink } from "react-router-dom";
import {
  AppBar,
  Badge,
  Button,
  CardContent,
  Card,
  Avatar,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material/";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Toolbar, Typography, Paper, Divider } from "@mui/material/";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import AddTaskIcon from "@mui/icons-material/AddTask";
import Home from "../pages/Home";
import Create from "../pages/Create/Create";
import EditTasks from "../pages/EditTasks/EditTasks";
import NotePopup from "../pages/Note/NotePopup";
import Login from "../pages/Login/Login";
import { useLogout } from "../hooks/useLogOut";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Cookies from "js-cookie";
import TimeLine from "../pages/TimeLine/TimeLine";
import { Fragment, useEffect, useState } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import View from "../pages/View/View";
import Filter from "../pages/Filters/Filter";
import Clock from "react-live-clock";
import DepartmentManager from "../pages/Filters/DepartmentManager";
import NewUser from "../pages/NewUser/Users";
import DeptUserAdd from "../pages/NewUser/DeptUserAdd";
import AddUserTab from "../pages/NewUser/AddUserTab";
import ShowUsers from "../pages/NewUser/ShowUsers";
import Dene from "../pages/Dene/Dene";
import UserEdit from "../pages/NewUser/UserEdit";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CheckIcon from "@mui/icons-material/Check";
import logo from "../assets/logoblack.svg";
import ResetPw from "../pages/Login/ResetPw";

const drawerWidth = 200;

function Layout(props) {
  const UserName = Cookies.get("username");
  const Permission = Cookies.get("permission");
  const ProfilePic = Cookies.get("ProfilePic");
  const navigate = useNavigate();
  const { logout } = useLogout();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [state, setState] = React.useState({
    right: false,
  });
  const UserID = Cookies.get("userID");
  const [data, setdata] = useState([]);

  const loadTasks = async () => {
    try {
      var axios = require("axios");

      axios
        .post(
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "task/getUserNotification"
            : process.env.react_app_localUrl + "task/getUserNotification",

          { UserID }
        )
        .then(({ data }) => {
          const HelperArray = data?.map((el) => ({ ...el, id: el._id }));
          setdata(HelperArray);
          Cookies.set("noti", data.length);
        });
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const UpdateNotifications = (value, e) => {
    e.preventDefault();
    var axios = require("axios");
    axios.post(
      process.env.react_app_productMode === "true"
        ? process.env.react_app_productUrl + "task/updateUserNotification"
        : process.env.react_app_localUrl + "task/updateUserNotification",
      { UserID: UserID, TaskID: value?._id }
    );
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const location = useLocation();
  const menuItems = [
    {
      topic: "Anasayfa",
      path: "/",
    },
    {
      topic: "Yeni Görev",
      path: "/create",
    },
    //{
    //   topic: "Bildirimler",
    //   path: "/notifications",
    // },
  ];

  const menuItems2 = [
    // {
    //   topic: "Zaman Çizelgesi",
    //   path: "/timeLine",
    // },
    {
      topic: "Yeni Kullanıcı",
      path: "/addUserTab",
    },
    // {
    //   topic: "Bildirimler",
    //   path: "/notifications",
    // },
  ];

  const menuItems3 = [
    // {
    //   topic: "Zaman Çizelgesi",
    //   path: "/timeLine",
    // },
    {
      topic: "Yeni Kullanıcı",
      path: "/addUserTab",
    },
    // {
    //   topic: "Bildirimler",
    //   path: "/notifications",
    // },
  ];
  // https://earasta.com/arasta_white.svg

  const drawer = (
    <div className="left-drawer">
      <a href="/">
        <img src={logo} className="logo" />
      </a>

      <Typography
        variant="button"
        display="block"
        color={"#121212"}
        style={{
          fontWeight: 700,
          marginLeft: 10,
          marginRight: 10,
          marginTop: -6,
        }}
      >
        Görev Takip Sistemi
      </Typography>
      <Typography
        variant="h7"
        sx={{ color: "#EE315D", ml: 3, mr: 3, fontSize: 14 }}
      >
        <span onChange={(e) => e.target.value} className="moment">
          <Clock format={"DD.MM.YYYY  HH:mm:ss"} ticking={true} />
        </span>
      </Typography>
      <Toolbar />

      <List>
        <Paper
          className="paper"
          elevation={0}
          sx={{ paddingLeft: 2, marginRight: 2 }}
        >
          {/* <Paper
          className="paper"
          elevation={0}
          sx={{ paddingLeft: 1, marginRight: 1 }}
        > */}
          {menuItems.map((text, index) => (
            <ListItem
              sx={{
                color: "#EE315D",
                border: 0.5,
                mb: 1,
              }}
              key={text.topic}
              component="a"
              href={location.path === "home" ? "\\" : text.path}
            >
              {index % 2 === 0 ? <HomeIcon /> : <AddTaskIcon color="white" />}
              {/* {index % 3 === 0 ? (
                  <HomeIcon color="white" />
                ) : (
                  (<AddTaskIcon color="white" />)(
                    <AccountBoxIcon color="white" />
                  )
                )} */}

              <ListItemText sx={{ paddingLeft: "5px" }} primary={text.topic} />
            </ListItem>
          ))}
          {Permission === "admin" && (
            <>
              {" "}
              {menuItems2.map((text, index) => (
                <ListItem
                  sx={{
                    color: "#EE315D",
                    border: 0.5,
                    display: "flex",
                    flexDirection: "row",
                    whiteSpace: "nowrap",
                  }}
                  button
                  key={text.topic}
                  component="a"
                  href={location.path === "home" ? "\\" : text.path}
                >
                  <ListItemIcon sx={{ mb: 1, mr: -3 }}>
                    {index % 2 === 0 ? (
                      <PersonAddIcon color="#EE315D" />
                    ) : (
                      <NotificationsNoneIcon color="primary" />
                    )}
                  </ListItemIcon>
                  {/* <ListItemText primary={text.topic} /> */}
                  {/* 
                  {index % 2 === 0 ? (
                    <PersonAddIcon color="#EE315D" sx={{ width: "fit" }} />
                  ) : (
                    <NotificationsNoneIcon color="primary" />
                  )} */}

                  <ListItemText
                    sx={{ paddingLeft: "5px" }}
                    primary={text.topic}
                  />
                </ListItem>
              ))}
            </>
          )}
          {Permission === "DepartmentManager" && (
            <>
              {menuItems3.map((text, index) => (
                <ListItem
                  sx={{
                    color: "#EE315D",
                    border: 0.5,
                    display: "flex",
                    flexDirection: "row",
                    whiteSpace: "nowrap",
                  }}
                  button
                  key={text.topic}
                  component="a"
                  href={location.path === "home" ? "\\" : text.path}
                >
                  <ListItemIcon sx={{ mb: 1, mr: -3 }}>
                    {index % 2 === 0 ? (
                      <PersonAddIcon color="#EE315D" />
                    ) : (
                      <NotificationsNoneIcon color="primary" />
                    )}
                  </ListItemIcon>
                  {/* <ListItemText primary={text.topic} />

                  {index % 2 === 0 ? (
                    <PersonAddIcon color="#EE315D" />
                  ) : (
                    <NotificationsNoneIcon color="primary" />
                  )} */}

                  <ListItemText
                    sx={{ paddingLeft: "5px" }}
                    primary={text.topic}
                  />
                </ListItem>
              ))}
            </>
          )}
        </Paper>
      </List>
    </div>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const rightdrawer = (anchor) => (
    <div className="left-drawer">
      <div className="left-drawer-header">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer(anchor, false)}
          sx={{ ml: 2, mr: 10 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          sx={{ textAlign: "center", fontSize: 16, color: "#EE315D", mt: 1 }}
          variant="h6"
          display="block"
          gutterBottom
        >
          BİLDİRİMLER
        </Typography>
      </div>
      <Divider light />
      <br />
      <Box
        sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 350 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          {data.map((value, index) => (
            <ListItem button key={index}>
              <Card sx={{ MaxWidth: 340, minWidth: 200 }}>
                <CardContent
                  onClick={(e) => {
                    UpdateNotifications(value, e);
                    navigate(`/view/${value?._id}`);
                    Cookies.get("noti" - 1);
                  }}
                  sx={{
                    width: 315,
                    backgroundColor: "#EE315D",
                    height: "auto",
                  }}
                >
                  <Typography
                    component="div"
                    variant="h7"
                    color="white"
                    sx={{ fontSize: 16 }}
                  >
                    <CheckIcon sx={{ color: "white", mr: 1 }} /> Yeni Görev
                  </Typography>
                  {value.Title}
                  <Typography
                    variant="subtitle1"
                    component="div"
                    color="white"
                    sx={{ fontSize: 14 }}
                  >
                    {value.Description}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    color="white"
                    sx={{ fontSize: 14 }}
                  >
                    {moment(new Date(Number(value.GivenDate) * 1000)).format(
                      "DD.MM.YYYY HH:mm:ss"
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const BasicMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div className="basic-menu">
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <Avatar
            className="Avatar"
            alt="ProfilePic"
            src={ProfilePic}
            sx={{
              border: 2,
              borderColor: "#121212",
              marginLeft: 2,
              width: 55,
              height: 55,
              backgroundColor: "white",
            }}
          />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>
            <NavLink
              to="/profile"
              style={{
                textDecoration: "none",
                color: "#121212",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "between",
                }}
              >
                <AccountBoxIcon className="mini-profile" />
                <span>Hesap Detayları</span>
              </Grid>
            </NavLink>
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem> */}
        </Menu>
      </div>
    );
  };

  useEffect(() => {
    loadTasks();
  }, []);

  return (
    <div className="navbar">
      <Box
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <CssBaseline />
        <AppBar
          position="fixed"
          elevation={0}
          sx={{
            width: { md: `calc(100% - ${drawerWidth}px)` },
            ml: { md: `${drawerWidth}px` },
          }}
        >
          {UserName && (
            <>
              <Toolbar className="toolbar">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ display: { md: "none" }, color: "red" }}
                >
                  <MenuIcon />
                </IconButton>
                {/* <Link
                  variant="h7"
                  underline="none"
                  sx={{ color: "#EE315D" }}
                  href={"/view/:id"}
                >  </Link> */}
                <Typography sx={{ color: "#EE315D", ml: -1 }}>
                  {"Hoş Geldin"}{" "}
                  {UserName.charAt(0).toUpperCase() + UserName.slice(1)}
                </Typography>

                <Fragment key={"right"}>
                  <Button onClick={toggleDrawer("right", true)}>
                    <Badge
                      badgeContent={data.length}
                      color="secondary"
                      sx={{ color: "#EE315D" }}
                    >
                      <NotificationsNoneIcon color="action" />
                    </Badge>
                  </Button>

                  <Drawer
                    // disableAutoFocus
                    disableEnforceFocus
                    anchor={"right"}
                    open={state["right"]}
                    onClose={toggleDrawer("right", false)}
                  >
                    {rightdrawer("right")}
                  </Drawer>
                  <Button
                    onClick={logout}
                    variant="contained"
                    color="secondary"
                    startIcon={<ExitToAppIcon sx={{ color: "white" }} />}
                    className="logout-btn"
                  >
                    Çıkış
                  </Button>

                  <BasicMenu />
                </Fragment>
              </Toolbar>
            </>
          )}
        </AppBar>
        {UserName && (
          <>
            <Box
              component="nav"
              sx={{
                width: { md: drawerWidth },
                flexShrink: { md: 0 },
              }}
            >
              <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: "#eceff1",
                  },
                }}
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", md: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                    backgroundColor: "white",
                  },
                }}
              >
                {drawer}
              </Drawer>
              <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: "white",
                  },
                }}
                variant="permanent"
                sx={{
                  display: { xs: "none", md: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
                open
              >
                {drawer}
              </Drawer>
            </Box>
          </>
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { md: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          <Routes>
            <Route
              path="/login"
              exact
              element={!UserName ? <Login /> : <Home />}
            />
            <Route path="/" exact element={UserName ? <Home /> : <Login />} />
            <Route
              path="/create"
              exact
              element={UserName ? <Create /> : <Login />}
            />
            <Route
              path="/update/:id"
              exact
              element={UserName ? <EditTasks /> : <Login />}
            />
            <Route
              path="/popup/:id"
              exact
              element={UserName ? <NotePopup /> : <Login />}
            />
            <Route
              path="/timeLine"
              exact
              element={UserName ? <TimeLine /> : <Login />}
            />

            <Route
              path="/view/:id"
              exact
              element={UserName ? <View /> : <Login />}
            />
            <Route
              path="/filter"
              exact
              element={UserName ? <Filter /> : <Login />}
            />
            <Route
              path="/departmentManager"
              exact
              element={UserName ? <DepartmentManager /> : <Login />}
            />
            <Route
              path="/newUser"
              exact
              element={UserName ? <NewUser /> : <Login />}
            />
            <Route
              path="/showUsers"
              exact
              element={UserName ? <ShowUsers /> : <Login />}
            />
            <Route
              path="/deptUser"
              exact
              element={UserName ? <DeptUserAdd /> : <Login />}
            />

            <Route
              path="/addUserTab"
              exact
              element={UserName ? <AddUserTab /> : <Login />}
            />

            <Route
              path="/profile"
              exact
              element={UserName ? <Dene /> : <Login />}
            />

            {/* <Route
              path="/calendar"
              exact
              element={UserName ? <Calendar /> : <Login />}
            /> */}

            <Route
              path="/addUserTab/userEdit/:id"
              exact
              element={UserName ? <UserEdit /> : <Login />}
            />

            <Route path="/ResetPassword/:tokenid" exact element={<ResetPw />} />
            {/* 
            <Route
              path="/taskCard"
              exact
              element={UserName ? <TaskCards /> : <Login />}
            /> */}
          </Routes>
        </Box>
      </Box>
    </div>
  );
}

Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
export default Layout;
