import React from "react";
import { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Select,
  FormControlLabel,
  FormLabel,
  Button,
  Checkbox,
  FormGroup,
  Radio,
  RadioGroup,
  FormControl,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Divider,
  Container,
  Card,
  Grid,
  MenuItem,
  Dialog,
  Stack,
  Chip,
  Slider,
  Typography,
  Slide,
  Paper,
  IconButton,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css fi
import { DateRange } from "react-date-range";
import locale from "date-fns/locale/tr";
import moment, { localeData } from "moment";
import { useTheme, styled, alpha } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

import {
  trTR,
  DataGrid,
  gridClasses,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridCsvExportMenuItem,
} from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import clsx from "clsx";
import "./Filter.css";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import axios from "axios";
import { lightBlue, grey } from "@mui/material/colors";
import "moment/locale/tr";

// import Calendar from "../Routine/TaskCalendar";
const csvOptions = { delimiter: ";", utf8WithBom: true };

const orientation = window.matchMedia("(max-width: 700px)").matches
  ? "vertical"
  : "horizontal";

const CustomExportButton = (props) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = (props) => (
  <GridToolbarContainer {...props}>
    <CustomExportButton
      sx={{
        justifyContent: "start",
        itemAlign: "center",
      }}
    />
    <GridToolbarColumnsButton
      sx={{
        justifyContent: "start",
        itemAlign: "center",

        ml: 1,
      }}
    />
    <GridToolbarFilterButton sx={{ display: "none" }} />
    <GridToolbarDensitySelector
      sx={{
        justifyContent: "start",
        itemAlign: "center",

        ml: 1,
      }}
    />
    <GridToolbarExport sx={{ display: "none" }} />
  </GridToolbarContainer>
);

const currencies = [
  {
    value: "Yeni Görev",
    label: "Yeni Görev",
  },
  {
    value: "Üzerinde Çalışıyor",
    label: "Üzerinde Çalışıyor",
  },
  {
    value: "Takılmış",
    label: "Takılmış",
  },
  {
    value: "Tamamlandı",
    label: "Tamamlandı",
  },
  {
    value: "Beklemede",
    label: "Beklemede",
  },
];
const currencies2 = [
  {
    value: "Yüksek",
    label: "Yüksek",
  },
  {
    value: "Orta",
    label: "Orta",
  },
  {
    value: "Düşük",
    label: "Düşük",
  },
];
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const ODD_OPACITY = 0.2;

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },

  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

export default function Filter({ data, setdata }) {
  const [filters, setFilters] = useState(
    JSON.parse(localStorage.getItem("guncel"))
  );
  const [userLocalDate, setUserLocalDate] = useState(
    JSON.parse(localStorage.getItem("FilterDate"))
  );
  const [checked, setChecked] = React.useState(false);
  const [pageSize, setPageSize] = useState(15);

  const theme = useTheme();
  const fullScreenFilter = useMediaQuery(theme.breakpoints.down("xs"));

  const [startDate, setStartDate] = useState(
    filters
      ? new Date(
          filters?.GivenDate
            ? moment(new Date(Number(filters?.GivenDate.$gte) * 1000)).format(
                "YYYY.MM.DD HH:mm:ss"
              )
            : filters?.StartDate
            ? moment(new Date(Number(filters?.StartDate.$gte) * 1000)).format(
                "YYYY.MM.DD HH:mm:ss"
              )
            : filters?.EndDate
            ? moment(new Date(Number(filters?.EndDate.$gte) * 1000)).format(
                "YYYY.MM.DD HH:mm:ss"
              )
            : new Date()
        )
      : userLocalDate
      ? new Date(
          userLocalDate?.GivenDate
            ? moment(
                new Date(Number(userLocalDate?.GivenDate.$gte) * 1000)
              ).format("YYYY.MM.DD HH:mm:ss")
            : userLocalDate?.StartDate
            ? moment(
                new Date(Number(userLocalDate?.StartDate.$gte) * 1000)
              ).format("YYYY.MM.DD HH:mm:ss")
            : userLocalDate?.EndDate
            ? moment(
                new Date(Number(userLocalDate?.EndDate.$gte) * 1000)
              ).format("YYYY.MM.DD HH:mm:ss")
            : new Date()
        )
      : new Date()
  );

  const [endDate, setEndDate] = useState(
    filters
      ? new Date(
          filters?.GivenDate
            ? moment(new Date(Number(filters?.GivenDate.$lte) * 1000)).format(
                "YYYY.MM.DD HH:mm:ss"
              )
            : filters?.StartDate
            ? moment(new Date(Number(filters?.StartDate.$lte) * 1000)).format(
                "YYYY.MM.DD HH:mm:ss"
              )
            : filters?.EndDate
            ? moment(new Date(Number(filters?.EndDate.$lte) * 1000)).format(
                "YYYY.MM.DD HH:mm:ss"
              )
            : new Date()
        )
      : userLocalDate
      ? new Date(
          userLocalDate?.GivenDate
            ? moment(
                new Date(Number(userLocalDate?.GivenDate.$lte) * 1000)
              ).format("YYYY.MM.DD HH:mm:ss")
            : userLocalDate?.StartDate
            ? moment(
                new Date(Number(userLocalDate?.StartDate.$lte) * 1000)
              ).format("YYYY.MM.DD HH:mm:ss")
            : userLocalDate?.EndDate
            ? moment(
                new Date(Number(userLocalDate?.EndDate.$lte) * 1000)
              ).format("YYYY.MM.DD HH:mm:ss")
            : new Date()
        )
      : new Date()
  );

  const [value, setValue] = React.useState("");
  const [dates, setDates] = React.useState();
  const [val, setVal] = useState([]);
  const [ischecked2, setIsChecked2] = useState(
    filters?.GivenDate || filters?.StartDate || filters?.EndDate ? true : false
  );
  const [ischeckedFilter, setCheckedFilter] = useState(false);

  const [selectedRadio1, setSelectedRadio1] = useState(
    filters?.GivenDate || filters?.selectedRadio == "1" ? "1" : false
  );
  const [selectedRadio2, setSelectedRadio2] = useState(
    filters?.StartDate || filters?.selectedRadio == "2" ? "2" : false
  );
  const [selectedRadio3, setSelectedRadio3] = useState(
    filters?.EndDate || filters?.selectedRadio == "3" ? "3" : false
  );

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("Choose wisely");
  const [selectionModel, setSelectionModel] = useState([]);
  const navigate = useNavigate();
  const username = Cookies.get("username");

  const departments = Cookies.get("departments");
  const permission = Cookies.get("permission");
  const userID = Cookies.get("userID");

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
    value: "",
  };

  const updateRange = (e, dat) => {
    const findActtion = data?.filter((el) => el.Action == "Tamamlandı");
    const actionLength = findActtion?.length;
    const dataLength = data?.length;
    const findRange = 100 * (actionLength / dataLength);

    dat = findRange;
    setVal([0, dat ? dat : 0]);
  };

  useEffect(() => {
    updateRange();
  }, []);

  useEffect(() => {
    const getDefaultData =
      JSON.parse(localStorage.getItem("ColumnUser")) || columns;
    setColumns(getDefaultData);
  }, []);

  // useEffect(() => {
  //   localStorage.setItem("filters", JSON.stringify(filters));
  // }, [filters]);

  const handleChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 5 : 5,
      bottom: params.isLastVisible ? 10 : 5,
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeCheckBox2 = () => {
    setIsChecked2(!ischecked2);

    localStorage.setItem(
      "guncel",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("guncel")),
        ischecked2: !ischecked2,
      })
    );
  };

  const handleChangeCheckBoxFilter = () => {
    if (!ischeckedFilter == false) {
      const filterDay = {
        Department: departments,
        Permission: permission,
        UserID: userID,
        number: 1,
      };
      setCheckedFilter(!ischeckedFilter);
      setdata(data);

      axios

        .post(
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "tasks"
            : process.env.react_app_localUrl + "tasks",
          filterDay
        )
        .then(({ data }) => {
          const HelperArray = data?.map((el) => ({ ...el, id: el._id }));
          setdata(HelperArray);
        });
    } else {
      const filterDay = {
        Department: departments,
        Permission: permission,
        UserID: userID,
        number: 3,
      };
      setCheckedFilter(!ischeckedFilter);

      axios

        .post(
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "tasks"
            : process.env.react_app_localUrl + "tasks",
          filterDay
        )
        .then(({ data }) => {
          const HelperArray = data?.map((el) => ({ ...el, id: el._id }));
          setdata(HelperArray);
        });
    }
  };

  const handleChangeRadio = (event) => {
    setSelectedRadio1(event.target.value);
    setSelectedRadio2(event.target.value);
    setSelectedRadio3(event.target.value);

    localStorage.setItem(
      "guncel",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("guncel")),
        selectedRadio: event.target.value,
      })
    );
    setHelperText(" ");
    setError(false);
  };

  function handleSelect(ranges) {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  }

  moment.locale("tr", locale);

  const handleClickOpen = (e) => {
    setOpen(true);
  };

  const onSearch = async (e) => {
    const data = {
      "Users.Permission": permission,
      "Users.UserID": userID,
      "Users.Department": departments,
      Action: filters?.Action,
      Status: filters?.Status,
    };

    const config = {
      method: "post",
      url:
        process.env.react_app_productMode === "true"
          ? process.env.react_app_productUrl + `task/filter/`
          : process.env.react_app_localUrl + `task/filter/`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (ischecked2 === true) {
      if (selectedRadio1 === "1") {
        data.GivenDate = {
          $gte: `${
            new Date(
              moment(startDate || userLocalDate.startDate).format(
                "YYYY-MM-DD 00:00:00Z"
              )
            ).getTime() / 1000
          }`,
          $lte: `${
            new Date(
              moment(endDate || userLocalDate.endDate).format(
                "YYYY-MM-DD 23:59:59Z"
              )
            ).getTime() / 1000
          }`,
        };
        localStorage.setItem(
          "FilterDate",
          JSON.stringify({ GivenDate: data.GivenDate })
        );
      } else if (selectedRadio2 === "2") {
        data.StartDate = {
          $gte: `${
            new Date(
              moment(startDate || userLocalDate.startDate).format(
                "YYYY-MM-DD 00:00:00Z"
              )
            ).getTime() / 1000
          }`,
          $lte: `${
            new Date(
              moment(endDate || userLocalDate.endDate).format(
                "YYYY-MM-DD 23:59:59Z"
              )
            ).getTime() / 1000
          }`,
        };
        localStorage.setItem(
          "FilterDate",
          JSON.stringify({ StartDate: data.StartDate })
        );
      } else if (selectedRadio3 === "3") {
        data.EndDate = {
          $gte: `${
            new Date(
              moment(startDate || userLocalDate.startDate).format(
                "YYYY-MM-DD 00:00:00Z"
              )
            ).getTime() / 1000
          }`,
          $lte: `${
            new Date(
              moment(endDate || userLocalDate.endDate).format(
                "YYYY-MM-DD 23:59:59Z"
              )
            ).getTime() / 1000
          }`,
        };
        localStorage.setItem(
          "FilterDate",
          JSON.stringify({ EndDate: data.EndDate })
        );
      } else {
        setHelperText("Lütfen Tarih Çeşidi Seçiniz");
        setError(true);
      }
    }
    try {
      localStorage.setItem("guncel", JSON.stringify(data));

      setFilters(data);
      config.data = data;
      const response = await axios(config);
      const result = response.data.map((element) => ({
        ...element,
        id: element._id,
      }));
      setdata(result);
      setHelperText("");
      setError(false);
    } catch (error) {
      console.log(error);
      setOpen(false);
      Swal.fire({
        title: "HATA!",
        text: "Beklenmeyen bir sorun oluştu.",
        icon: "error",
        confirmButtonText: "OK",
      });
      localStorage.removeItem("guncel");
      localStorage.removeItem("selectedUserFilter");
      localStorage.removeItem("selectedUserFilterDate");

      localStorage.removeItem("selectedUserData");
      localStorage.removeItem("selectedUserLocal");
    } finally {
      setOpen(false);
    }
  };

  const RemoveTask = (_id) => {
    setdata((prevRows) => prevRows.filter((row) => row.id !== _id));

    try {
      fetch(
        process.env.react_app_productMode === "true"
          ? process.env.react_app_productUrl + `task/delete/` + _id
          : process.env.react_app_localUrl + `task/delete/` + _id,

        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
        }
      );
    } catch (error) {
      alert("Başarısız oldu ");
    }
  };

  const AlertDelete = (id) => {
    id = id[0]._id;
    Swal.fire({
      title: "Emin Misiniz?",
      text: "Bu görevi silmek üzeresiniz bunu geri alamazsınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "İptal",
      confirmButtonText: "Evet, Sil!",
    }).then((result) => {
      if (result.isConfirmed) {
        RemoveTask(id);
        Swal.fire("Silindi!", "Bu Görev Başarıyla Silindi.", "success");
      }
    });
  };

  const PostEdit = (id) => {
    id = id[0]._id;

    navigate(`update/${id}`);
  };
  const AddNote = (id) => {
    id = id[0]._id;

    navigate(`popup/${id}`);
  };
  const View = (id) => {
    id = id[0]._id;

    navigate(`view/${id}`);
  };

  const [columns, setColumns] = useState([
    {
      hide: false,
      field: "TaskCompleteDate",
      headerName: "Görev Tamamlanma Tarihi",
      sortable: true,
      minWidth: 160,
      headerAlign: "left",
      flex: 1,
      valueGetter: (params) => {
        return params.value
          ? moment(new Date(Number(params.value) * 1000)).format(
              "DD.MMMM.YYYY HH:mm"
            )
          : "";
      },
    },
    {
      hide: false,
      field: "Users",
      headerName: "Görev Kullanıcıları",
      sortable: false,
      minWidth: 600,
      flex: 1,
      type: "string",
      valueGetter: (params) => {
        // console.log(params.row);
        const { Users } = params.row;
        return Users?.map((elem) => elem.Username).join(",");
      },
      renderCell: function (params) {
        //console.log(params.value);

        const user = params?.value;
        return user?.split(",")?.map((Users, index) => (
          <Stack
            type="string"
            width={100}
            key={index}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "stretch",
              justifyContent: "left",
              p: 0,
              m: 0,
            }}
          >
            <Chip
              sx={{
                listStyle: "none",
                fontSize: 10,
                height: 20,
                color: "#121212",
                borderColor: "#121212",
                ml: 0.4,
              }}
              color="primary"
              variant="outlined"
              label={`${Users}`}
            />
          </Stack>
        ));
      },
    },
    {
      hide: false,
      field: "GivenDate",
      headerName: "Veriliş Tarihi",
      minWidth: 160,
      headerAlign: "left",
      flex: 1,
      valueGetter: (params) => {
        return params.value
          ? moment(new Date(Number(params.value) * 1000)).format(
              "DD.MMMM.YYYY HH:mm"
            )
          : "";
      },
    },
    {
      hide: false,
      field: "Title",
      headerName: "Başlık",
      minWidth: 200,
      headerAlign: "left",
      flex: 1,
      renderCell: (params) => (
        <CustomWidthTooltip
          title={params.value.toString()}
          placement="bottom-start"
        >
          <span className="table-cell-trucate">{params.value.toString()}</span>
        </CustomWidthTooltip>
      ),
    },
    {
      hide: false,
      field: "Description",
      headerName: "Açıklama",
      minWidth: 200,
      headerAlign: "left",
      flex: 1,
      renderCell: (params) => (
        <CustomWidthTooltip
          title={params.value.toString()}
          placement="bottom-start"
        >
          <span className="table-cell-trucate">{params.value.toString()}</span>
        </CustomWidthTooltip>
      ),
    },
    {
      hide: false,
      field: "Action",
      headerName: "Durum",
      minWidth: 150,
      headerAlign: "center",
      flex: 1,

      cellClassName: (params) =>
        clsx("super-app", {
          newtask: params.value === "Yeni Görev",
          manuel: params.value === "Üzerinde Çalışıyor",
          positive: params.value === "Tamamlandı",
          negative: params.value === "Takılmış",
          beklemede: params.value === "Beklemede",
        }),
    },

    {
      hide: false,
      field: "Status",
      headerName: "Öncelik",
      minWidth: 120,
      headerAlign: "left",
      flex: 1,
      cellClassName: (params) =>
        clsx("super-app", {
          dusuk: params.value === "Düşük",
          positive: params.value === "Orta",
          negative: params.value === "Yüksek",
        }),
    },
    {
      hide: false,
      field: "StartDate",
      headerName: "Başlangıç Tarihi ",
      minWidth: 160,
      headerAlign: "left",
      flex: 1,
      valueGetter: (params) => {
        return params.value
          ? moment(new Date(Number(params.value) * 1000)).format(
              "DD.MMMM.YYYY HH:mm"
            )
          : "";
      },
    },
    {
      hide: false,
      field: "EndDate",
      headerName: "Tahmini Bitiş Tarihi",
      minWidth: 160,
      headerAlign: "left",
      flex: 1,
      valueGetter: (params) => {
        return params.value
          ? moment(new Date(Number(params.value) * 1000)).format(
              "DD.MMMM.YYYY HH:mm"
            )
          : "";
      },
    },
    // {
    //   hide: false,
    //   headerName: "Özellikler",
    //   field: "actions",
    //   type: "actions",
    //   minWidth: 250,
    //   headerAlign: "center",
    //   flex: 1,
    //   getActions: (params) => {
    //     const kullaniciDogrulandi = params.row.Users.filter(
    //       (x) => x.UserID == userID
    //     );

    //     const bul = kullaniciDogrulandi.map((x) => {
    //       return x.Edit === true;
    //     });

    //     // console.log(bul);

    //     return [
    //       <GridActionsCellItem
    //         style={{
    //           visibility: bul[0] ? "visible" : "hidden",
    //         }}
    //         icon={<DeleteIcon />}
    //         label="Delete"
    //         onClick={() => AlertDelete(params.id)}
    //       />,
    //       <GridActionsCellItem
    //         style={{
    //           visibility: bul[0] ? "visible" : "hidden",
    //         }}
    //         icon={<EditIcon />}
    //         label="Update"
    //         onClick={() => PostEdit(params.id)}
    //       />,
    //       <GridActionsCellItem
    //         icon={<NoteAddIcon sx={{ color: lightBlue[500] }} />}
    //         label="AddNote"
    //         onClick={() => AddNote(params.id)}
    //       />,
    //       <GridActionsCellItem
    //         icon={<VisibilityIcon />}
    //         label="View"
    //         onClick={() => View(params.id)}
    //       />,
    //       // <GridActionsCellItem
    //       //   icon={<CalendarMonthIcon sx={{ mb: 0 }} />}
    //       //   label="Rutinlere Ekle"
    //       //   onClick={() => Calander(params.id)}
    //       //   showInMenu
    //       // />,
    //     ];
    //   },
    // },
  ]);

  const HandleClear = (e) => {
    localStorage.removeItem("guncel");
    localStorage.removeItem("FilterDate");

    const HelperFilters = { ...filters };
    delete HelperFilters.Action;
    delete HelperFilters.Status;
    setFilters(HelperFilters);
    setIsChecked2(false);
    setSelectedRadio1(false);
    setSelectedRadio2(false);
    setSelectedRadio3(false);
  };
  const handleRowClick = (params, event) => {
    setChecked(true);
    const kullaniciDogrulandi = params.row.Users.filter(
      (x) => x.UserID == userID
    );
    const bul = kullaniciDogrulandi.map((x) => {
      return x.Edit === true;
    });
  };
  return (
    <div className="userfilter" style={{ width: "100%", marginTop: -50 }}>
      {username && (
        <>
          <div className="buttonMainContainer">
            <Button
              variant="contained"
              onClick={handleClickOpen}
              className="yeni-btn"
            >
              Filtreleme
            </Button>
          </div>
          <Container maxWidth="sm" className="filter-pop">
            <Dialog
              fullScreen={fullScreenFilter}
              open={open}
              onClose={handleClose}
              fullWidth
              maxWidth="md"
            >
              <DialogTitle id="responsive-dialog-title" sx={{ mb: 2 }}>
                *Filtreleme için değerlerinizi seçiniz.
                <Divider />
              </DialogTitle>
              <DialogContent>
                <Box style={{ display: "flex", flexWrap: "noWrap" }}>
                  Durum
                  <Select
                    type="text"
                    label="Durum"
                    size="small"
                    name="Action"
                    sx={{ width: 300, mt: 3, ml: -5.5 }}
                    // name="Action"
                    // defaultValue={filterData?.Action ? filterData?.Action : ""}
                    value={filters?.Action ? filters?.Action : ""}
                    onChange={handleChange}
                    style={{ ml: 3 }}
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  Öncelik
                  <Select
                    type="text"
                    // defaultValue={filterData?.Status ? filterData?.Status : ""}
                    value={filters?.Status ? filters?.Status : ""}
                    label="Status"
                    name="Status"
                    size="small"
                    sx={{ width: 300, mt: 3, ml: -6 }}
                    onChange={handleChange}
                  >
                    {currencies2.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                <br />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        checked={ischecked2}
                        onChange={handleChangeCheckBox2}
                      />
                    }
                    label="Filtre İçin Tarih  Kullan"
                  />
                  <br />
                  <Box className="FormElement">
                    <FormControl
                      className="FormControl"
                      variant="standard"
                      sx={{ fontSize: 12 }}
                      error={error}
                    >
                      <FormLabel
                        id="checkbox"
                        color="info"
                        disabled={!ischecked2}
                        sx={{ fontSize: 14, ml: -2, mb: 2 }}
                      >
                        *Filtreleme İçin Tarih Çeşidi ve Günü Seçiniz:
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-error-radios"
                        value={value}
                        name="dates"
                        onChange={(e) => setDates(e.target.value)}
                      >
                        <FormControlLabel
                          value="1"
                          control={
                            <Radio
                              disabled={!ischecked2}
                              value="1"
                              checked={selectedRadio1 === "1"}
                              onChange={handleChangeRadio}
                              inputProps={{ "aria-label": "givenDate" }}
                            />
                          }
                          label="Görev Veriliş Tarihine Göre"
                        />
                        <FormControlLabel
                          value="2"
                          control={
                            <Radio
                              disabled={!ischecked2}
                              value="2"
                              checked={selectedRadio2 === "2"}
                              onChange={handleChangeRadio}
                              inputProps={{ "aria-label": "StartDate" }}
                            />
                          }
                          label="Görev Başlangıç Tarihine Göre"
                        />
                        <FormControlLabel
                          value="3"
                          control={
                            <Radio
                              disabled={!ischecked2}
                              value="3"
                              checked={selectedRadio3 === "3"}
                              onChange={handleChangeRadio}
                              inputProps={{ "aria-label": "EndDate" }}
                            />
                          }
                          label="Görev Bitiş Tarihine Göre"
                        />
                      </RadioGroup>
                    </FormControl>
                    {ischecked2 === true && (
                      <DateRange
                        orientation={orientation}
                        startDate={startDate}
                        endDate={endDate}
                        className="daterangepicker"
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                        footerContent={false}
                        editableDateInputs={true}
                        value={""}
                        locale={locale}
                        // endDatePlaceholder="End Date"
                        // rangeColors={[theme.palette.primary.main]}
                        // ranges={ranges}
                        //  onChange={ranges => setRanges([ranges.rollup])}
                        // inputRanges={[]}
                        //moveRangeOnFirstSelection={false}
                      />
                    )}
                  </Box>
                </FormGroup>
              </DialogContent>
              <DialogActions>
                <Button
                  type="reset"
                  className="clear-btn"
                  onClick={HandleClear}
                  autoFocus
                  sx={{ position: "absolute", right: 850, left: 5 }}
                >
                  Temizle
                </Button>
                <Button
                  autoFocus
                  onClick={onSearch}
                  type="submit"
                  variant="contained"
                >
                  Uygula
                </Button>
                <Button onClick={handleClose} autoFocus>
                  Vazgeç
                </Button>
              </DialogActions>
            </Dialog>
          </Container>
          <br />

          <Box
            sx={{
              justifyContent: "center",
              flex: 1,
              backgroundColor: "white",
              borderRadius: 50,
              borderWidth: "1px",
            }}
          >
            <Slider
              sx={{
                width: "80%",
                justifyItems: "center",
                mt: 2,
              }}
              defaultValue={val}
              value={val}
              onChange={(e) => updateRange(e)}
              min={0}
              max={100}
              step={null}
            />
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              sx={{
                fontSize: 15,
                textAlign: "center",
                mt: -3,
                mb: 5,
                color: "lightgray",
              }}
            >
              <h4>
                Görev Tamamlanma Oranı: {""}
                {data?.length > 1
                  ? (
                      (data?.filter((el) => el.Action == "Tamamlandı").length /
                        data?.length) *
                      100
                    ).toFixed(2)
                  : 0}
                %
              </h4>
            </Typography>
          </Box>
          <Box
            sx={{
              height: "20px",
              "& .super-app.dusuk": {
                padding: "10px 30px 10px 30px",
                borderColor: "white",

                borderRadius: "25px",
                backgroundColor: "#e3711c",
                color: "white",
                fontWeight: "500",
              },
              "& .super-app.manuel": {
                paddingTop: "10px",
                paddingBottom: "10px",
                borderColor: "white",
                borderRadius: "25px",
                backgroundColor: "#e3711c",
                color: "white",
                fontWeight: "500",
              },
              "& .super-app.positive": {
                borderColor: "white",
                // backgroundImage: "url(" + completedLogo + ")",
                borderRadius: "25px",
                backgroundColor: "#009B35",
                padding: "10px 30px 10px 30px",
                color: "white",
                fontWeight: "500",
              },
              "& .super-app.negative": {
                borderColor: "white",

                borderRadius: "25px",
                backgroundColor: "#EE315D",
                padding: "10px 30px 10px 30px",
                color: "white",
                fontWeight: "500",
              },
              "& .super-app.beklemede": {
                borderColor: "white",
                borderRadius: "25px",
                backgroundColor: "#0074ff",
                color: "white",
                fontWeight: "500",
                padding: "10px 30px 10px 30px",
              },
              "& .super-app.newtask": {
                borderColor: "white",
                borderRadius: "25px",
                backgroundColor: "#8363b4",
                color: "white",
                fontWeight: "500",
                padding: "10px 30px 10px 30px",
              },
            }}
          >
            <Card
              elevation={3}
              xs={10}
              sm={12}
              md={10}
              style={{ width: "100%" }}
            >
              {/* <div style={{ display: "flex" }}>
                <FormGroup sx={{ width: 250 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ mb: 1.5, ml: 2 }}
                        checked={ischeckedFilter}
                        onChange={handleChangeCheckBoxFilter}
                      />
                    }
                    label="Son
                  3
                  Günü Filtrele"
                  />
                </FormGroup>
              </div> */}

              <div>
                <div
                  style={{
                    justifyContent: "right",
                    display: "flex",
                    flexWrap: "nowrap",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
                    <Paper
                      elevation={0}
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {selectionModel[0] && (
                        <div
                          style={{
                            marginBottom: "10px",
                            marginTop: "10px",
                            marginLeft: "10px",
                            marginRight: "10px",
                            borderRadius: "25px",
                            backgroundColor: "#f5f5f5",
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <Typography sx={{ ml: 2, mt: 2, mr: 5 }}>
                            {/* Seçilen Görev:{" "} */}
                            <span
                              style={{ fontWeight: "bold", fontSize: "13px" }}
                            >
                              {" "}
                              {selectionModel[0]?.Title}
                            </span>
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              justifyContent: "left",
                              alignItems: "center",
                            }}
                          >
                            <br />

                            {selectionModel[0]?.Users.filter(
                              (x) => x.UserID == userID
                            ).map((el, index) => (
                              // return el.Edit === true;
                              <div key={index}>
                                <Tooltip title="Sil">
                                  <IconButton
                                    style={{
                                      visibility:
                                        el.Edit === true ? "visible" : "hidden",
                                    }}
                                    aria-label="delete"
                                    onClick={() => AlertDelete(selectionModel)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Düzenle">
                                  <IconButton
                                    style={{
                                      visibility:
                                        el.Edit === true ? "visible" : "hidden",
                                    }}
                                    onClick={() => PostEdit(selectionModel)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            ))}
                            <Tooltip title="Not Ekle">
                              <IconButton
                                sx={{ paddingTop: 2 }}
                                onClick={() => AddNote(selectionModel)}
                              >
                                <NoteAddIcon sx={{ color: lightBlue[500] }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Detaylar">
                              <IconButton onClick={() => View(selectionModel)}>
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      )}
                    </Paper>
                  </Slide>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <FormGroup sx={{ width: 220 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ display: "flex", alignItems: "center" }}
                              checked={ischeckedFilter}
                              onChange={handleChangeCheckBoxFilter}
                            />
                          }
                          label="Son 3 Günü Filtrele"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>

              <Grid container className="grid" sx={{ display: "flex" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <StyledDataGrid
                    localeText={
                      trTR.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableColumnMenu
                    // checkboxSelection
                    className="datagrid-table"
                    rows={data}
                    autoHeight={true}
                    disableExtendRowFullWidth={true}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[15, 25, 50, 100]}
                    pagination
                    onSelectionModelChange={(id) => {
                      const selectedIDs = new Set(id);
                      const selectedRows = data?.filter((row) =>
                        selectedIDs.has(row.id)
                      );
                      setSelectionModel(selectedRows);
                    }}
                    onRowClick={handleRowClick}
                    selectionModel={selectionModel}
                    {...data}
                    components={{ Toolbar: CustomToolbar }}
                    // sx={{
                    //   [`& .${gridClasses.row}`]: {
                    //     bgcolor: (theme) =>
                    //       theme.palette.mode === "light" ? grey[50] : grey[50],
                    //   },
                    // }}
                    getRowSpacing={getRowSpacing}
                    rowHeight={40}
                    // filterModel={filterModel}
                    // onFilterModelChange={(model) => setFilterModel(model)}
                    //components={{ Toolbar: GridToolbar}}

                    componentsProps={{
                      preferencesPanel: {
                        onChange: (event) => {
                          const tempCols = [...columns];

                          tempCols.forEach((col, i) => {
                            if (col.field == event.target.name) {
                              tempCols[i].hide = !tempCols[i].hide;
                            }

                            localStorage.setItem(
                              "ColumnUser",
                              JSON.stringify(tempCols)
                            );
                          });
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Card>
          </Box>
        </>
      )}
    </div>
  );
}
