import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Typography,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
/* import { styled } from "@mui/material/styles"; */
import "./UserAdd.css";
import { Container } from "@mui/system";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
/* const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#EE315D",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#EE315D",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#EE315D",
    },
  },
}); */

const currencies = [
  {
    value: "User",
    label: "User",
  },
  {
    value: "Stajyer",
    label: "Stajyer",
  },
  {
    value: "DepartmentManager",
    label: "Department Manager",
  },
  {
    value: "admin",
    label: "Admin",
  },
];

export default function Users() {
  const [currency, setCurrency] = React.useState("User");
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState();
  const [Permission, setPermission] = useState("");
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setselectedDepartment] = useState();
  const [Address, setAddress] = useState("");
  const [Phone, setPhone] = useState("");
  const [Mail, setMail] = useState("");
  const handleChange1 = (event) => {
    setCurrency(event.target.value);
  };
  const navigate = useNavigate();
  const LoadDepartment = async () => {
    try {
      var axios = require("axios");
      var config = {
        method: "get",
        url:
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "departments"
            : process.env.react_app_localUrl + "departments",
        headers: {},
      };
      const Result = await axios(config);
      const Department = Result.data;
      const EditDepartment = Department.map((el) => ({
        ...el,
        label: el.Department,
        value: el.Department,
      }));
      setDepartment(EditDepartment);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    LoadDepartment();
  }, []);

  const handleAdd = () => {
    const newUserData = {
      Seen: false,
      Username: Username,
      Password: Password,
      Department: selectedDepartment?.Department,
      Permission: Permission,
      Edit: false,
      Address: Address,
      Phone: Phone,
      Mail: Mail,
    };
    axios

      .post(
        process.env.react_app_productMode === "true"
          ? process.env.react_app_productUrl + "create"
          : process.env.react_app_localUrl + "create",

        newUserData
      )
      .then(navigate("/addUserTab"))
      .then(
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Yeni Kullanıcı Başarı ile Eklendi!",
          showConfirmButton: false,
          timer: 1500,
        })
      );
    setTimeout(() => {
      window.location.reload();
    }, 2500);
  };
  return (
    <div className="AdminUser">
      <br />
      <form onSubmit={handleAdd}>
        <Container>
          <Typography
            align="left"
            variant="subtitle1"
            sx={{ color: "#EE315D" }}
          >
            Sisteme Yeni Kullanıcı Kaydı İçin Bilgileri Giriniz.
          </Typography>

          <TextField
            size="small"
            label="Kullanıcı Adı"
            margin="normal"
            fullWidth
            //name="Username"
            //value={Username}
            required
            onChange={(e) => setUsername(e.target.value)}
          >
            Username
          </TextField>

          <TextField
            type="password"
            size="small"
            label="Şifre"
            margin="normal"
            fullWidth
            //name="Password"
            required
            onChange={(e) => setPassword(e.target.value)}
          >
            Password
          </TextField>
          <TextField
            size="small"
            select
            required
            label="Departman"
            // helperText="Lütfen Departman Seçiniz."
            margin="normal"
            fullWidth
            //name="Department"
            value={selectedDepartment?.value ?? ""}
            // onChange={(e, value) => {
            //   let index = value.key.slice(2);
            //   HandleSelectDepartment(index);
            // }}
            onChange={(e, userValue) => {
              let index = userValue.key.slice(2);
              const { value } = e.target;
              const Departments = department[index];
              setselectedDepartment({
                value: value,
                DepartmentID: Departments._id,
                Department: Departments.Department,
              });
            }}
          >
            {department.map((option, id) => {
              return (
                <MenuItem key={id} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </TextField>
          {/* <TextField
        size="small"
        select
        fullWidth
        label="Yeni Kullanıcı"
        margin="normal"
        required
        value={selectedUser?.Username ?? ""}
        onChange={(e, userValue) => {
          let index = userValue.key.slice(2);
          const { value } = e.target;

          setselectedUser({
            Username: value,
            Department: selectedDepartment.value,
          });
        }}
      >
        {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField> */}
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalPhoneIcon sx={{ width: 20 }} />
                </InputAdornment>
              ),
            }}
            size="small"
            label="Telefon"
            margin="normal"
            fullWidth
            //name="Username"
            required
            onChange={(e) => setPhone(e.target.value)}
          >
            Phone
          </TextField>

          <TextField
            size="small"
            label="Mail"
            margin="normal"
            fullWidth
            //name="Phone"
            required
            onChange={(e) => setMail(e.target.value)}
          >
            Mail
          </TextField>
          <TextField
            size="small"
            label="Adres"
            multiline
            rows={3}
            margin="normal"
            fullWidth
            //name="Username"

            onChange={(e) => setAddress(e.target.value)}
          >
            Adddress
          </TextField>
          {/* <Typography
          align="left"
          variant="caption"
          display="block"
          sx={{ color: "#EE315D" }}
        >
          * Adres Bilgisi Zorunlu Değildir, Diğer Alanları Doldurunuz!
        </Typography> */}
          <TextField
            size="small"
            id="standard-select-currency"
            select
            name="Action"
            label="Statu"
            source={currency}
            onClick={handleChange1}
            //helperText="Please select your Action."
            margin="normal"
            required
            fullWidth
            value={Permission}
            onChange={(e) => setPermission(e.target.value)}
          >
            {currencies.map((option, id) => (
              <MenuItem key={id} value={option.value}>
                {option.label}
              </MenuItem>
            ))}{" "}
          </TextField>

          <br />
          <Button
            className="button-save"
            variant="contained"
            color="secondary"
            label="Kullanıcı Türü"
            sx={{ mt: 5, backgroundColor: "#f44336" }}
            type="submit"
          >
            Kullanıcı Ekle
          </Button>
        </Container>
      </form>
    </div>
  );
}
