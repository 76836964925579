import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Typography,
  Container,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { styled } from "@mui/material/styles";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#EE315D",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#EE315D",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#EE315D",
    },
  },
});
const currencies = [
  {
    value: "User",
    label: "User",
  },
  {
    value: "Stajyer",
    label: "Stajyer",
  },
];

export default function DeptUserAdd() {
  const departments = Cookies.get("departments");
  const [currency, setCurrency] = React.useState("User");
  //const [UserID, setuserID] = useState(null);
  const [Username, setUsername] = useState("");
  const [Address, setAddress] = useState("");
  const [Phone, setPhone] = useState("");
  const [Mail, setMail] = useState("");
  const [Password, setPassword] = useState("");
  const [Permission, setPermission] = useState("");
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setselectedDepartment] = useState();
  const [selectedUser, setselectedUser] = useState("");
  const handleChange1 = (event) => {
    setCurrency(event.target.value);
  };
  const navigate = useNavigate();
  const LoadDepartment = async () => {
    try {
      var axios = require("axios");
      var config = {
        method: "get",
        url:
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "departments"
            : process.env.react_app_localUrl + "departments",

        headers: {},
      };
      const Result = await axios(config);
      const Department = Result.data;
      const EditDepartment = Department.map((el) => ({
        ...el,
        label: el.Department,
        value: el.Department,
      }));
      setDepartment(EditDepartment);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    LoadDepartment();
  }, []);

  const handleAdd = () => {
    if (
      Username !== "" &&
      Password !== "" &&
      Permission !== "" &&
      Mail !== "" &&
      Phone !== ""
    ) {
      const newUserData = {
        Seen: false,
        Username: Username,
        Address: Address,
        Phone: Phone,
        Mail: Mail,
        Password: Password,
        Department: departments,
        Permission: Permission,
        Edit: false,
        ProfilePic: "https://www.flaticon.com/free-icons/user",
      };
      axios

        .post(
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "create"
            : process.env.react_app_localUrl + "create",

          newUserData
        )
        .then(navigate("/addUserTab"))
        .then(
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Yeni Kullanıcı Başarı ile Eklendi!",
            showConfirmButton: false,
            timer: 1500,
          })
        );
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } else {
      Swal.fire({
        title: "HATA!",
        text: "Tüm Bilgileri Giriniz!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div className="DeptUser">
      <br />
      <Container>
        <Typography align="left" variant="subtitle1" sx={{ color: "#EE315D" }}>
          Sisteme Yeni Kullanıcı Kaydı İçin Bilgileri Giriniz.
        </Typography>
        <CssTextField
          size="small"
          label="Kullanıcı Adı"
          margin="normal"
          fullWidth
          //name="Username"
          required
          onChange={(e) => setUsername(e.target.value)}
        >
          Username
        </CssTextField>

        <CssTextField
          type="password"
          size="small"
          label="Şifre"
          margin="normal"
          fullWidth
          //name="Password"
          required
          onChange={(e) => setPassword(e.target.value)}
        >
          Password
        </CssTextField>

        <CssTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {" "}
                <LocalPhoneIcon sx={{ width: 20 }} />
              </InputAdornment>
            ),
          }}
          size="small"
          label="Telefon"
          margin="normal"
          fullWidth
          //name="Phone"
          required
          onChange={(e) => setPhone(e.target.value)}
        >
          Phone
        </CssTextField>
        <CssTextField
          size="small"
          label="Mail"
          margin="normal"
          fullWidth
          //name="Phone"
          required
          onChange={(e) => setMail(e.target.value)}
        >
          Mail
        </CssTextField>
        <CssTextField
          size="small"
          label="Adres"
          multiline
          rows={3}
          margin="normal"
          fullWidth
          //name="Address"

          onChange={(e) => setAddress(e.target.value)}
        >
          Adddress
        </CssTextField>
        {/* <Typography
          align="left"
          variant="caption"
          display="block"
          sx={{ color: "#EE315D" }}
        >
          * Adres Bilgisi Zorunlu Değildir, Diğer Alanları Doldurunuz!
        </Typography> */}
        <CssTextField
          size="small"
          id="standard-select-currency"
          select
          name="Action"
          label="Statu"
          source={currency}
          onClick={handleChange1}
          //helperText="Please select your Action."
          margin="normal"
          required
          fullWidth
          value={Permission}
          onChange={(e) => setPermission(e.target.value)}
        >
          {currencies.map((option, id) => (
            <MenuItem key={id} value={option.value}>
              {option.label}
            </MenuItem>
          ))}{" "}
        </CssTextField>
        <br />
        <Button
          className="button-save"
          variant="contained"
          color="secondary"
          label="Kullanıcı Türü"
          onClick={handleAdd}
          sx={{ mt: 5, backgroundColor: "#f44336" }}
        >
          Kullanıcı Ekle
        </Button>
      </Container>
    </div>
  );
}
