import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Button,
  TextField,
  MenuItem,
  Box,
  Checkbox,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  OutlinedInput,
  Select,
  Typography,
  FormGroup,
  FormControlLabel,
} from "@mui/material/";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

import "./Create.css";
import { styled } from "@mui/material/styles";
import Cookies from "js-cookie";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import Swal from "sweetalert2";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import format from "date-fns/format";
import locale from "date-fns/locale/tr";
import { DateRange } from "react-date-range";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#EE315D",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#EE315D",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#EE315D",
    },
  },
});

const sitil = makeStyles({
  margin: {
    marginTop: 20,
  },
});

const currencies = [
  {
    value: "Yeni Görev",
    label: "Yeni Görev",
  },
  {
    value: "Üzerinde Çalışıyor",
    label: "Üzerinde Çalışıyor",
  },
  {
    value: "Takılmış",
    label: "Takılmış",
  },
  {
    value: "Tamamlandı",
    label: "Tamamlandı",
  },
  {
    value: "Beklemede",
    label: "Beklemede",
  },
];
const currencies2 = [
  {
    value: "Yüksek",
    label: "Yüksek",
  },
  {
    value: "Orta",
    label: "Orta",
  },
  {
    value: "Düşük",
    label: "Düşük",
  },
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Create = () => {
  const [checkedAllUser, setCheckedAllUser] = useState(false);

  const [openCalendar, setOpenCalendar] = useState(false);
  const permission = Cookies.get("permission");
  const UsernameCookie = Cookies.get("username");
  const Departments = Cookies.get("departments");
  const userId = Cookies.get("userID");
  const NotfcToken = Cookies.get("NotfcToken");
  const [open, setOpen] = useState(false);
  // const [open2, setOpen2] = useState(false);
  // const [open3, setOpen3] = useState(false);

  const [currency, setCurrency] = useState("Yeni Görev");
  const [currency2, setCurrency2] = useState("Orta");
  const createSitil = sitil();
  const [UsernameInput, setUsernameInput] = useState("");
  const [DescriptionInput, setDescriptionInput] = useState("");
  const [Title, setTitle] = useState("");
  const [Action, setAction] = useState("Yeni Görev");
  const [Status, setStatus] = useState("Orta");
  const [GivenDate, setGivenDate] = useState(moment());
  // const [StartDate, setStartDate] = useState();
  // const [EndDate, setEndDate] = useState();
  const [selectedUser, setselectedUser] = React.useState([]);
  const [selectedDepartment, setselectedDepartment] = useState();
  let [Users, setUsers] = useState([]);
  let [department, setDepartment] = useState([]);
  const [ischecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  // const [ischecked2, setIsChecked2] = useState(false);
  const orientation = window.matchMedia("(max-width: 700px)").matches
    ? "vertical"
    : "horizontal";
  const handleChange1 = (event) => {
    setCurrency(event.target.value);
  };
  const handleChange2 = (event) => {
    setCurrency2(event.target.value);
  };

  const handleChangeBroadCast = () => {
    setCheckedAllUser(!checkedAllUser);
  };

  const HandleSelectDepartment = (index) => {
    const Departments = department[index];
    const DepartmentID = Departments._id;
    const value = Departments.value;
    const Department = Departments.Department;
    setselectedDepartment({
      value: value,
      DepartmentID: DepartmentID,
      Department: Department,
    });
  };

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleUser = (e) => {
    e.preventDefault();
    if (ischecked === true) {
      const HelperArray = [
        {
          Seen: true,
          UserID: userId,
          Username: UsernameCookie,
          Edit: true,
          Department: Departments,
          Permission: permission,
          NotfcToken,
        },
      ];

      selectedUser.forEach((el) => {
        const currentUser = Users.find((ele) => el === ele.Username);
        HelperArray.push(currentUser);
      });

      if (Action === "Tamamlandı") {
        const newTaskData = {
          Title: Title,
          Description: DescriptionInput,
          GivenDate:
            new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,

          StartDate:
            new Date(
              moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
            ).getTime() / 1000,
          EndDate:
            new Date(
              moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
            ).getTime() / 1000,
          Action,
          Status,
          Users: HelperArray,
          TaskAdmin: UsernameCookie,
          Departments: Departments,
          TaskCompleteDate:
            new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,
        };

        axios
          .post(
            process.env.react_app_productMode === "true"
              ? process.env.react_app_productUrl + "tasks/create"
              : process.env.react_app_localUrl + "tasks/create",
            newTaskData
          )
          .then(({ data }) =>
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${data.Users.map(
                (el) => el.Username
              )} İçin Görev Eklendi !`,
              showConfirmButton: false,
              timer: 2700,
            })
          )
          .then(navigate("/"));
      } else {
        const newTaskData = {
          Title: Title,
          Description: DescriptionInput,
          GivenDate:
            new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,

          StartDate:
            new Date(moment().format("YYYY-MM-DD 00:00:00Z")).getTime() / 1000,
          EndDate:
            new Date(moment().format("YYYY-MM-DD 23:59:59Z")).getTime() / 1000,
          Action,
          Status,
          Users: HelperArray,
          TaskAdmin: UsernameCookie,
          Departments: Departments,
          TaskCompleteDate: "",
        };

        axios
          .post(
            process.env.react_app_productMode === "true"
              ? process.env.react_app_productUrl + "tasks/create"
              : process.env.react_app_localUrl + "tasks/create",

            newTaskData
          )
          .then(({ data }) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${data.Users.map(
                (el) => el.Username
              )} İçin Görev Eklendi !`,
              showConfirmButton: false,
              timer: 2700,
            });
          })
          .then(navigate("/"));
      }
    } else {
      if (Action === "Tamamlandı") {
        const newTaskData = {
          Title: Title,
          Description: DescriptionInput,
          GivenDate:
            new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,

          StartDate:
            new Date(
              moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
            ).getTime() / 1000,
          EndDate:
            new Date(
              moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
            ).getTime() / 1000,
          Action,
          Status,
          Users: [
            {
              Seen: true,
              UserID: userId,
              Username: UsernameCookie,
              Edit: true,
              Department: Departments,
              Permission: permission,
              NotfcToken,
            },
          ],
          TaskAdmin: UsernameCookie,
          Departments: Departments,
          TaskCompleteDate:
            new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,
        };

        axios
          .post(
            process.env.react_app_productMode === "true"
              ? process.env.react_app_productUrl + "tasks/create"
              : process.env.react_app_localUrl + "tasks/create",

            newTaskData
          )
          .then((res) => {})
          .then(navigate("/"))
          .then(
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Görev Başarıyla Kaydedildi!",
              showConfirmButton: false,
              timer: 1500,
            })
          );
      } else {
        const newTaskData = {
          Title: Title,
          Description: DescriptionInput,
          GivenDate:
            new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,
          // StartDate: moment(StartDate).format().slice(0, -6),
          // EndDate: moment(EndDate).format().slice(0, -6),
          StartDate:
            new Date(
              moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
            ).getTime() / 1000,
          EndDate:
            new Date(
              moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
            ).getTime() / 1000,
          Action,
          Status,
          Users: [
            {
              Seen: true,
              UserID: userId,
              Username: UsernameCookie,
              Edit: true,
              Department: Departments,
              Permission: permission,
              NotfcToken: NotfcToken,
            },
          ],
          TaskAdmin: UsernameCookie,
          Departments: Departments,
          TaskCompleteDate: "",
        };

        axios
          .post(
            process.env.react_app_productMode === "true"
              ? process.env.react_app_productUrl + "tasks/create"
              : process.env.react_app_localUrl + "tasks/create",

            newTaskData
          )
          .then((res) => {})
          .then(navigate("/"))
          .then(
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Görev Başarıyla Kaydedildi!",
              showConfirmButton: false,
              timer: 1500,
            })
          );
      }
    }
    setTimeout(() => {
      window.location.href = "/";
    }, 2500);
  };

  const handleDeptManager = async (e) => {
    e.preventDefault();
    if (ischecked) {
      const HelperArray = [];

      selectedUser.forEach((el) => {
        const currentUser = Users.find((ele) => el === ele.Username);
        HelperArray.push(currentUser);
      });

      if (selectedDepartment?.value === Departments) {
        if (Action === "Tamamlandı") {
          const bodyObject = {
            Title: Title,
            Description: DescriptionInput,
            GivenDate:
              new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,

            StartDate:
              new Date(
                moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
              ).getTime() / 1000,
            EndDate:
              new Date(
                moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
              ).getTime() / 1000,
            Action,
            Status,
            Users: [],
            TaskAdmin: HelperArray[0].Username,
            Departments: HelperArray[0].Department,
            TaskCompleteDate:
              new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,
          };

          const Check = HelperArray.filter((x) => x.UserID == userId);

          if (Check?.length === 1) {
            HelperArray.map((el, i) => {
              if (selectedUser?.length === 1) {
                bodyObject.Users.push(el);
                bodyObject.Users[i].Seen = true;
                bodyObject.Users[i].Edit = true;
              } else {
                if (el?.UserID == userId) {
                  bodyObject.Users.push(el);
                  bodyObject.Users[i].Seen = true;
                  bodyObject.Users[i].Edit = true;
                } else {
                  bodyObject.Users.push(el);
                  bodyObject.Users[i].Seen = false;
                  bodyObject.Users[i].Edit = false;
                }
              }
            });
          } else {
            HelperArray.map((el, i) => {
              if (selectedUser.length === 1) {
                bodyObject.Users.push(el);
                bodyObject.Users[i].Edit = true;
              } else {
                bodyObject.Users.push(el);
                bodyObject.Users[i].Edit = false;
              }
            });
          }

          axios
            .post(
              process.env.react_app_productMode === "true"
                ? process.env.react_app_productUrl + "tasks/create"
                : process.env.react_app_localUrl + "tasks/create",

              bodyObject
            )
            .then(({ data }) =>
              Swal.fire({
                position: "center",
                icon: "success",
                title: `${data.Users.map(
                  (el) => el.Username
                )} İçin Görev Eklendi !`,
                showConfirmButton: false,
                timer: 2700,
              })
            )
            .then(navigate("/"));
        } else {
          const bodyObject = {
            Title: Title,
            Description: DescriptionInput,
            GivenDate:
              new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,

            StartDate:
              new Date(
                moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
              ).getTime() / 1000,
            EndDate:
              new Date(
                moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
              ).getTime() / 1000,
            Action,
            Status,
            Users: [],
            TaskAdmin: HelperArray[0].Username,
            Departments: HelperArray[0].Department,
            TaskCompleteDate: "",
          };

          const Check = HelperArray.filter((x) => x.UserID == userId);

          if (Check?.length === 1) {
            HelperArray.map((el, i) => {
              if (selectedUser?.length === 1) {
                bodyObject.Users.push(el);
                bodyObject.Users[i].Seen = true;
                bodyObject.Users[i].Edit = true;
              } else {
                if (el?.UserID == userId) {
                  bodyObject.Users.push(el);
                  bodyObject.Users[i].Seen = true;
                  bodyObject.Users[i].Edit = true;
                } else {
                  bodyObject.Users.push(el);
                  bodyObject.Users[i].Seen = false;
                  bodyObject.Users[i].Edit = false;
                }
              }
            });
          } else {
            HelperArray.map((el, i) => {
              if (selectedUser.length === 1) {
                bodyObject.Users.push(el);
                bodyObject.Users[i].Edit = true;
              } else {
                bodyObject.Users.push(el);
                bodyObject.Users[i].Edit = false;
              }
            });
          }

          axios
            .post(
              process.env.react_app_productMode === "true"
                ? process.env.react_app_productUrl + "tasks/create"
                : process.env.react_app_localUrl + "tasks/create",

              bodyObject
            )
            .then(({ data }) =>
              Swal.fire({
                position: "center",
                icon: "success",
                title: `${data.Users.map(
                  (el) => el.Username
                )} İçin Görev Eklendi !`,
                showConfirmButton: false,
                timer: 2700,
              })
            )
            .then(navigate("/"));
        }
      } else {
        const User = selectedUser.find((el) => el == UsernameCookie);
        if (User) {
          const HelperArray = [];
          selectedUser.forEach((el, i) => {
            const currentUser = Users.find((ele) => el === ele.Username);
            HelperArray.push(currentUser);

            if (el == UsernameCookie) {
              HelperArray[i].Seen = true;
              HelperArray[i].Edit = true;
            }
          });

          if (Action === "Tamamlandı") {
            const bodyObject = {
              Title: Title,
              Description: DescriptionInput,
              GivenDate:
                new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() /
                1000,

              StartDate:
                new Date(
                  moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
                ).getTime() / 1000,
              EndDate:
                new Date(
                  moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
                ).getTime() / 1000,
              Action,
              Status,
              Users: HelperArray,
              TaskAdmin: HelperArray[0].Username,
              Departments: HelperArray[0].Department,
              TaskCompleteDate:
                new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() /
                1000,
            };

            axios
              .post(
                process.env.react_app_productMode === "true"
                  ? process.env.react_app_productUrl + "tasks/create"
                  : process.env.react_app_localUrl + "tasks/create",

                bodyObject
              )
              .then(({ data }) =>
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: `${data.Users.map(
                    (el) => el.Username
                  )} İçin Görev Eklendi !`,
                  showConfirmButton: false,
                  timer: 2700,
                })
              )
              .then(navigate("/"));
          } else {
            const bodyObject = {
              Title: Title,
              Description: DescriptionInput,
              GivenDate:
                new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() /
                1000,

              StartDate:
                new Date(
                  moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
                ).getTime() / 1000,
              EndDate:
                new Date(
                  moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
                ).getTime() / 1000,
              Action,
              Status,
              Users: HelperArray,
              TaskAdmin: HelperArray[0].Username,
              Departments: HelperArray[0].Department,
              TaskCompleteDate: "",
            };

            axios
              .post(
                process.env.react_app_productMode === "true"
                  ? process.env.react_app_productUrl + "tasks/create"
                  : process.env.react_app_localUrl + "tasks/create",

                bodyObject
              )
              .then(({ data }) =>
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: `${data.Users.map(
                    (el) => el.Username
                  )} İçin Görev Eklendi !`,
                  showConfirmButton: false,
                  timer: 2700,
                })
              )
              .then(navigate("/"));
          }
        } else {
          const HelperArray = [
            {
              Seen: true,
              UserID: userId,
              Username: UsernameCookie,
              Edit: true,
              Department: Departments,
              Permission: permission,
              NotfcToken,
            },
          ];

          selectedUser.forEach((el) => {
            const currentUser = Users.find((ele) => el === ele.Username);
            HelperArray.push(currentUser);
          });

          if (Action === "Tamamlandı") {
            const bodyObject = {
              Title: Title,
              Description: DescriptionInput,
              GivenDate:
                new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() /
                1000,

              StartDate:
                new Date(
                  moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
                ).getTime() / 1000,
              EndDate:
                new Date(
                  moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
                ).getTime() / 1000,
              Action,
              Status,
              Users: HelperArray,
              TaskAdmin: HelperArray[0].Username,
              Departments: HelperArray[0].Department,
              TaskCompleteDate:
                new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() /
                1000,
            };
            axios
              .post(
                process.env.react_app_productMode === "true"
                  ? process.env.react_app_productUrl + "tasks/create"
                  : process.env.react_app_localUrl + "tasks/create",

                bodyObject
              )
              .then(({ data }) =>
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: `${data.Users.map(
                    (el) => el.Username
                  )} İçin Görev Eklendi !`,
                  showConfirmButton: false,
                  timer: 2700,
                })
              )
              .then(navigate("/"));
          } else {
            const bodyObject = {
              Title: Title,
              Description: DescriptionInput,
              GivenDate:
                new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() /
                1000,

              StartDate:
                new Date(
                  moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
                ).getTime() / 1000,
              EndDate:
                new Date(
                  moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
                ).getTime() / 1000,
              Action,
              Status,
              Users: HelperArray,
              TaskAdmin: HelperArray[0].Username,
              Departments: HelperArray[0].Department,
              TaskCompleteDate: "",
            };
            axios
              .post(
                process.env.react_app_productMode === "true"
                  ? process.env.react_app_productUrl + "tasks/create"
                  : process.env.react_app_localUrl + "tasks/create",

                bodyObject
              )
              .then(({ data }) =>
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: `${data.Users.map(
                    (el) => el.Username
                  )} İçin Görev Eklendi !`,
                  showConfirmButton: false,
                  timer: 2700,
                })
              )
              .then(navigate("/"));
          }
        }
      }
    } else {
      if (Action === "Tamamlandı") {
        const bodyObject = {
          Title,
          Description: DescriptionInput,
          GivenDate:
            new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,

          StartDate:
            new Date(
              moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
            ).getTime() / 1000,
          EndDate:
            new Date(
              moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
            ).getTime() / 1000,
          Action,
          Status,
          Users: [
            {
              Seen: true,
              UserID: userId,
              Username: UsernameCookie,
              Edit: true,
              Department: Departments,
              Permission: permission,
              NotfcToken,
            },
          ],
          TaskAdmin: UsernameCookie,
          Departments: Departments,
          TaskCompleteDate:
            new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,
        };
        e.preventDefault();
        axios
          .post(
            process.env.react_app_productMode === "true"
              ? process.env.react_app_productUrl + "tasks/create"
              : process.env.react_app_localUrl + "tasks/create",

            bodyObject
          )
          .then(navigate("/"))
          .then(
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Görev Başarıyla Kaydedildi!",
              showConfirmButton: false,
              timer: 1500,
            })
          );
      } else {
        const bodyObject = {
          Title,

          Description: DescriptionInput,
          GivenDate:
            new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,

          StartDate:
            new Date(
              moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
            ).getTime() / 1000,
          EndDate:
            new Date(
              moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
            ).getTime() / 1000,
          Action,
          Status,
          Users: [
            {
              Seen: true,
              UserID: userId,
              Username: UsernameCookie,
              Edit: true,
              Department: Departments,
              Permission: permission,
              NotfcToken,
            },
          ],
          TaskAdmin: UsernameCookie,
          Departments: Departments,
          TaskCompleteDate: "",
        };
        e.preventDefault();
        axios
          .post(
            process.env.react_app_productMode === "true"
              ? process.env.react_app_productUrl + "tasks/create"
              : process.env.react_app_localUrl + "tasks/create",

            bodyObject
          )

          .then(navigate("/"))
          .then(
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Görev Başarıyla Kaydedildi!",
              showConfirmButton: false,
              timer: 1500,
            })
          );
      }
    }
    setTimeout(() => {
      window.location.reload();
    }, 2500);
  };

  const handleSubmitAdmin = async (e) => {
    e.preventDefault();

    if (ischecked === true) {
      const HelperArray = [];

      selectedUser.forEach((el) => {
        const currentUser = Users.find((ele) => el === ele.Username);
        HelperArray.push(currentUser);
      });

      if (Action === "Tamamlandı") {
        const bodyObject = {
          Title: Title,
          Description: DescriptionInput,
          GivenDate:
            new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,

          StartDate:
            new Date(
              moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
            ).getTime() / 1000,
          EndDate:
            new Date(
              moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
            ).getTime() / 1000,
          Action,
          Status,
          Users: [],
          TaskAdmin: HelperArray[0].Username,
          Departments: HelperArray[0].Department,
          TaskCompleteDate:
            new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,
        };

        const Check = HelperArray.filter((x) => x.UserID == userId);

        if (Check?.length === 1) {
          HelperArray.map((el, i) => {
            if (selectedUser?.length === 1) {
              bodyObject.Users.push(el);
              bodyObject.Users[i].Seen = true;
              bodyObject.Users[i].Edit = true;
            } else {
              if (el?.UserID == userId) {
                bodyObject.Users.push(el);
                bodyObject.Users[i].Seen = true;
                bodyObject.Users[i].Edit = true;
              } else {
                bodyObject.Users.push(el);
                bodyObject.Users[i].Seen = false;
                bodyObject.Users[i].Edit = false;
              }
            }
          });
        } else {
          HelperArray.map((el, i) => {
            if (selectedUser.length === 1) {
              bodyObject.Users.push(el);
              bodyObject.Users[i].Edit = true;
            } else {
              bodyObject.Users.push(el);
              bodyObject.Users[i].Edit = false;
            }
          });
        }

        axios
          .post(
            process.env.react_app_productMode === "true"
              ? process.env.react_app_productUrl + "tasks/create"
              : process.env.react_app_localUrl + "tasks/create",

            bodyObject
          )
          .then(({ data }) =>
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${data.Users.map(
                (el) => el.Username
              )} İçin Görev Eklendi !`,
              showConfirmButton: false,
              timer: 2700,
            })
          )
          .then(navigate("/"));
      } else {
        if (checkedAllUser === true) {
          Users.forEach((el) => {
            // const currentUser = el.map((ele) => ele.Username);
            HelperArray.push(el);
          });

          const bodyObject = {
            Title: Title,
            Description: DescriptionInput,
            GivenDate:
              new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,

            StartDate:
              new Date(
                moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
              ).getTime() / 1000,
            EndDate:
              new Date(
                moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
              ).getTime() / 1000,
            Action,
            Status,
            Users: [],
            TaskAdmin: HelperArray[0].Username,
            Departments: HelperArray[0].Department,
            TaskCompleteDate: "",
          };

          // const Check = HelperArray.filter((x) => x.UserID == userId);

          HelperArray.map((el, i) => {
            bodyObject.Users.push(el);
            bodyObject.Users[i].Seen = false;
            bodyObject.Users[i].Edit = false;
          });

          axios
            .post(
              process.env.react_app_productMode === "true"
                ? process.env.react_app_productUrl + "tasks/create"
                : process.env.react_app_localUrl + "tasks/create",

              bodyObject
            )
            .then(({ data }) =>
              Swal.fire({
                position: "center",
                icon: "success",
                title: `${data.Users.map(
                  (el) => el.Username
                )} İçin Görev Eklendi !`,
                showConfirmButton: false,
                timer: 2700,
              })
            )
            .then(navigate("/"));
        } else {
          const bodyObject = {
            Title: Title,
            Description: DescriptionInput,
            GivenDate:
              new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,

            StartDate:
              new Date(
                moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
              ).getTime() / 1000,
            EndDate:
              new Date(
                moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
              ).getTime() / 1000,
            Action,
            Status,
            Users: [],
            TaskAdmin: HelperArray[0].Username,
            Departments: HelperArray[0].Department,
            TaskCompleteDate: "",
          };

          const Check = HelperArray.filter((x) => x.UserID == userId);

          if (Check?.length === 1) {
            HelperArray.map((el, i) => {
              if (selectedUser?.length === 1) {
                bodyObject.Users.push(el);
                bodyObject.Users[i].Seen = true;
                bodyObject.Users[i].Edit = true;
              } else {
                if (el?.UserID == userId) {
                  bodyObject.Users.push(el);
                  bodyObject.Users[i].Seen = true;
                  bodyObject.Users[i].Edit = true;
                } else {
                  bodyObject.Users.push(el);
                  bodyObject.Users[i].Seen = false;
                  bodyObject.Users[i].Edit = false;
                }
              }
            });
          } else {
            HelperArray.map((el, i) => {
              if (selectedUser.length === 1) {
                bodyObject.Users.push(el);
                bodyObject.Users[i].Edit = true;
              } else {
                bodyObject.Users.push(el);
                bodyObject.Users[i].Edit = false;
              }
            });
          }

          axios
            .post(
              process.env.react_app_productMode === "true"
                ? process.env.react_app_productUrl + "tasks/create"
                : process.env.react_app_localUrl + "tasks/create",

              bodyObject
            )
            .then(({ data }) =>
              Swal.fire({
                position: "center",
                icon: "success",
                title: `${data.Users.map(
                  (el) => el.Username
                )} İçin Görev Eklendi !`,
                showConfirmButton: false,
                timer: 2700,
              })
            )
            .then(navigate("/"));
        }
      }
    } else {
      if (Action === "Tamamlandı") {
        const bodyObject = {
          Title,
          Description: DescriptionInput,
          GivenDate:
            new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,
          StartDate:
            new Date(
              moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
            ).getTime() / 1000,
          EndDate:
            new Date(
              moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
            ).getTime() / 1000,
          Action,
          Status,
          Users: [
            {
              Seen: true,
              UserID: userId,
              Username: UsernameCookie,
              Edit: true,
              Department: Departments,
              Permission: permission,
              NotfcToken,
            },
          ],
          TaskAdmin: UsernameCookie,
          Departments: Departments,
          TaskCompleteDate:
            new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,
        };
        e.preventDefault();
        axios
          .post(
            process.env.react_app_productMode === "true"
              ? process.env.react_app_productUrl + "tasks/create"
              : process.env.react_app_localUrl + "tasks/create",

            bodyObject
          )
          .then(navigate("/"))
          .then(
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Görev Başarıyla Kaydedildi!",
              showConfirmButton: false,
              timer: 1500,
            })
          );
      } else {
        const bodyObject = {
          Title,
          Description: DescriptionInput,
          GivenDate:
            new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000,

          StartDate:
            new Date(
              moment(range[0].startDate).format("YYYY-MM-DD 00:00:00Z")
            ).getTime() / 1000,
          EndDate:
            new Date(
              moment(range[0].endDate).format("YYYY-MM-DD 23:59:59Z")
            ).getTime() / 1000,
          Action,
          Status,
          Users: [
            {
              Seen: true,
              UserID: userId,
              Username: UsernameCookie,
              Edit: true,
              Department: Departments,
              Permission: permission,
              NotfcToken,
            },
          ],
          TaskAdmin: UsernameCookie,
          Departments: Departments,
          TaskCompleteDate: "",
        };
        e.preventDefault();
        axios

          .post(
            process.env.react_app_productMode === "true"
              ? process.env.react_app_productUrl + "tasks/create"
              : process.env.react_app_localUrl + "tasks/create",

            bodyObject
          )

          .then(navigate("/"))
          .then(
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Görev Başarıyla Kaydedildi!",
              showConfirmButton: false,
              timer: 1500,
            })
          );
      }
    }
    setTimeout(() => {
      window.location.reload();
    }, 2500);
  };

  const LoadUser = async () => {
    try {
      var axios = require("axios");
      var config = {
        method: "get",

        url:
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "Users"
            : process.env.react_app_localUrl + "Users",

        headers: {},
      };
      const Result = await axios(config);
      const Users = Result.data;
      const EditUsers = Users.map((el) => ({
        ...el,
        label: el.Username,
        value: el.Username,
        UserID: el._id,
      }));
      setUsers(EditUsers);
    } catch (error) {
      console.log(error);
    }
  };

  const LoadDepartment = async () => {
    try {
      var axios = require("axios");
      var config = {
        method: "get",

        url:
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "departments"
            : process.env.react_app_localUrl + "departments",
        headers: {},
      };
      const Result = await axios(config);
      const Department = Result.data;
      const EditDepartment = Department.map((el) => ({
        ...el,
        label: el.Department,
        value: el.Department,
      }));
      setDepartment(EditDepartment);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutSide, true);
    LoadUser();
    LoadDepartment();
  }, []);

  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpenCalendar(false);
    }
  };

  const refOne = useRef(null);
  const hideOnClickOutSide = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpenCalendar(false);
    }
  };

  const handleChangeAccordion = () => {
    setIsChecked(!ischecked);
    if (ischecked === false) {
      setCheckedAllUser(false);
    }
  };
  moment.locale("tr", locale);

  if (permission === "admin") {
    return (
      <Container className="create">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            component="form"
            onSubmit={handleSubmitAdmin}
            className={createSitil}
          >
            <Accordion
              onChange={handleChangeAccordion}
              sx={{ borderRadius: "5px" }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ backgroundColor: "#EE315D", borderRadius: "5px" }}
              >
                <ExpandMoreIcon
                  sx={{ color: "white", width: 60, height: 30 }}
                />
                <Typography
                  variant="h6"
                  display="block"
                  sx={{ color: "white" }}
                >
                  Başka Kullanıcıya Görev Eklemek İçin Tıklayınız...
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Tüm Kullanıcılara Görev Gönder"
                    checked={checkedAllUser}
                    onChange={handleChangeBroadCast}
                  />
                </FormGroup>

                <CssTextField
                  disabled={checkedAllUser}
                  className="select-dept"
                  sx={{
                    border: 1,
                    borderColor: "white",
                  }}
                  required={ischecked}
                  select
                  label="Departman Seçiniz"
                  // helperText="Lütfen Departman Seçiniz."
                  margin="normal"
                  fullWidth
                  name="DepartmentInput"
                  value={selectedDepartment?.value ?? ""}
                  onChange={(e, value) => {
                    let index = value.key.slice(2);
                    HandleSelectDepartment(index);
                  }}
                >
                  {department.map((option, id) => {
                    return (
                      <MenuItem key={id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </CssTextField>
                <Select
                  disabled={checkedAllUser}
                  className="selec-user"
                  sx={{
                    border: 1,
                    borderColor: "white",
                    color: "#EE315D",
                  }}
                  label="Kullanıcı"
                  required={ischecked}
                  fullWidth
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  name="Username"
                  value={selectedUser}
                  onChange={(e) => {
                    const value =
                      typeof e.target.value === "string"
                        ? e.target.value.split(",")
                        : e.target.value;
                    setselectedUser(value);
                  }}
                  input={<OutlinedInput label="Kullanıcı" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {Users.filter(
                    (el) => el.Department === selectedDepartment?.value
                  )
                    .map((ele) => ele.Username)
                    .map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={selectedUser.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                </Select>
              </AccordionDetails>
            </Accordion>

            <br />
            <DatePicker
              disabled
              open={open}
              onOpen={() => setOpen(false)}
              onClose={() => setOpen(false)}
              label="Görevin Veriliş Tarihi"
              margin="normal"
              name="GivenDate"
              value={GivenDate}
              onChange={(newvalue) => {
                setGivenDate(newvalue);
              }}
              renderInput={(params) => {
                return (
                  <CssTextField
                    margin="normal"
                    fullWidth
                    required
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => setOpen(false)}
                  />
                );
              }}
            />
            <br />
            <CssTextField
              label="Görev Başlığı"
              margin="normal"
              fullWidth
              name="Title"
              required
              onChange={(e) => setTitle(e.target.value)}
            >
              Task Title
            </CssTextField>
            <br />
            <CssTextField
              label="Açıklama"
              name="Description"
              margin="normal"
              fullWidth
              required
              multiline
              maxRows={4}
              minRows={2}
              onChange={(e) => setDescriptionInput(e.target.value)}
            >
              Task Details
            </CssTextField>
            <br />
            <CssTextField
              className="ActionStatus"
              inputProps={{
                className: createSitil.statu,
              }}
              style={{
                borderRadius: "5px",
                backgroundColor: !!(Action === "Takılmış")
                  ? "#EE315D"
                  : Action === "Tamamlandı"
                  ? "#009B35"
                  : Action === "Üzerinde Çalışıyor"
                  ? "#e3711c"
                  : Action === "Yeni Görev"
                  ? "#8363b4"
                  : "#0074ff",
              }}
              id="standard-select-currency"
              select
              name="Action"
              label="Durum"
              source={currency}
              onClick={handleChange1}
              margin="normal"
              required
              fullWidth
              value={Action}
              onChange={(e) => setAction(e.target.value)}
            >
              {currencies.map((option, id) => (
                <MenuItem key={id} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}{" "}
            </CssTextField>
            <br />
            <CssTextField
              className="ActionStatus"
              inputProps={{
                className: createSitil.statu,
              }}
              style={{
                borderRadius: "5px",
                backgroundColor: !!(Status === "Yüksek")
                  ? "#EE315D"
                  : Status === "Orta"
                  ? "#009B35"
                  : "#e3711c",
              }}
              id="standard-select-currency"
              select
              label="Öncelik"
              name="Status"
              source={currency2}
              onClick={handleChange2}
              margin="normal"
              required
              fullWidth
              value={Status}
              onChange={(e) => setStatus(e.target.value)}
            >
              {currencies2.map((option, id) => (
                <MenuItem key={id} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}{" "}
            </CssTextField>
            <br />

            {/* <DatePicker
              open={open2}
              onOpen={() => setOpen2(true)}
              onClose={() => setOpen2(false)}
              label="Görev Başlangıç Tarihi"
              margin="normal"
              name="StartDate"
              value={StartDate}
              onChange={(newvalue) => {
                setStartDate(newvalue);
              }}
              renderInput={(params) => {
                return (
                  <CssTextField
                    className="date-start"
                    margin="normal"
                    fullWidth
                    required
                    {...params}
                    onClick={() => setOpen2(true)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                );
              }}
            />
            <br />
            <DatePicker
              formatDate={moment().format("YYYY.MM.DDT00:00:00")}
              open={open3}
              onOpen={() => setOpen3(false)}
              onClose={() => setOpen3(false)}
              label="Görev Tahmini Bitiş Tarihi"
              margin="normal"
              name="EndDate"
              value={EndDate}
              onChange={(newvalue) => {
                setEndDate(newvalue);
              }}
              renderInput={(params) => {
                return (
                  <CssTextField
                    className="date-end"
                    margin="normal"
                    fullWidth
                    required
                    {...params}
                    onClick={() => setOpen3(true)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                );
              }}
            /> */}
            <div className="calendarWrap">
              <CssTextField
                margin="normal"
                label="Görev Başlangıç Tarihi"
                required
                value={`${format(range[0].startDate, "dd/MM/yyyy")}`}
                readOnly
                className="inputBox"
                onClick={() => setOpenCalendar((openCalendar) => !openCalendar)}
              />

              <CssTextField
                margin="normal"
                label="Görev Tahmini Bitiş Tarihi"
                required
                value={` ${format(range[0].endDate, "dd/MM/yyyy")}`}
                readOnly
                className="inputBox"
                onClick={() => setOpenCalendar((openCalendar) => !openCalendar)}
              />
              <div ref={refOne}>
                {openCalendar && (
                  <DateRange
                    // dateDisplayFormat="yyyy/MM/dd h:mm aa"
                    onChange={(item) => setRange([item.selection])}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                    ranges={range}
                    months={2}
                    direction={orientation}
                    className="calendarElement"
                    locale={locale}
                  />
                )}
              </div>
            </div>
            <br />

            <Button
              className="button-save"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Kaydet
            </Button>
          </Box>
        </LocalizationProvider>
      </Container>
    );
  }

  if (permission === "DepartmentManager") {
    return (
      <Container className="create">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            component="form"
            onSubmit={handleDeptManager}
            className={createSitil}
          >
            <Accordion
              onChange={handleChangeAccordion}
              sx={{ borderRadius: "5px" }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ backgroundColor: "#EE315D", borderRadius: "5px" }}
              >
                <ExpandMoreIcon
                  sx={{
                    color: "white",
                    width: 60,
                    height: 30,
                  }}
                />
                <Typography
                  variant="h6"
                  display="block"
                  sx={{ color: "white" }}
                >
                  Başka Kullanıcıya Görev Eklemek İçin Tıklayınız...
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  className="select-dept"
                  sx={{
                    border: 1,
                    borderColor: "white",
                  }}
                  required={ischecked}
                  select
                  label="Departman Seçiniz "
                  margin="normal"
                  fullWidth
                  name="Department"
                  value={selectedDepartment?.value ?? ""}
                  onChange={(e, value) => {
                    let index = value.key.slice(2);
                    HandleSelectDepartment(index);
                  }}
                >
                  {department.map((option, id) => {
                    return (
                      <MenuItem key={id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <Select
                  className="selec-user"
                  sx={{
                    border: 1,
                    borderColor: "white",
                    color: "#EE315D",
                  }}
                  required={ischecked}
                  fullWidth
                  multiple
                  name="Username"
                  value={selectedUser}
                  onChange={(e) => {
                    const value =
                      typeof e.target.value === "string"
                        ? e.target.value.split(",")
                        : e.target.value;
                    setselectedUser(value);
                  }}
                  input={<OutlinedInput label={"Kullanıcı"} />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {Users.filter(
                    (el) => el.Department === selectedDepartment?.value
                  )
                    .map((ele) => ele.Username)
                    .map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={selectedUser.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                </Select>
              </AccordionDetails>
            </Accordion>

            <br />
            <DatePicker
              disabled
              open={open}
              onOpen={() => setOpen(false)}
              onClose={() => setOpen(false)}
              label="Görev Kayıt Tarihi"
              margin="normal"
              name="GivenDate"
              value={GivenDate}
              onChange={(newvalue) => {
                setGivenDate(newvalue);
              }}
              renderInput={(params) => {
                return (
                  <CssTextField
                    margin="normal"
                    fullWidth
                    required
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => setOpen(false)}
                  />
                );
              }}
            />
            <br />
            <CssTextField
              label="Görev Başlığı"
              margin="normal"
              fullWidth
              name="Title"
              required
              onChange={(e) => setTitle(e.target.value)}
            >
              Task Title
            </CssTextField>
            <br />
            <CssTextField
              label="Açıklama"
              name="Description"
              margin="normal"
              fullWidth
              required
              multiline
              maxRows={4}
              minRows={2}
              onChange={(e) => setDescriptionInput(e.target.value)}
            >
              Task Details
            </CssTextField>
            <br />
            <CssTextField
              inputProps={{
                className: createSitil.statu,
              }}
              style={{
                borderRadius: "5px",
                backgroundColor: !!(Action === "Takılmış")
                  ? "#EE315D"
                  : Action === "Tamamlandı"
                  ? "#009B35"
                  : Action === "Üzerinde Çalışıyor"
                  ? "#e3711c"
                  : Action === "Yeni Görev"
                  ? "#8363b4"
                  : "#0074ff",
              }}
              id="standard-select-currency"
              select
              name="Action"
              className="ActionStatus"
              label="Durum"
              source={currency}
              onClick={handleChange1}
              margin="normal"
              required
              fullWidth
              value={Action}
              onChange={(e) => setAction(e.target.value)}
            >
              {currencies.map((option, id) => (
                <MenuItem key={id} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}{" "}
            </CssTextField>
            <br />
            <CssTextField
              className="ActionStatus"
              inputProps={{
                className: createSitil.statu,
              }}
              style={{
                borderRadius: "5px",
                backgroundColor: !!(Status === "Yüksek")
                  ? "#EE315D"
                  : Status === "Orta"
                  ? "#009B35"
                  : "#e3711c",
              }}
              id="standard-select-currency"
              select
              label="Öncelik"
              name="Status"
              source={currency2}
              onClick={handleChange2}
              margin="normal"
              required
              fullWidth
              value={Status}
              onChange={(e) => setStatus(e.target.value)}
            >
              {currencies2.map((option, id) => (
                <MenuItem key={id} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}{" "}
            </CssTextField>
            <br />

            <div className="calendarWrap">
              <CssTextField
                margin="normal"
                label="Görev Başlangıç Tarihi"
                required
                value={`${format(range[0].startDate, "dd/MM/yyyy")}`}
                readOnly
                className="inputBox"
                onClick={() => setOpenCalendar((openCalendar) => !openCalendar)}
              />

              <CssTextField
                margin="normal"
                label="Görev Tahmini Bitiş Tarihi"
                required
                value={` ${format(range[0].endDate, "dd/MM/yyyy")}`}
                readOnly
                className="inputBox"
                onClick={() => setOpenCalendar((openCalendar) => !openCalendar)}
              />
              <div ref={refOne}>
                {openCalendar && (
                  <DateRange
                    locale={locale}
                    onChange={(item) => setRange([item.selection])}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                    ranges={range}
                    months={2}
                    direction={orientation}
                    className="calendarElement"
                  />
                )}
              </div>
            </div>

            <Button
              className="button-save"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Kaydet
            </Button>
          </Box>
        </LocalizationProvider>
      </Container>
    );
  } else {
    return (
      <Container className="create">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box component="form" onSubmit={handleUser} className={createSitil}>
            <Accordion
              onChange={handleChangeAccordion}
              sx={{ borderRadius: "5px" }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ backgroundColor: "#EE315D", borderRadius: "5px" }}
              >
                <ExpandMoreIcon
                  sx={{ color: "white", width: 60, height: 30 }}
                />
                <Typography
                  variant="h6"
                  display="block"
                  sx={{ color: "white" }}
                >
                  Başkasıyla Birlikte Yeni Görev Eklemek İçin Tıklayınız...
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  className="select-dept"
                  sx={{
                    border: 1,
                    borderColor: "white",
                  }}
                  required={ischecked}
                  select
                  label="Departman Seçiniz "
                  margin="normal"
                  fullWidth
                  name="DepartmentInput"
                  value={selectedDepartment?.value ?? ""}
                  onChange={(e, value) => {
                    let index = value.key.slice(2);
                    HandleSelectDepartment(index);
                  }}
                >
                  {department.map((option, id) => {
                    return (
                      <MenuItem key={id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <Select
                  className="select-user"
                  label=""
                  sx={{
                    border: 1,
                    borderColor: "white",
                    color: "#EE315D",
                  }}
                  required={ischecked}
                  fullWidth
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  name="Username"
                  value={selectedUser}
                  onChange={(e) => {
                    const value =
                      typeof e.target.value === "string"
                        ? e.target.value.split(",")
                        : e.target.value;
                    setselectedUser(value);
                  }}
                  input={<OutlinedInput label="Kullanıcı" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {Users.filter(
                    (el) =>
                      el.Department === selectedDepartment?.value &&
                      el.Username !== UsernameCookie
                  )
                    .map((ele) => ele.Username)
                    .map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={selectedUser.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                </Select>
              </AccordionDetails>
            </Accordion>

            <CssTextField
              disabled
              label={
                UsernameCookie.charAt(0).toUpperCase() + UsernameCookie.slice(1)
              }
              margin="normal"
              name="UsernameInput"
              fullWidth
              value={UsernameInput}
              sx={{ display: "none" }}
            ></CssTextField>
            <br />
            <DatePicker
              disabled
              margin="normal"
              name="GivenDate"
              value={GivenDate}
              onChange={(newvalue) => {
                setGivenDate(newvalue);
              }}
              renderInput={(params) => {
                return (
                  <CssTextField
                    sx={{ display: "none" }}
                    label={GivenDate}
                    margin="normal"
                    fullWidth
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => setOpen(true)}
                  />
                );
              }}
            />
            <br />
            <CssTextField
              label="Görev Başlığı"
              margin="normal"
              name="Title"
              value={Title}
              fullWidth
              required
              onChange={(e) => setTitle(e.target.value)}
            >
              Task Title
            </CssTextField>
            <br />
            <CssTextField
              label="Açıklama"
              margin="normal"
              name="Description"
              value={DescriptionInput}
              fullWidth
              required
              multiline
              maxRows={4}
              minRows={2}
              onChange={(e) => setDescriptionInput(e.target.value)}
            >
              Task Details
            </CssTextField>
            <br />
            <CssTextField
              className="ActionStatus"
              inputProps={{
                className: createSitil.statu,
              }}
              style={{
                borderRadius: "6px",
                backgroundColor: !!(Action === "Takılmış")
                  ? "#EE315D"
                  : Action === "Tamamlandı"
                  ? "#009B35"
                  : Action === "Üzerinde Çalışıyor"
                  ? "#e3711c"
                  : Action === "Yeni Görev"
                  ? "#8363b4"
                  : "#0074ff",
              }}
              id="standard-select-currency"
              select
              label="Durum"
              source={currency}
              onClick={handleChange1}
              //helperText="Please select your Action"
              margin="normal"
              required
              fullWidth
              name="Action"
              value={Action}
              onChange={(e) => setAction(e.target.value)}
            >
              {currencies.map((option, id) => (
                <MenuItem key={id} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}{" "}
            </CssTextField>
            <br />
            <CssTextField
              className="ActionStatus"
              inputProps={{
                className: createSitil.statu,
              }}
              style={{
                color: "white",
                borderRadius: "5px",
                backgroundColor: !!(Status === "Yüksek")
                  ? "#EE315D"
                  : Status === "Orta"
                  ? "#009B35"
                  : "#e3711c",
              }}
              id="standard-select-currency"
              select
              label="Öncelik"
              source={currency2}
              onClick={handleChange2}
              //helperText="Please select your Status"
              margin="normal"
              required
              fullWidth
              name="Status"
              value={Status}
              onChange={(e) => setStatus(e.target.value)}
            >
              {currencies2.map((option, id) => (
                <MenuItem key={id} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}{" "}
            </CssTextField>

            {}
            <br />
            {/* <DatePicker
              open={open2}
              onOpen={() => setOpen2(true)}
              onClose={() => setOpen2(false)}
              label="Görev Başlangıç Tarihi"
              margin="normal"
              name="StartDate"
              value={StartDate}
              onChange={(newvalue) => {
                setStartDate(newvalue);
              }}
              renderInput={(params) => {
                return (
                  <CssTextField
                    className="date-start"
                    margin="normal"
                    fullWidth
                    required
                    {...params}
                    onClick={() => setOpen2(true)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                );
              }}
            />

            <br />
            <DatePicker
              open={open3}
              onOpen={() => setOpen3(false)}
              onClose={() => setOpen3(false)}
              label="Görev Tahmini Bitiş Tarihi"
              margin="normal"
              name="EndDate"
              value={EndDate}
              onChange={(newvalue) => {
                setEndDate(newvalue);
              }}
              renderInput={(params) => {
                return (
                  <CssTextField
                    className="date-end"
                    margin="normal"
                    fullWidth
                    required
                    {...params}
                    onClick={() => setOpen3(true)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                );
              }}
            /> */}

            <div className="calendarWrap">
              <CssTextField
                margin="normal"
                label="Görev Başlangıç Tarihi"
                required
                value={`${format(range[0].startDate, "dd/MM/yyyy")}`}
                readOnly
                className="inputBox"
                onClick={() => setOpenCalendar((openCalendar) => !openCalendar)}
              />

              <CssTextField
                margin="normal"
                label="Görev Tahmini Bitiş Tarihi"
                required
                value={` ${format(range[0].endDate, "dd/MM/yyyy")}`}
                readOnly
                className="inputBox"
                onClick={() => setOpenCalendar((openCalendar) => !openCalendar)}
              />
              <div ref={refOne}>
                {openCalendar && (
                  <DateRange
                    // dateDisplayFormat="yyyy/MM/dd h:mm aa"
                    onChange={(item) => setRange([item.selection])}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                    ranges={range}
                    months={2}
                    direction={orientation}
                    className="calendarElement"
                    locale={locale}
                  />
                )}
              </div>
            </div>
            <Button className="button-save" type="submit" variant="contained">
              Kaydet
            </Button>
          </Box>
        </LocalizationProvider>
      </Container>
    );
  }
};
export default Create;
