import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyD7dGWnB_7u_-Sz6RB6ZGpwm3WNRhJAhJA",
  authDomain: "arasta-supply-chain-management.firebaseapp.com",
  projectId: "arasta-supply-chain-management",
  storageBucket: "arasta-supply-chain-management.appspot.com",
  messagingSenderId: "498148175878",
  appId: "1:498148175878:web:bdecd8c705a76a53639387",
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
