import React, { useState } from "react";
import { createContext } from "react";

export const TaskContext = createContext();

const TaskContextProvider = (props) => {
  //const UserID = Cookies.get('userID')
  const [task, setTask] = useState([]);
  const [UserID, setuserID] = useState(null);
  const [Permission, setPermission] = useState(null);
  const [Department, setDepartment] = useState(null);
  return (
    <TaskContext.Provider
      value={{
        tasks: task,
        setTasks: setTask,
        UserID: UserID,
        Permission: Permission,
        Department: Department,
        setPermission: (perm) => setPermission(perm),
        setuserID: (text) => setuserID(text),
        setDepartment: (text) => setDepartment(text),
      }}
    >
      {props.children}
    </TaskContext.Provider>
  );
};
export default TaskContextProvider;
