import React, { useEffect, useState } from "react";
import { Button, MenuItem } from "@mui/material/";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "js-cookie";

import { useParams, useNavigate } from "react-router-dom";
import DepartmentManager from "../Filters/DepartmentManager";
const permissionUserValue = [
  {
    value: "User",
    label: "User",
  },
  {
    value: "Stajyer",
    label: "Stajyer",
  },
];
const permissionAdminValue = [
  {
    value: "User",
    label: "User",
  },
  {
    value: "Stajyer",
    label: "Stajyer",
  },
  {
    value: "DepartmentManager",
    label: "Department Manager",
  },
  {
    value: "admin",
    label: "Admin",
  },
];

export default function UserEdit(props) {
  let [Users, setUsers] = useState();
  const Permission = Cookies.get("permission");
  const [currency, setCurrency] = React.useState("");

  const { id } = useParams();
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setselectedDepartment] = useState("");

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    navigate("/addUserTab");
  };

  useEffect(() => {
    setOpen(true);
    LoadUser();
    LoadDepartment();
  }, []);
  useEffect(() => {}, [currency, selectedDepartment, setselectedDepartment]);

  const LoadUser = async () => {
    try {
      var axios = require("axios");
      var config = {
        method: "get",
        url:
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + `User/${id}`
            : process.env.react_app_localUrl + `User/${id}`,
      };
      const Result = await axios(config);
      const Users = Result.data;
      const EditUsers = Users.map((el) => ({
        ...el,
        label: el.Username,
        value: el.Username,
        UserID: el._id,
      }));
      setUsers(EditUsers[0]);
      setCurrency(Users[0]?.Permission ?? "");
      setselectedDepartment(Users[0]?.Department ?? "");
    } catch (error) {
      console.log(error);
    }
  };

  const LoadDepartment = async () => {
    try {
      var axios = require("axios");
      var config = {
        method: "get",
        url:
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "departments"
            : process.env.react_app_localUrl + "departments",
        headers: {},
      };
      const Result = await axios(config);
      const Department = Result.data;
      const EditDepartment = Department.map((el) => ({
        ...el,
        label: el.Department,
        value: el.Department,
      }));
      setDepartment(EditDepartment);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdata = async (e) => {
    try {
      if (Permission == "admin") {
        var data = {
          Username: Users.Username,
          Phone: Users.Phone,
          Mail: Users.Mail,
          Address: Users.Address,
          Permission: currency,
          Department: selectedDepartment,
        };
      }
      if (Permission == "DepartmentManager") {
        var data = {
          Username: Users.Username,
          Phone: Users.Phone,
          Mail: Users.Mail,
          Address: Users.Address,
          Permission: currency,
        };
      }

      e.preventDefault();
      var config = {
        method: "post",
        url:
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + `useredit/${id}`
            : process.env.react_app_localUrl + `useredit/${id}`,

        headers: {
          "Content-Type": "application/json",
        },
        data,
      };
      await axios(config);

      navigate("/addUserTab");
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Görev Başarıyla Güncellendi!",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ color: "#EE315D" }}>
          Kullanıcı Bilgilerini Güncelleyebilirsiniz.
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            value={Users?.Username ?? ""}
            size="small"
            label="Kullanıcı Adı"
            margin="normal"
            fullWidth
            name="Username"
            required
            onChange={(e) =>
              setUsers({ ...Users, [e.target.name]: e.target.value })
            }
          />

          <TextField
            value={Users?.Phone ?? ""}
            size="small"
            label="Telefon"
            margin="normal"
            fullWidth
            name="Phone"
            required
            onChange={(e) =>
              setUsers({ ...Users, [e.target.name]: e.target.value })
            }
          />

          <TextField
            value={Users?.Mail ?? ""}
            size="small"
            label="Mail"
            margin="normal"
            fullWidth
            name="Mail"
            required
            onChange={(e) =>
              setUsers({ ...Users, [e.target.name]: e.target.value })
            }
          />

          <TextField
            value={Users?.Address ?? ""}
            size="small"
            label="Adres"
            multiline
            rows={3}
            margin="normal"
            fullWidth
            name="Address"
            required
            onChange={(e) =>
              setUsers({ ...Users, [e.target.name]: e.target.value })
            }
          />

          <TextField
            size="small"
            id="standard-select-currency"
            select
            name="Permission"
            // label={Users ? Users.Permission : ""}
            // source={currency}
            value={currency}
            // onClick={(e) =>
            //   setUsers({ ...Users, [e.target.name]: e.target.value })
            // }
            margin="normal"
            fullWidth
            onChange={(e) => setCurrency(e.target.value)}
          >
            {Permission == "DepartmentManager" &&
              permissionUserValue.map((option, id) => (
                <MenuItem key={id} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            {Permission == "admin" &&
              permissionAdminValue.map((option, id) => (
                <MenuItem key={id} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>

          {Permission == "admin" && (
            <TextField
              size="small"
              select
              margin="normal"
              fullWidth
              name="Department"
              value={selectedDepartment}
              onChange={(e) => setselectedDepartment(e.target.value)}
            >
              {department.map((option, id) => {
                return (
                  <MenuItem key={id} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>İptal</Button>
          <Button onClick={handleUpdata}>Güncelle</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
