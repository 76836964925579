import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Typography, Button, TextField } from "@mui/material";
import { Paper } from "@mui/material/";
import "./Login.css";
import Swal from "sweetalert2";

const ResetPw = () => {
  let { tokenid } = useParams();
  const [checkTokenID, setCheckTokenID] = useState(false);
  const [gidecekData, setGidecekData] = useState({});
  const [sonucData, setSonucData] = useState({});
  const [userID, setUserID] = useState({});

  const checkServer = async () => {
    const { data } = await axios.post(
      process.env.react_app_productMode === "true"
        ? process.env.react_app_productUrl + `checkPasswordToken`
        : process.env.react_app_localUrl + `checkPasswordToken`,
      { tokenid: tokenid }
    );
    setSonucData(data);
    setCheckTokenID(data.status);
    setUserID(data.UserID);
  };

  const sendUserInfo = async (e) => {
    e.preventDefault();

    if (
      gidecekData?.Password === gidecekData?.ConfirmPassword &&
      gidecekData?.Password !== "" &&
      gidecekData?.ConfirmPassword !== ""
    ) {
      await axios
        .post(
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + `updateTokenPassword`
            : process.env.react_app_localUrl + `updateTokenPassword`,
          { UserID: userID, newPassword: gidecekData.Password }
        )
        .then(({ data }) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Güncelleme Başarılı",
            showConfirmButton: false,
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Şifreler Eşit Değil!",
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
    checkServer();
  }, []);

  return (
    <>
      {checkTokenID ? (
        <div>
          <form onSubmit={(e) => sendUserInfo(e)}>
            <Paper variant="outlined" square elevation={0}>
              <Container className="container">
                <div className="arasta-logo">
                  <Typography
                    variant="button"
                    display="block"
                    color={"CaptionText"}
                    gutterBottom
                    style={{ fontWeight: 700, textAlign: "center" }}
                  >
                    <br />
                    Görev Takip Sistemi Şifre Değiştirme
                  </Typography>
                </div>

                <TextField
                  required
                  fullWidth
                  size="small"
                  helperText="En az 4 karakterlik şifrenizi belirleyiniz! "
                  name="password"
                  type="password"
                  autoFocus
                  label="Yeni Şifreyi Giriniz."
                  margin="normal"
                  inputProps={{ minLength: 4 }}
                  onChange={(e) =>
                    setGidecekData({
                      ...gidecekData,
                      Password: e.target.value,
                    })
                  }
                />
                <br />
                <TextField
                  required
                  fullWidth
                  size="small"
                  helperText="İlk girdiğiniz parola ile aynı olmasına dikkat ediniz. "
                  name="confirmPassword"
                  type="password"
                  label="Yeni Şifreyi Tekrar Giriniz."
                  margin="normal"
                  inputProps={{ minLength: 4 }}
                  onChange={(e) =>
                    setGidecekData({
                      ...gidecekData,
                      ConfirmPassword: e.target.value,
                    })
                  }
                />
                <br />
                <Button
                  className="button-login"
                  variant="contained"
                  color="secondary"
                  type="submit"
                  size="large"
                  sx={{ mt: 5, mb: 5, backgroundColor: "#EE315D" }}
                >
                  Onayla
                </Button>
              </Container>
            </Paper>
          </form>
        </div>
      ) : (
        <div>{sonucData.msg}</div>
      )}
    </>
  );
};

export default ResetPw;
