import React from "react";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";

import { useEffect, useState } from "react";
import {
  Box,
  Select,
  FormControlLabel,
  FormLabel,
  Button,
  Checkbox,
  FormGroup,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  DialogActions,
  DialogContent,
  Stack,
  DialogTitle,
  useMediaQuery,
  Divider,
  Container,
  Grid,
  MenuItem,
  Dialog,
  Card,
  Chip,
  IconButton,
  InputAdornment,
  InputLabel,
  Slide,
  Paper,
  Typography,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css fi
import { DateRange } from "react-date-range";
import locale from "date-fns/locale/tr";
import moment from "moment";
import { useTheme, styled, alpha } from "@mui/material/styles";
import { json, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

import VisibilityIcon from "@mui/icons-material/Visibility";
import clsx from "clsx";
import Cookies from "js-cookie";
import {
  trTR,
  DataGrid,
  gridClasses,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridCsvExportMenuItem,
} from "@mui/x-data-grid";
import { lightBlue } from "@mui/material/colors";
import Swal from "sweetalert2";
import "./Filter.css";
import "moment/locale/tr";

const csvOptions = { delimiter: ";", utf8WithBom: true };

const CustomExportButton = (props) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = (props) => (
  <GridToolbarContainer {...props}>
    <CustomExportButton
      sx={{
        justifyContent: "start",
        itemAlign: "center",
      }}
    />
    <GridToolbarColumnsButton
      sx={{
        justifyContent: "start",
        itemAlign: "center",

        ml: 1,
      }}
    />
    <GridToolbarFilterButton sx={{ display: "none" }} />
    <GridToolbarDensitySelector
      sx={{
        justifyContent: "start",
        itemAlign: "center",

        ml: 1,
      }}
    />
    <GridToolbarExport sx={{ display: "none" }} />
  </GridToolbarContainer>
);
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
const currencies = [
  {
    value: "Yeni Görev",
    label: "Yeni Görev",
  },
  {
    value: "Üzerinde Çalışıyor",
    label: "Üzerinde Çalışıyor",
  },
  {
    value: "Takılmış",
    label: "Takılmış",
  },
  {
    value: "Tamamlandı",
    label: "Tamamlandı",
  },
  {
    value: "Beklemede",
    label: "Beklemede",
  },
];
const currencies2 = [
  {
    value: "Yüksek",
    label: "Yüksek",
  },
  {
    value: "Orta",
    label: "Orta",
  },
  {
    value: "Düşük",
    label: "Düşük",
  },
];

const ODD_OPACITY = 0.2;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },

  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "red",
      },
    },
    "&.Mui-selected": {
      backgroundColor: "red",
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "red",
        },
      },
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

export default function DepartmentManager({ data, setdata }) {
  const [filters, setFilters] = useState(
    JSON.parse(localStorage.getItem("guncel"))
  );
  const [userLocalFilter, setUserLocalFilter] = useState(
    JSON.parse(localStorage.getItem("selectedUserFilter"))
  );
  const [userLocalDate, setUserLocalDate] = useState(
    JSON.parse(localStorage.getItem("selectedUserFilterDate"))
  );
  const permission = Cookies.get("permission");

  const userID = Cookies.get("userID");
  const departments = Cookies.get("departments");
  const [checked, setChecked] = React.useState(false);

  const [pageSize, setPageSize] = useState(15);

  const theme = useTheme();

  const fullScreenFilter = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreenUser = useMediaQuery(theme.breakpoints.down("xs"));

  const [startDate, setStartDate] = useState(
    filters
      ? new Date(
          filters?.GivenDate
            ? moment(new Date(Number(filters?.GivenDate.$gte) * 1000)).format(
                "YYYY.MM.DD HH:mm:ss"
              )
            : filters?.StartDate
            ? moment(new Date(Number(filters?.StartDate.$gte) * 1000)).format(
                "YYYY.MM.DD HH:mm:ss"
              )
            : filters?.EndDate
            ? moment(new Date(Number(filters?.EndDate.$gte) * 1000)).format(
                "YYYY.MM.DD HH:mm:ss"
              )
            : new Date()
        )
      : userLocalFilter
      ? new Date(
          userLocalDate?.GivenDate
            ? moment(
                new Date(Number(userLocalDate?.GivenDate.$gte) * 1000)
              ).format("YYYY.MM.DD HH:mm:ss")
            : userLocalDate?.StartDate
            ? moment(
                new Date(Number(userLocalDate?.StartDate.$gte) * 1000)
              ).format("YYYY.MM.DD HH:mm:ss")
            : userLocalDate?.EndDate
            ? moment(
                new Date(Number(userLocalDate?.EndDate.$gte) * 1000)
              ).format("YYYY.MM.DD HH:mm:ss")
            : new Date()
        )
      : new Date()
  );

  const [endDate, setEndDate] = useState(
    filters
      ? new Date(
          filters?.GivenDate
            ? moment(new Date(Number(filters?.GivenDate.$lte) * 1000)).format(
                "YYYY.MM.DD HH:mm:ss"
              )
            : filters?.StartDate
            ? moment(new Date(Number(filters?.StartDate.$lte) * 1000)).format(
                "YYYY.MM.DD HH:mm:ss"
              )
            : filters?.EndDate
            ? moment(new Date(Number(filters?.EndDate.$lte) * 1000)).format(
                "YYYY.MM.DD HH:mm:ss"
              )
            : new Date()
        )
      : userLocalFilter
      ? new Date(
          userLocalDate?.GivenDate
            ? moment(
                new Date(Number(userLocalDate?.GivenDate.$lte) * 1000)
              ).format("YYYY.MM.DD HH:mm:ss")
            : userLocalDate?.StartDate
            ? moment(
                new Date(Number(userLocalDate?.StartDate.$lte) * 1000)
              ).format("YYYY.MM.DD HH:mm:ss")
            : userLocalDate?.EndDate
            ? moment(
                new Date(Number(userLocalDate?.EndDate.$lte) * 1000)
              ).format("YYYY.MM.DD HH:mm:ss")
            : new Date()
        )
      : new Date()
  );

  const [value, setValue] = React.useState("");
  const [dates, setDates] = React.useState();

  const [ischecked2, setIsChecked2] = useState(
    userLocalFilter?.ischecked2 === true ||
      userLocalFilter?.GivenDate ||
      userLocalFilter?.StartDate ||
      userLocalFilter?.EndDate ||
      filters?.GivenDate ||
      filters?.StartDate ||
      filters?.EndDate
      ? true
      : false
  );

  const [ischecked3, setIsChecked3] = useState(
    filters?.["Users.UserID"] ? true : false
  );
  const [ischeckedFilter, setCheckedFilter] = useState(false);

  const [selectedRadio1, setSelectedRadio1] = useState(
    filters?.GivenDate || userLocalFilter?.selectedRadio == "1" ? "1" : false
  );
  const [selectedRadio2, setSelectedRadio2] = useState(
    filters?.StartDate || userLocalFilter?.selectedRadio == "2" ? "2" : false
  );
  const [selectedRadio3, setSelectedRadio3] = useState(
    filters?.EndDate || userLocalFilter?.selectedRadio == "3" ? "3" : false
  );

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("Choose wisely");
  const [selectionModel, setSelectionModel] = useState([]);
  const navigate = useNavigate();

  const [selectedUser, setselectedUser] = useState(
    JSON.parse(localStorage.getItem("selectedUserLocal")) || []
  );
  const [addSelectUser, setAddSelectUser] = useState("");

  let [department, setDepartments] = useState([]);
  let [users, setUsers] = useState([]);
  const [ischecked, setIsChecked] = useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [clearAktif, setClearAktif] = useState(false);

  // const [filters, setFilters] = useState({});

  const handleChangeCheckBoxFilter = () => {
    if (!ischeckedFilter == false) {
      const filterDay = {
        Department: departments,
        Permission: permission,
        UserID: userID,
        number: 1,
      };
      setCheckedFilter(!ischeckedFilter);
      setdata(data);

      axios

        .post(
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "tasks"
            : process.env.react_app_localUrl + "tasks",
          filterDay
        )
        .then(({ data }) => {
          const HelperArray = data?.map((el) => ({ ...el, id: el._id }));
          setdata(HelperArray);
        });
    } else {
      const filterDay = {
        Department: departments,
        Permission: permission,
        UserID: userID,
        number: 3,
      };
      setCheckedFilter(!ischeckedFilter);

      axios

        .post(
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "tasks"
            : process.env.react_app_localUrl + "tasks",
          filterDay
        )
        .then(({ data }) => {
          const HelperArray = data?.map((el) => ({ ...el, id: el._id }));
          setdata(HelperArray);
        });
    }
  };

  const handleClickOpen2 = () => {
    if (selectionModel?.length === 0) {
      setOpen2(false);
      Swal.fire({
        title: "HATA!",
        text: "Kullanıcı Atanacak Görevi Seçmediniz!",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      setOpen2(true);
    }
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleChangeCheckBox = () => {
    setIsChecked(!ischecked);
  };
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
    value: "",
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeCheckBox2 = () => {
    setIsChecked2(!ischecked2);

    localStorage.setItem(
      "selectedUserFilter",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("selectedUserFilter")),
        ischecked2: !ischecked2,
      })
    );
  };
  const handleChangeCheckBox3 = () => {
    setIsChecked3(!ischecked3);
  };
  const handleChangeRadio = (event) => {
    setSelectedRadio1(event.target.value);
    setSelectedRadio2(event.target.value);
    setSelectedRadio3(event.target.value);

    localStorage.setItem(
      "selectedUserFilter",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("selectedUserFilter")),
        selectedRadio: event.target.value,
      })
    );

    setHelperText(" ");
    setError(false);
  };

  const LoadUser = async () => {
    try {
      var axios = require("axios");
      var config = {
        method: "get",

        url:
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + `Users`
            : process.env.react_app_localUrl + `Users`,
        headers: {},
      };
      const Result = await axios(config);
      const Users = Result.data;
      const EditUsers = Users.map((el) => ({
        ...el,
        label: el.Username,
        value: el.Username,
      }));
      setUsers(EditUsers);
    } catch (error) {
      console.log(error);
    }
  };

  const LoadDepartment = async () => {
    try {
      var axios = require("axios");
      var config = {
        method: "get",

        url:
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + `departments`
            : process.env.react_app_localUrl + `departments`,
        headers: {},
      };
      const Result = await axios(config);
      const Department = Result.data;
      const EditDepartment = Department.map((el) => ({
        ...el,
        label: el.Department,
        value: el.Department,
      }));
      setDepartments(EditDepartment);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getDefaultData =
      JSON.parse(localStorage.getItem("ColumnDepartmentManager")) || columns;
    setColumns(getDefaultData);
  }, []);

  useEffect(() => {
    LoadUser();
    LoadDepartment();
    // console.log(filters);
  }, []);

  // useEffect(() => {
  //   localStorage.setItem("filters", JSON.stringify(filters));
  // }, [filters]);

  function handleSelect(ranges) {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    // localStorage.setItem(
    //   "selectedUserFilter",
    //   JSON.stringify({
    //     ...JSON.parse(localStorage.getItem("selectedUserFilter")),
    //     startDate: ranges.selection.startDate,
    //     endDate: ranges.selection.endDate,
    //   })
    // );
  }

  moment.locale("tr", locale);
  const handleClickOpen = () => {
    // HandleClear();
    setOpen(true);
  };

  const onSearch = async (e) => {
    let count = 0;
    let _data = [];

    const localUser = JSON.parse(localStorage.getItem("selectedUserLocal"));
    // console.log(localUser);

    try {
      if (localUser?.length == 0 || localUser == null) {
        if (ischecked2 === true) {
          //kendime değil tarih bazlı

          if (selectedRadio1 === "1") {
            //kendime değil tarih bazlı tarih çeşidi ile
            var data = JSON.stringify({
              "Users.Department": departments,
              Action: filters?.Action || userLocalFilter?.Action,
              Status: filters?.Status || userLocalFilter?.Status,
              GivenDate: {
                $gte: `${
                  new Date(
                    moment(startDate).format("YYYY-MM-DD 00:00:00Z")
                  ).getTime() / 1000
                }`,
                $lte: `${
                  new Date(
                    moment(endDate).format("YYYY-MM-DD 23:59:59Z")
                  ).getTime() / 1000
                }`,
              },
            });

            e.preventDefault();
            localStorage.setItem("guncel", data);
            var config = {
              method: "post",

              url:
                process.env.react_app_productMode === "true"
                  ? process.env.react_app_productUrl + `task/filter/`
                  : process.env.react_app_localUrl + `task/filter/`,
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            // console.log("1" + config);
            // console.log("1" + data);

            axios(config).then(function (response) {
              // console.log(JSON.stringify(response.data));
              if (response.data?.msg) {
                Swal.fire({
                  title: "HATA!",
                  text: response.data.msg,
                  icon: "error",
                  confirmButtonText: "OK",
                });
                setOpen(false);
              } else {
                const EditData = response.data.map((el) => ({
                  ...el,
                  id: el._id,
                }));
                // setdata(EditData);
                if (selectedUser.length > 1) {
                  setdata(JSON.parse(localStorage.getItem("selectedUserData")));
                } else {
                  setdata(EditData);
                }
                setOpen(false);
                setHelperText(" ");
                setError(false);
              }
            });
          } else if (selectedRadio2 === "2") {
            //kendime değil tarih bazlı tarih çeşidi ile
            var data = JSON.stringify({
              "Users.Department": departments,

              Action: filters?.Action || userLocalFilter?.Action,
              Status: filters?.Status || userLocalFilter?.Status,
              StartDate: {
                $gte: `${
                  new Date(
                    moment(startDate).format("YYYY-MM-DD 00:00:00Z")
                  ).getTime() / 1000
                }`,
                $lte: `${
                  new Date(
                    moment(endDate).format("YYYY-MM-DD 23:59:59Z")
                  ).getTime() / 1000
                }`,
              },
            });

            e.preventDefault();
            localStorage.setItem("guncel", data);
            var config = {
              method: "post",

              url:
                process.env.react_app_productMode === "true"
                  ? process.env.react_app_productUrl + `task/filter`
                  : process.env.react_app_localUrl + `task/filter`,
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            // console.log("3" + config);
            // console.log("3" + data);

            axios(config).then(function (response) {
              // console.log(JSON.stringify(response.data));
              if (response.data?.msg) {
                Swal.fire({
                  title: "HATA!",
                  text: response.data.msg,
                  icon: "error",
                  confirmButtonText: "OK",
                });
                setOpen(false);
              } else {
                const EditData = response.data.map((el) => ({
                  ...el,
                  id: el._id,
                }));
                setdata(EditData);
                setOpen(false);
                setHelperText(" ");
                setError(false);
              }
            });
          } else if (selectedRadio3 === "3") {
            //kendime değil tarih bazlı tarih çeşidi ile
            var data = JSON.stringify({
              "Users.Department": departments,

              Action: filters?.Action || userLocalFilter?.Action,
              Status: filters?.Status || userLocalFilter?.Status,
              EndDate: {
                $gte: `${
                  new Date(
                    moment(startDate).format("YYYY-MM-DD 00:00:00Z")
                  ).getTime() / 1000
                }`,
                $lte: `${
                  new Date(
                    moment(endDate).format("YYYY-MM-DD 23:59:59Z")
                  ).getTime() / 1000
                }`,
              },
            });

            e.preventDefault();
            localStorage.setItem("guncel", data);
            var config = {
              method: "post",

              url:
                process.env.react_app_productMode === "true"
                  ? process.env.react_app_productUrl + `task/filter/`
                  : process.env.react_app_localUrl + `task/filter/`,
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            // console.log("3" + config);
            // console.log("3" + data);

            axios(config).then(function (response) {
              // console.log(JSON.stringify(response.data));
              if (response.data?.msg) {
                Swal.fire({
                  title: "HATA!",
                  text: response.data.msg,
                  icon: "error",
                  confirmButtonText: "OK",
                });
                setOpen(false);
              } else {
                const EditData = response.data.map((el) => ({
                  ...el,
                  id: el._id,
                }));
                // setdata(EditData);
                if (selectedUser.length > 1) {
                  setdata(JSON.parse(localStorage.getItem("selectedUserData")));
                } else {
                  setdata(EditData);
                }
                setOpen(false);
                setHelperText(" ");
                setError(false);
              }
            });
          } else {
            //kendime değil tarih bazlı tarih çeşidi yoksa
            setHelperText("Lütfen Tarih Çeşidi Seçiniz");
            setError(true);
          }
        } else {
          //Kendime Değil Tarih Bazlı da Değil
          if (clearAktif) {
            localStorage.removeItem("guncel");
            setTimeout(() => {
              window.location.reload();
            }, 200);
          } else {
            e.preventDefault();

            localStorage?.removeItem("selectedUserFilter");
            localStorage?.removeItem("selectedUserFilterDate");

            localStorage?.removeItem("selectedUserData");
            localStorage?.removeItem("selectedUserLocal");

            var data = JSON.stringify({
              "Users.Department": departments,
              Action: filters?.Action,
              Status: filters?.Status,
            });
            // console.log(data);
            localStorage.setItem("guncel", data);

            var config = {
              method: "post",

              url:
                process.env.react_app_productMode === "true"
                  ? process.env.react_app_productUrl + `task/filter`
                  : process.env.react_app_localUrl + `task/filter`,

              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };
            // console.log(data);
            // console.log("4" + config);
            axios(config).then(function (response) {
              // console.log(JSON.stringify(response.data));
              if (response.data?.msg) {
                Swal.fire({
                  title: "HATA!",
                  text: response.data.msg,
                  icon: "error",
                  confirmButtonText: "OK",
                });
                setOpen(false);
              } else {
                const EditData = response.data.map((el) => ({
                  ...el,
                  id: el._id,
                }));
                if (selectedUser.length > 1) {
                  setdata(JSON.parse(localStorage.getItem("selectedUserData")));
                } else {
                  setdata(EditData);
                }
                setOpen(false);
                setHelperText(" ");
                setError(false);
              }
            });
          }
        }
      }

      if (ischecked3 === true) {
        //kendime
        localStorage.removeItem("selectedUserFilterDate");
        localStorage.removeItem("selectedUserData");
        localStorage.removeItem("selectedDepartment");
        localStorage.removeItem("selectedUserLocal");
        localStorage.removeItem("selectedUserFilter");

        if (clearAktif) {
          localStorage.removeItem("guncel");
          setTimeout(() => {
            window.location.reload();
          }, 200);
        } else {
          if (ischecked2 === true) {
            //kendime tarih bazlı
            if (selectedRadio1 === "1") {
              var data = JSON.stringify({
                "Users.Department": departments,
                "Users.UserID": userID,
                Action: filters?.Action,
                Status: filters?.Status,
                GivenDate: {
                  $gte: `${
                    new Date(
                      moment(startDate).format("YYYY-MM-DD 00:00:00Z")
                    ).getTime() / 1000
                  }`,
                  $lte: `${
                    new Date(
                      moment(endDate).format("YYYY-MM-DD 23:59:59Z")
                    ).getTime() / 1000
                  }`,
                },
              });

              localStorage.setItem("guncel", data);

              e.preventDefault();

              var config = {
                method: "post",

                url:
                  process.env.react_app_productMode === "true"
                    ? process.env.react_app_productUrl + `task/filter/`
                    : process.env.react_app_localUrl + `task/filter/`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios(config).then(function (response) {
                // console.log(JSON.stringify(response.data));
                if (response.data?.msg) {
                  Swal.fire({
                    title: "HATA!",
                    text: response.data.msg,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                  setOpen(true);
                } else {
                  const EditData = response.data.map((el) => ({
                    ...el,
                    id: el._id,
                  }));
                  setdata(EditData);
                  setOpen(false);
                  setHelperText(" ");
                  setError(false);
                }
              });
            } else if (selectedRadio2 === "2") {
              var data = JSON.stringify({
                "Users.Department": departments,
                "Users.UserID": userID,
                Action: filters?.Action,
                Status: filters?.Status,
                StartDate: {
                  $gte: `${
                    new Date(
                      moment(startDate).format("YYYY-MM-DD 00:00:00Z")
                    ).getTime() / 1000
                  }`,
                  $lte: `${
                    new Date(
                      moment(endDate).format("YYYY-MM-DD 23:59:59Z")
                    ).getTime() / 1000
                  }`,
                },
              });

              e.preventDefault();

              localStorage.setItem("guncel", data);

              var config = {
                method: "post",

                url:
                  process.env.react_app_productMode === "true"
                    ? process.env.react_app_productUrl + `task/filter/`
                    : process.env.react_app_localUrl + `task/filter/`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };
              // console.log("1" + config);
              // console.log("1" + data);

              axios(config).then(function (response) {
                // console.log(JSON.stringify(response.data));
                if (response.data?.msg) {
                  Swal.fire({
                    title: "HATA!",
                    text: response.data.msg,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                  setOpen(true);
                } else {
                  const EditData = response.data.map((el) => ({
                    ...el,
                    id: el._id,
                  }));
                  setdata(EditData);
                  setOpen(false);
                  setHelperText(" ");
                  setError(false);
                }
              });
            } else if (selectedRadio3 === "3") {
              var data = JSON.stringify({
                "Users.Department": departments,
                "Users.UserID": userID,
                Action: filters?.Action,
                Status: filters?.Status,
                EndDate: {
                  $gte: `${
                    new Date(
                      moment(startDate).format("YYYY-MM-DD 00:00:00Z")
                    ).getTime() / 1000
                  }`,
                  $lte: `${
                    new Date(
                      moment(endDate).format("YYYY-MM-DD 23:59:59Z")
                    ).getTime() / 1000
                  }`,
                },
              });

              e.preventDefault();
              localStorage.setItem("guncel", data);

              var config = {
                method: "post",

                url:
                  process.env.react_app_productMode === "true"
                    ? process.env.react_app_productUrl + `task/filter/`
                    : process.env.react_app_localUrl + `task/filter/`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };
              // console.log("1" + config);
              // console.log("1" + data);

              axios(config).then(function (response) {
                // console.log(JSON.stringify(response.data));
                if (response.data?.msg) {
                  Swal.fire({
                    title: "HATA!",
                    text: response.data.msg,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                  setOpen(true);
                } else {
                  const EditData = response.data.map((el) => ({
                    ...el,
                    id: el._id,
                  }));
                  setdata(EditData);
                  setOpen(false);
                  setHelperText(" ");
                  setError(false);
                }
              });
            } else {
              //kendime tarih bazlı tarih çeşidi yoksa
              setHelperText("Lütfen Tarih Çeşidi Seçiniz");
              setError(true);
            }
          } else {
            //kendime tarih bazlı değilse
            var data = JSON.stringify({
              "Users.Permission": permission,
              "Users.UserID": userID,
              "Users.Department": departments,
              Action: filters?.Action,
              Status: filters?.Status,
            });

            e.preventDefault();

            localStorage.setItem("guncel", data);
            var config = {
              method: "post",

              url:
                process.env.react_app_productMode === "true"
                  ? process.env.react_app_productUrl + `task/filter`
                  : process.env.react_app_localUrl + `task/filter`,
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };
            // console.log("2" + config);
            axios(config).then(function (response) {
              // console.log(JSON.stringify(response.data));
              if (response.data?.msg) {
                Swal.fire({
                  title: "HATA!",
                  text: response.data.msg,
                  icon: "error",
                  confirmButtonText: "OK",
                });
                setOpen(false);
              } else {
                const EditData = response.data.map((el) => ({
                  ...el,
                  id: el._id,
                }));
                setdata(EditData);
                setOpen(false);
                setHelperText(" ");
                setError(false);
              }
            });
          }
        }
      } else {
        //kendime değilse
        const HelperArray = [];

        if (
          selectedUser.length !== 0 ||
          JSON.parse(localStorage.getItem("selectedUserLocal"))
        ) {
          localStorage.removeItem("guncel");
          // console.log("kullanıcı var");
          selectedUser.forEach((el) => {
            const currentUser = users.find((ele) => el === ele.Username);
            HelperArray.push(currentUser);

            const selectedUserData = {
              "Users.Department": departments,
              "Users.Username": currentUser?.Username,
              Action: filters?.Action || userLocalFilter?.Action,
              Status: filters?.Status || userLocalFilter?.Status,
            };
            if (ischecked2 === true) {
              //kendime değil tarih bazlı

              if (selectedRadio1 === "1") {
                //kendime değil tarih bazlı tarih çeşidi ile
                selectedUserData.GivenDate = {
                  $gte: `${
                    new Date(
                      moment(startDate || userLocalDate.startDate).format(
                        "YYYY-MM-DD 00:00:00Z"
                      )
                    ).getTime() / 1000
                  }`,
                  $lte: `${
                    new Date(
                      moment(endDate || userLocalDate.endDate).format(
                        "YYYY-MM-DD 23:59:59Z"
                      )
                    ).getTime() / 1000
                  }`,
                };

                e.preventDefault();
                localStorage.setItem(
                  "selectedUserFilterDate",
                  JSON.stringify({ GivenDate: selectedUserData.GivenDate })
                );

                var config = {
                  method: "post",

                  url:
                    process.env.react_app_productMode === "true"
                      ? process.env.react_app_productUrl + `task/filter/`
                      : process.env.react_app_localUrl + `task/filter/`,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: selectedUserData,
                };

                console.log("multiuser radio buttton 1");

                axios(config).then(function (response) {
                  // console.log(JSON.stringify(response.data));
                  if (response.data?.msg) {
                    Swal.fire({
                      title: "HATA!",
                      text: response.data.msg,
                      icon: "error",
                      confirmButtonText: "OK",
                    });
                    setOpen(false);
                  } else {
                    ++count;
                    _data = _data.concat(
                      response.data.map((el) => ({
                        ...el,
                        id: el._id,
                      }))
                    );

                    if (count == selectedUser.length) {
                      const filterData = _data.filter(
                        (a, i) => _data.findIndex((s) => a._id === s._id) === i
                      );

                      localStorage.setItem(
                        "selectedUserData",
                        JSON.stringify(filterData)
                      );

                      setdata(
                        JSON.parse(localStorage.getItem("selectedUserData"))
                      );

                      setOpen(false);
                      setHelperText(" ");
                      setError(false);
                    }
                  }
                });
              } else if (selectedRadio1 === "2") {
                //kendime değil tarih bazlı tarih çeşidi ile
                selectedUserData.StartDate = {
                  $gte: `${
                    new Date(
                      moment(startDate).format("YYYY-MM-DD 00:00:00Z")
                    ).getTime() / 1000
                  }`,
                  $lte: `${
                    new Date(
                      moment(endDate).format("YYYY-MM-DD 23:59:59Z")
                    ).getTime() / 1000
                  }`,
                };
                e.preventDefault();

                localStorage.setItem(
                  "selectedUserFilterDate",
                  JSON.stringify({ StartDate: selectedUserData.StartDate })
                );

                var config = {
                  method: "post",

                  url:
                    process.env.react_app_productMode === "true"
                      ? process.env.react_app_productUrl + `task/filter/`
                      : process.env.react_app_localUrl + `task/filter/`,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: selectedUserData,
                };

                axios(config).then(function (response) {
                  // console.log(JSON.stringify(response.data));
                  if (response.data?.msg) {
                    Swal.fire({
                      title: "HATA!",
                      text: response.data.msg,
                      icon: "error",
                      confirmButtonText: "OK",
                    });
                    setOpen(false);
                  } else {
                    ++count;
                    _data = _data.concat(
                      response.data.map((el) => ({
                        ...el,
                        id: el._id,
                      }))
                    );

                    if (count == selectedUser.length) {
                      const filterData = _data.filter(
                        (a, i) => _data.findIndex((s) => a._id === s._id) === i
                      );

                      localStorage.setItem(
                        "selectedUserData",
                        JSON.stringify(filterData)
                      );

                      setdata(
                        JSON.parse(localStorage.getItem("selectedUserData"))
                      );

                      setOpen(false);
                      setHelperText(" ");
                      setError(false);
                    }
                  }
                });
              } else if (selectedRadio1 === "3") {
                //kendime değil tarih bazlı tarih çeşidi ile
                selectedUserData.EndDate = {
                  $gte: `${
                    new Date(
                      moment(startDate).format("YYYY-MM-DD 00:00:00Z")
                    ).getTime() / 1000
                  }`,
                  $lte: `${
                    new Date(
                      moment(endDate).format("YYYY-MM-DD 23:59:59Z")
                    ).getTime() / 1000
                  }`,
                };
                e.preventDefault();
                localStorage.setItem(
                  "selectedUserFilterDate",
                  JSON.stringify({ EndDate: selectedUserData.EndDate })
                );

                var config = {
                  method: "post",

                  url:
                    process.env.react_app_productMode === "true"
                      ? process.env.react_app_productUrl + `task/filter/`
                      : process.env.react_app_localUrl + `task/filter/`,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: selectedUserData,
                };

                axios(config).then(function (response) {
                  // console.log(JSON.stringify(response.data));
                  if (response.data?.msg) {
                    Swal.fire({
                      title: "HATA!",
                      text: response.data.msg,
                      icon: "error",
                      confirmButtonText: "OK",
                    });
                    setOpen(false);
                  } else {
                    ++count;
                    _data = _data.concat(
                      response.data.map((el) => ({
                        ...el,
                        id: el._id,
                      }))
                    );

                    if (count == selectedUser.length) {
                      const filterData = _data.filter(
                        (a, i) => _data.findIndex((s) => a._id === s._id) === i
                      );

                      localStorage.setItem(
                        "selectedUserData",
                        JSON.stringify(filterData)
                      );

                      setdata(
                        JSON.parse(localStorage.getItem("selectedUserData"))
                      );

                      setOpen(false);
                      setHelperText(" ");
                      setError(false);
                    }
                  }
                });
              } else {
                //kendime değil tarih bazlı tarih çeşidi yoksa
                setHelperText("Lütfen Tarih Çeşidi Seçiniz");
                setError(true);
              }
            } else {
              //Kendime Değil Tarih Bazlı da Değil

              //   e.preventDefault();
              //   localStorage.setItem("guncel", JSON.stringify(selectedUserData));

              var config = {
                method: "post",

                url:
                  process.env.react_app_productMode === "true"
                    ? process.env.react_app_productUrl + `task/filter`
                    : process.env.react_app_localUrl + `task/filter`,

                headers: {
                  "Content-Type": "application/json",
                },
                data: selectedUserData,
              };
              // console.log(data);
              // console.log("4" + config);
              axios(config).then(function (response) {
                // console.log(JSON.stringify(response.data));
                if (response.data?.msg) {
                  Swal.fire({
                    title: "HATA!",
                    text: response.data.msg,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                  setOpen(false);
                } else {
                  ++count;
                  _data = _data.concat(
                    response.data.map((el) => ({
                      ...el,
                      id: el._id,
                    }))
                  );
                  if (count == selectedUser.length) {
                    const filterData = _data.filter(
                      (a, i) => _data.findIndex((s) => a._id === s._id) === i
                    );
                    // console.log(filterData);

                    localStorage.setItem(
                      "selectedUserData",
                      JSON.stringify(filterData)
                    );

                    setdata(
                      JSON.parse(localStorage.getItem("selectedUserData"))
                    );

                    // setdata(userData);
                    setOpen(false);
                    setHelperText(" ");
                    setError(false);
                  }
                }
              });
            }
          });
          localStorage.setItem(
            "selectedUserLocal",
            JSON.stringify(selectedUser)
          );
        } else {
          //selected user yok

          if (ischecked2 === true) {
            //kendime değil tarih bazlı

            if (selectedRadio1 === "1") {
              //kendime değil tarih bazlı tarih çeşidi ile
              var data = JSON.stringify({
                "Users.Department": departments,

                Action: filters?.Action || userLocalFilter?.Action,
                Status: filters?.Status || userLocalFilter?.Status,
                GivenDate: {
                  $gte: `${
                    new Date(
                      moment(startDate).format("YYYY-MM-DD 00:00:00Z")
                    ).getTime() / 1000
                  }`,
                  $lte: `${
                    new Date(
                      moment(endDate).format("YYYY-MM-DD 23:59:59Z")
                    ).getTime() / 1000
                  }`,
                },
              });

              e.preventDefault();
              localStorage.setItem("guncel", data);
              var config = {
                method: "post",

                url:
                  process.env.react_app_productMode === "true"
                    ? process.env.react_app_productUrl + `task/filter/`
                    : process.env.react_app_localUrl + `task/filter/`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };

              // console.log("1" + config);
              // console.log("1" + data);

              axios(config).then(function (response) {
                // console.log(JSON.stringify(response.data));
                if (response.data?.msg) {
                  Swal.fire({
                    title: "HATA!",
                    text: response.data.msg,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                  setOpen(false);
                } else {
                  const EditData = response.data.map((el) => ({
                    ...el,
                    id: el._id,
                  }));
                  // setdata(EditData);
                  if (selectedUser.length > 1) {
                    setdata(
                      JSON.parse(localStorage.getItem("selectedUserData"))
                    );
                  } else {
                    setdata(EditData);
                  }
                  setOpen(false);
                  setHelperText(" ");
                  setError(false);
                }
              });
            } else if (selectedRadio2 === "2") {
              //kendime değil tarih bazlı tarih çeşidi ile
              var data = JSON.stringify({
                "Users.Department": departments,

                Action: filters?.Action || userLocalFilter?.Action,
                Status: filters?.Status || userLocalFilter?.Status,
                StartDate: {
                  $gte: `${
                    new Date(
                      moment(startDate).format("YYYY-MM-DD 00:00:00Z")
                    ).getTime() / 1000
                  }`,
                  $lte: `${
                    new Date(
                      moment(endDate).format("YYYY-MM-DD 23:59:59Z")
                    ).getTime() / 1000
                  }`,
                },
              });

              e.preventDefault();
              localStorage.setItem("guncel", data);
              var config = {
                method: "post",

                url:
                  process.env.react_app_productMode === "true"
                    ? process.env.react_app_productUrl + `task/filter`
                    : process.env.react_app_localUrl + `task/filter`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };

              // console.log("3" + config);
              // console.log("3" + data);

              axios(config).then(function (response) {
                // console.log(JSON.stringify(response.data));
                if (response.data?.msg) {
                  Swal.fire({
                    title: "HATA!",
                    text: response.data.msg,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                  setOpen(false);
                } else {
                  const EditData = response.data.map((el) => ({
                    ...el,
                    id: el._id,
                  }));
                  setdata(EditData);
                  setOpen(false);
                  setHelperText(" ");
                  setError(false);
                }
              });
            } else if (selectedRadio3 === "3") {
              //kendime değil tarih bazlı tarih çeşidi ile
              var data = JSON.stringify({
                "Users.Department": departments,
                Action: filters?.Action || userLocalFilter?.Action,
                Status: filters?.Status || userLocalFilter?.Status,
                EndDate: {
                  $gte: `${
                    new Date(
                      moment(startDate).format("YYYY-MM-DD 00:00:00Z")
                    ).getTime() / 1000
                  }`,
                  $lte: `${
                    new Date(
                      moment(endDate).format("YYYY-MM-DD 23:59:59Z")
                    ).getTime() / 1000
                  }`,
                },
              });

              e.preventDefault();
              localStorage.setItem("guncel", data);
              var config = {
                method: "post",

                url:
                  process.env.react_app_productMode === "true"
                    ? process.env.react_app_productUrl + `task/filter/`
                    : process.env.react_app_localUrl + `task/filter/`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };

              // console.log("3" + config);
              // console.log("3" + data);

              axios(config).then(function (response) {
                // console.log(JSON.stringify(response.data));
                if (response.data?.msg) {
                  Swal.fire({
                    title: "HATA!",
                    text: response.data.msg,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                  setOpen(false);
                } else {
                  const EditData = response.data.map((el) => ({
                    ...el,
                    id: el._id,
                  }));
                  // setdata(EditData);
                  if (selectedUser.length > 1) {
                    setdata(
                      JSON.parse(localStorage.getItem("selectedUserData"))
                    );
                  } else {
                    setdata(EditData);
                  }
                  setOpen(false);
                  setHelperText(" ");
                  setError(false);
                }
              });
            } else {
              //kendime değil tarih bazlı tarih çeşidi yoksa
              setHelperText("Lütfen Tarih Çeşidi Seçiniz");
              setError(true);
            }
          } else {
            //Kendime Değil Tarih Bazlı da Değil
            if (clearAktif) {
              localStorage.removeItem("guncel");
              setTimeout(() => {
                window.location.reload();
              }, 200);
            } else {
              e.preventDefault();
              // console.log("son else ");

              var data = JSON.stringify({
                "Users.Department": departments,
                Action: filters?.Action || userLocalFilter?.Action,
                Status: filters?.Status || userLocalFilter?.Status,
              });

              localStorage.setItem("guncel", data);

              var config = {
                method: "post",

                url:
                  process.env.react_app_productMode === "true"
                    ? process.env.react_app_productUrl + `task/filter`
                    : process.env.react_app_localUrl + `task/filter`,

                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };
              // console.log(data);
              // console.log("4" + config);
              axios(config).then(function (response) {
                // console.log(JSON.stringify(response.data));
                if (response.data?.msg) {
                  Swal.fire({
                    title: "HATA!",
                    text: response.data.msg,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                  setOpen(false);
                } else {
                  const EditData = response.data.map((el) => ({
                    ...el,
                    id: el._id,
                  }));
                  if (selectedUser.length > 1) {
                    setdata(
                      JSON.parse(localStorage.getItem("selectedUserData"))
                    );
                  } else {
                    setdata(EditData);
                  }
                  setOpen(false);
                  setHelperText(" ");
                  setError(false);
                }
              });
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      localStorage.removeItem("guncel");
      localStorage.removeItem("selectedUserFilter");
      localStorage.removeItem("selectedUserFilterDate");

      localStorage.removeItem("selectedUserData");
      localStorage.removeItem("selectedUserLocal");
    }
  };

  const RemoveTask = (_id) => {
    setdata((prevRows) => prevRows.filter((row) => row.id !== _id));
    const storedArray = JSON.parse(localStorage.getItem("selectedUserData"));
    if (storedArray) {
      const localFilter = storedArray?.filter((el) => el._id !== _id);
      localStorage.setItem("selectedUserData", JSON.stringify(localFilter));
    }

    try {
      fetch(
        process.env.react_app_productMode === "true"
          ? process.env.react_app_productUrl + `task/delete/` + _id
          : process.env.react_app_localUrl + `task/delete/` + _id,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
        }
      );
    } catch (error) {
      alert("Başarısız oldu ");
    }
  };
  const AlertDelete = (id) => {
    id = id[0]._id;
    Swal.fire({
      title: "Emin Misiniz?",
      text: "Bu görevi silmek üzeresiniz bunu geri alamazsınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "İptal",
      confirmButtonText: "Evet, Sil!",
    }).then((result) => {
      if (result.isConfirmed) {
        RemoveTask(id);
        Swal.fire("Silindi!", "Bu Görev Başarıyla Silindi.", "success");
      }
    });
  };
  const PostEdit = (id) => {
    id = id[0]._id;
    navigate(`update/${id}`);
  };
  const AddNote = (id) => {
    id = id[0]._id;
    navigate(`popup/${id}`);
  };
  const View = (id) => {
    id = id[0]._id;
    navigate(`view/${id}`);
  };

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 5 : 5,
      bottom: params.isLastVisible ? 10 : 5,
    };
  }, []);
  moment.locale("tr");
  const [columns, setColumns] = useState([
    {
      field: "TaskCompleteDate",
      headerName: "Görev Tamamlanma Tarihi",
      minWidth: 160,
      sortable: true,
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        return params.value
          ? moment(new Date(Number(params.value) * 1000)).format(
              "DD.MMMM.YYYY HH:mm"
            )
          : "";
      },
    },
    // {
    //   field: "Department",
    //   headerName: "Departman",
    //   minWidth: 100,
    //   sortable: false,
    //   headerAlign: "center",
    //   flex: 1,
    // },
    {
      field: "Users",
      headerName: "Görev Kullanıcıları",
      sortable: false,
      minWidth: 600,
      flex: 1,
      type: "string",
      valueGetter: (params) => {
        const { Users } = params.row;
        return Users?.map((elem) => elem.Username).join(",");
      },
      renderCell: function (params, index) {
        const user = params?.value;
        return user?.split(",")?.map((Users, index) => (
          <Stack
            type="string"
            width={100}
            spacing={0}
            key={index}
            sx={{
              listStyle: "none",
              display: "-ms-flexbox",

              justifyContent: "left",
              p: 0,
              m: 0,
            }}
          >
            <Chip
              sx={{
                fontSize: 10,
                height: 20,
                color: "#121212",
                borderColor: "#121212",
                ml: 0.4,
              }}
              color="primary"
              variant="outlined"
              label={`${Users}`}
            />
          </Stack>
        ));
      },
    },
    {
      field: "GivenDate",
      headerName: "Veriliş Tarihi",
      minWidth: 160,
      sortable: true,
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        return params.value
          ? moment(new Date(Number(params.value) * 1000)).format(
              "DD.MMMM.YYYY HH:mm"
            )
          : "";
      },
    },
    {
      field: "Title",
      headerName: "Başlık",
      minWidth: 200,
      sortable: true,
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <CustomWidthTooltip
          title={params.value.toString()}
          placement="bottom-start"
        >
          <span className="table-cell-trucate">{params.value.toString()}</span>
        </CustomWidthTooltip>
      ),
    },
    {
      field: "Description",
      headerName: "Açıklama",
      minWidth: 200,
      sortable: false,
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <CustomWidthTooltip title={params.value} placement="bottom-start">
          <span className="table-cell-trucate">{params.value.toString()}</span>
        </CustomWidthTooltip>
      ),
    },
    {
      field: "Action",
      headerName: "Durum",
      minWidth: 150,
      sortable: false,
      headerAlign: "left",
      flex: 1,
      type: "string",
      cellClassName: (params) =>
        clsx("super-app", {
          newtask: params.value === "Yeni Görev",
          manuel: params.value === "Üzerinde Çalışıyor",
          positive: params.value === "Tamamlandı",
          negative: params.value === "Takılmış",
          beklemede: params.value === "Beklemede",
        }),
    },
    {
      field: "Status",
      headerName: "Öncelik",
      minWidth: 120,
      sortable: false,
      headerAlign: "center",
      flex: 1,
      cellClassName: (params) =>
        clsx("super-app", {
          dusuk: params.value === "Düşük",
          positive: params.value === "Orta",
          negative: params.value === "Yüksek",
        }),
    },
    {
      field: "StartDate",
      headerName: "Başlangıç Tarihi",
      minWidth: 160,
      sortable: true,
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        return params.value
          ? moment(new Date(Number(params.value) * 1000)).format(
              "DD.MMMM.YYYY HH:mm"
            )
          : "";
      },
    },
    {
      field: "EndDate",
      headerName: "Bitiş Tarihi",
      minWidth: 160,
      sortable: true,
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        return params.value
          ? moment(new Date(Number(params.value) * 1000)).format(
              "DD.MMMM.YYYY HH:mm"
            )
          : "";
      },
    },
    // {
    //   headerName: "Özellikler",
    //   field: "actions",
    //   type: "actions",
    //   minWidth: 250,
    //   sortable: false,
    //   headerAlign: "center",
    //   //flex: 1,
    //   getActions: (params) => [
    //     <GridActionsCellItem
    //       icon={<DeleteIcon />}
    //       label="Delete"
    //       onClick={() => AlertDelete(params.id)}
    //     />,
    //     <GridActionsCellItem
    //       icon={<EditIcon />}
    //       label="Update"
    //       onClick={() => PostEdit(params.id)}
    //     />,
    //     <GridActionsCellItem
    //       icon={<NoteAddIcon sx={{ color: lightBlue[500] }} />}
    //       label="AddNote"
    //       onClick={() => AddNote(params.id)}
    //     />,
    //     <GridActionsCellItem
    //       icon={<VisibilityIcon />}
    //       label="View"
    //       onClick={() => View(params.id)}
    //     />,
    //   ],
    // },
  ]);
  const handleNewUserClear = () => {
    setselectedUser("");
  };

  const HandleClear = () => {
    setClearAktif(true);
    localStorage?.removeItem("guncel");
    localStorage?.removeItem("selectedUserFilter");
    localStorage?.removeItem("selectedUserFilterDate");

    localStorage?.removeItem("selectedUserData");
    localStorage?.removeItem("selectedUserLocal");

    const HelperFiltersLocal = { ...userLocalFilter };

    delete HelperFiltersLocal.Action;
    delete HelperFiltersLocal.Status;
    setUserLocalFilter(HelperFiltersLocal);

    const HelperFilters = { ...filters };
    delete HelperFilters.Username;
    delete HelperFilters.Action;
    delete HelperFilters.Status;
    setFilters(HelperFilters);

    setselectedUser([]);
    setIsChecked2(false);
    setIsChecked3(false);

    setSelectedRadio1(false);
    setSelectedRadio2(false);
    setSelectedRadio3(false);
  };

  const Update = (id) => {
    id = selectionModel[0]._id;
    let selectedUserId = selectionModel._id;
    if (addSelectUser !== "") {
      users.find((el) => {
        if (el.Username === addSelectUser.Username) {
          selectedUserId = el._id;
        }
      });

      const ModalUpdateData = {
        UserID: selectedUserId,
        Username: addSelectUser.Username,
        Edit: ischecked,
        Department: addSelectUser.Department,
      };
      axios
        .post(
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + `tasks/addNewUser/${id}`
            : process.env.react_app_localUrl + `tasks/addNewUser/${id}`,

          ModalUpdateData
        )
        .then(function (response) {
          if (response.data.status === false) {
            Swal.fire({
              title: "HATA!",
              text: "Bu Kullanıcı Zaten Var !",
              icon: "error",
              confirmButtonText: "OK",
            });
            setOpen2(false);
          } else {
            Swal.fire({
              icon: "success",
              title: "Kullanıcı Başarıyla Eklendi!",
              showConfirmButton: false,
              timer: 1500,
            });

            if (response.data.getUpdated) {
              const _id = id;

              const storedArray = JSON.parse(
                localStorage.getItem("selectedUserData")
              );
              const localFilter = storedArray?.filter((el) => el._id !== _id);
              localStorage.setItem(
                "selectedUserData",
                JSON.stringify(localFilter)
              );

              const parsedArray = JSON.parse(
                localStorage.getItem("selectedUserData")
              );
              const newArray = [...parsedArray, response.data.getUpdated];
              localStorage.setItem(
                "selectedUserData",
                JSON.stringify(newArray)
              );
            }

            setOpen2(false);
            setTimeout(() => {
              window.location.reload();
            }, 200);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      // const arrayLenght = array.length - 1;
      // if (index === arrayLenght) {
      //   handleClose2();
      //   Swal.fire({
      //     icon: "success",
      //     title: "Görev Başarıyla Kaydedildi!",
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
    } else {
      Swal.fire({
        title: "HATA!",
        text: "Gerekli Kullanıcıyı Seçmediniz!",
        icon: "error",
        confirmButtonText: "OK",
      });
      setOpen2(false);
    }
  };
  const orientation = window.matchMedia("(max-width: 700px)").matches
    ? "vertical"
    : "horizontal";

  const handleRowClick = (param, event) => {
    setChecked(true);
  };

  const handleChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
    // const userData = JSON.parse(localStorage.getItem("selectedUserLocal"));

    localStorage.setItem(
      "selectedUserFilter",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("selectedUserFilter")),
        [event.target.name]: event.target.value,
      })
    );
  };

  return (
    <div
      className="department"
      style={{ width: "100%", marginTop: -50 }}
      onClick={() => {
        if (selectionModel === 0) {
          setChecked(false);
        }
      }}
    >
      <div className="buttonMainContainer">
        <Button
          variant="contained"
          onClick={handleClickOpen}
          className="yeni-btn"
        >
          Filtreleme
        </Button>
        {/* <Button
          variant="contained"
          onClick={handleClickOpen2}
          className="yeni-btn"
        >
          Göreve Kullanıcı Ata
        </Button> */}
      </div>
      <Box
        sx={{
          height: "20px",
          "& .super-app.dusuk": {
            padding: "10px 30px 10px 30px",
            borderColor: "white",

            borderRadius: "25px",
            backgroundColor: "#e3711c",
            color: "white",
            fontWeight: "500",
          },
          "& .super-app.manuel": {
            borderColor: "white",
            borderRadius: "25px",
            backgroundColor: "#e3711c",
            color: "white",
            fontWeight: "500",
          },
          "& .super-app.positive": {
            borderColor: "white",
            // backgroundImage: "url(" + completedLogo + ")",
            borderRadius: "25px",
            backgroundColor: "#009B35",
            padding: "10px 30px 10px 30px",
            color: "white",
            fontWeight: "500",
          },
          "& .super-app.negative": {
            borderColor: "white",

            borderRadius: "25px",
            backgroundColor: "#EE315D",
            padding: "10px 30px 10px 30px",
            color: "white",
            fontWeight: "500",
          },
          "& .super-app.beklemede": {
            borderColor: "white",

            borderRadius: "25px",
            backgroundColor: "#0074ff",
            color: "white",
            fontWeight: "500",
            padding: "10px 30px 10px 30px",
          },
          "& .super-app.newtask": {
            borderColor: "white",

            borderRadius: "25px",
            backgroundColor: "#8363b4",
            color: "white",
            fontWeight: "500",
            padding: "10px 30px 10px 30px",
          },
        }}
      >
        <Dialog
          fullScreen={fullScreenUser}
          open={open2}
          onClose={handleClose2}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" color="secondary">
            {"Göreve Yeni Kullanıcı Ekle"}
          </DialogTitle>
          <DialogContent>
            {/* <TextField
                            sx={{ width: 300 }}
                            size="small"
                            select
                            label="Departman"
                            margin="normal"
                            required
                            value={selectedDepartment?.value ?? ""}
                            onChange={(e, value) => {
                                let index = value.key.slice(2)
                                HandleSelectDepartment(index)
                                // setSelectedDepartment(e.target.value)

                            }}>
                            {department.map((option, id) => {
                                return (
                                    <MenuItem key={id} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                )
                            })}
                        </TextField> */}

            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton aria-label="clear" onClick={handleNewUserClear}>
                      <ClearIcon sx={{ width: 20, color: "purple" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              // sx={{ width: 400 }}
              fullWidth
              size="small"
              select
              label="Kullanıcı Seçiniz."
              margin="normal"
              required
              value={addSelectUser?.Username ?? ""}
              onChange={(e, userValue) => {
                let index = userValue.key.slice(2);
                const { value } = e.target;
                setAddSelectUser({ Username: value, Department: departments });
              }}
            >
              {users
                .filter((el) => el.Department === departments)
                .map(({ label, value, _id }) => {
                  return (
                    <MenuItem key={_id} value={value}>
                      {label}
                    </MenuItem>
                  );
                })}
            </TextField>
            <br />
            <br />
            <FormGroup className="FormGroup">
              <FormLabel sx={{ mr: 3, fontSize: 15 }}>
                Görev Üzerinde Yetki Durumu :
              </FormLabel>
              <FormControlLabel
                value="edit"
                control={
                  <Checkbox
                    sx={{ mb: 1.5 }}
                    checked={ischecked}
                    onChange={handleChangeCheckBox}
                  />
                }
                label="Düzenleme Yapabilir mi?"
              />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose2}>
              Vazgeç
            </Button>
            <Button onClick={Update} autoFocus variant="contained">
              Ekle
            </Button>
          </DialogActions>
        </Dialog>
        <Card
          elevation={3}
          className="tasklist-card"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ width: "100%" }}
        >
          {/* <div
            style={{
              display: "flex",
              justifyContent: "between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex" }}>
              <FormGroup sx={{ width: 250 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ mb: 1.5, ml: 2 }}
                      checked={ischeckedFilter}
                      onChange={handleChangeCheckBoxFilter}
                    />
                  }
                  label="Son
                  3  
                  Günü Filtrele"
                />
              </FormGroup>
            </div>
          </div> */}

          <div>
            <div
              style={{
                justifyContent: "right",
                display: "flex",
                flexWrap: "nowrap",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
                <Paper
                  elevation={0}
                  sx={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  {selectionModel[0] && (
                    <div
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        borderRadius: "25px",
                        backgroundColor: "#f5f5f5",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Typography sx={{ ml: 2, mt: 2, mr: 5 }}>
                        {/* Seçilen Görev:{" "} */}
                        <span style={{ fontWeight: "bold", fontSize: "13px" }}>
                          {" "}
                          {selectionModel[0]?.Title}
                        </span>
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "nowrap",
                          justifyContent: "left",
                          alignItems: "center",
                        }}
                      >
                        <br />
                        <Tooltip title="Sil">
                          <IconButton
                            aria-label="delete"
                            onClick={() => AlertDelete(selectionModel)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Düzenle">
                          <IconButton onClick={() => PostEdit(selectionModel)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Not Ekle">
                          <IconButton
                            sx={{ paddingTop: 2 }}
                            onClick={() => AddNote(selectionModel)}
                          >
                            <NoteAddIcon sx={{ color: lightBlue[500] }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Detaylar">
                          <IconButton onClick={() => View(selectionModel)}>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Kullanıcı Ata">
                          <IconButton
                            onClick={() => handleClickOpen2(selectionModel)}
                          >
                            <PersonAddAltIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </Paper>
              </Slide>

              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                }}
              >
                <div>
                  <FormGroup sx={{ width: 220 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ display: "flex", alignItems: "center" }}
                          checked={ischeckedFilter}
                          onChange={handleChangeCheckBoxFilter}
                        />
                      }
                      label="Son 3 Günü Filtrele"
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
          <Grid container className="grid" sx={{ display: "flex" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <StyledDataGrid
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                {...data}
                getRowSpacing={getRowSpacing}
                disableColumnMenu
                // checkboxSelection
                className="datagrid-table"
                autoHeight={true}
                disableExtendRowFullWidth={true}
                rows={data}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[15, 25, 50, 100]}
                pagination
                rowHeight={30}
                selectionModel={selectionModel}
                onSelectionModelChange={(id) => {
                  const selectedIDs = new Set(id);

                  const selectedRows = data.filter((row) =>
                    selectedIDs.has(row.id)
                  );
                  setSelectionModel(selectedRows);
                }}
                onRowClick={handleRowClick}
                CustomToolbar
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{
                  preferencesPanel: {
                    onChange: (event) => {
                      const tempCols = [...columns];

                      tempCols.forEach((col, i) => {
                        if (col.field == event.target.name) {
                          tempCols[i].hide = !tempCols[i].hide;
                        }

                        localStorage.setItem(
                          "ColumnDepartmentManager",
                          JSON.stringify(tempCols)
                        );
                      });
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Container maxWidth="sm" className="filter-pop">
        <Dialog
          fullScreen={fullScreenFilter}
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="responsive-dialog-title" sx={{ mb: -2 }}>
            {"Filtreleme için değerlerinizi seçiniz."}
            <Divider />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ mb: 1, alignItems: "center" }}
                  checked={ischecked3}
                  onChange={handleChangeCheckBox3}
                />
              }
              label="Kendi Görevlerini Filtrele"
            />
          </DialogTitle>
          <br />
          <DialogContent sx={{ mt: -5 }}>
            <Box
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {/* Departman
                            <Select
                                type='text'
                                disabled={ischecked3}
                                size="small"
                                name='Department'
                                sx={{ width: 300, mt: 3, ml: -10 }}
                                value={filters.Department}
                                onChange={handleFilter} >
                                {department.map((option, id) => {
                                    return (
                                        <MenuItem key={id} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    )
                                })}
                            </Select> */}
              <FormControl>
                <InputLabel>Kullanıcı</InputLabel>

                <Select
                  disabled={ischecked3}
                  className="select-user"
                  sx={{
                    border: 1,
                    borderColor: "white",
                    color: "#EE315D",
                    width: 400,
                    ml: 1,
                    height: 40,
                    mt: 1,
                  }}
                  label="Kullanıcı"
                  required={ischecked}
                  fullWidth
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  name="Username"
                  value={
                    JSON.parse(localStorage.getItem("selectedUserLocal"))
                      ?.length > 0
                      ? JSON.parse(localStorage.getItem("selectedUserLocal"))
                      : selectedUser
                  }
                  // value={selectedUser}
                  onChange={(e) => {
                    const value =
                      typeof e.target.value === "string"
                        ? e.target.value.split(",")
                        : e.target.value;
                    localStorage.setItem(
                      "selectedUserLocal",
                      JSON.stringify({
                        ...JSON.parse(
                          localStorage.getItem("selectedUserLocal")
                        ),
                        // User: value,
                      })
                    );
                    // console.log(value);
                    setselectedUser(value);
                  }}
                  input={<OutlinedInput label="Kullanıcı" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {users
                    .filter((el) => el.Department === departments)
                    .map((ele) => ele.Username)
                    .map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={
                            JSON.parse(
                              localStorage.getItem("selectedUserLocal")
                            )?.length > 0
                              ? JSON.parse(
                                  localStorage.getItem("selectedUserLocal")
                                ).indexOf(name) > -1
                              : selectedUser?.indexOf(name) > -1
                          }
                          // checked={selectedUser.indexOf(name) > -1}
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Durum</InputLabel>
                <Select
                  // defaultValue={
                  //   JSON.parse(localStorage.getItem("multiUserFilter"))?.Action
                  // }
                  value={
                    filters?.Action
                      ? filters?.Action
                      : userLocalFilter?.Action
                      ? userLocalFilter?.Action
                      : ""
                  }
                  type="text"
                  label="Durum"
                  size="small"
                  sx={{ width: 200, ml: 1, height: 40, mt: 1 }}
                  name="Action"
                  onChange={handleChange}
                  style={{ ml: 3 }}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Öncelik</InputLabel>
                <Select
                  // defaultValue={
                  //   JSON.parse(localStorage.getItem("multiUserFilter"))?.Status
                  // }
                  value={
                    filters?.Status
                      ? filters?.Status
                      : userLocalFilter?.Status
                      ? userLocalFilter?.Status
                      : ""
                  }
                  type="text"
                  label="Statu"
                  size="small"
                  sx={{ width: 200, ml: 1, height: 40, mt: 1 }}
                  name="Status"
                  // value={filters.Status}
                  onChange={handleChange}
                >
                  {currencies2.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <br />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ mb: 1, alignContent: "center" }}
                    checked={ischecked2}
                    onChange={handleChangeCheckBox2}
                  />
                }
                label="Filtre İçin Tarih  Kullan"
              />
              <br />
              <Box className="FormElement">
                <FormControl
                  className="FormControl"
                  variant="standard"
                  sx={{ fontSize: 12 }}
                  error={error}
                >
                  <FormLabel
                    id="checkbox"
                    color="info"
                    // disabled={!ischecked2}
                    disabled={
                      // !JSON.parse(localStorage.getItem("selectedUserFilter"))
                      //   ?.ischecked2 ||
                      // !JSON.parse(localStorage.getItem("guncel"))?.ischecked2

                      !ischecked2
                    }
                    sx={{ fontSize: 14, ml: -2, mb: 2 }}
                  >
                    *Filtreleme İçin Tarih Çeşidi ve Günü Seçiniz:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-error-radios"
                    value={value}
                    name="dates"
                    onChange={(e) => setDates(e.target.value)}
                  >
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          // checked={
                          //   JSON.parse(localStorage.getItem("multiUserFilter"))
                          //     ?.selectedRadio == 1 || selectedRadio3 == 1
                          // }

                          disabled={!ischecked2}
                          value="1"
                          checked={selectedRadio1 == "1"}
                          onChange={handleChangeRadio}
                          inputProps={{ "aria-label": "givenDate" }}
                        />
                      }
                      label="Görev Veriliş Tarihine Göre"
                    />
                    <FormControlLabel
                      value="2"
                      control={
                        <Radio
                          checked={selectedRadio2 == "2"}
                          disabled={!ischecked2}
                          // checked={
                          //   JSON.parse(localStorage.getItem("multiUserFilter"))
                          //     ?.selectedRadio == 2 || selectedRadio3 == 2
                          // }
                          value="2"
                          onChange={handleChangeRadio}
                          inputProps={{ "aria-label": "StartDate" }}
                        />
                      }
                      label="Görev Başlangıç Tarihine Göre"
                    />
                    <FormControlLabel
                      value="3"
                      control={
                        <Radio
                          checked={selectedRadio3 == "3"}
                          disabled={!ischecked2}
                          // checked={
                          //   JSON.parse(localStorage.getItem("multiUserFilter"))
                          //     ?.selectedRadio == 3 || selectedRadio3 == 3
                          // }
                          value="3"
                          onChange={handleChangeRadio}
                          inputProps={{ "aria-label": "EndDate" }}
                        />
                      }
                      label="Görev Bitiş Tarihine Göre"
                    />
                  </RadioGroup>
                </FormControl>
                {ischecked2 === true && (
                  <DateRange
                    orientation={orientation}
                    startDate={startDate}
                    endDate={endDate}
                    className="daterangepicker"
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    footerContent={false}
                    editableDateInputs={true}
                    value={""}
                    locale={locale}
                    // endDatePlaceholder="End Date"
                    // rangeColors={[theme.palette.primary.main]}
                    // ranges={ranges}
                    //  onChange={ranges => setRanges([ranges.rollup])}
                    // inputRanges={[]}
                    //moveRangeOnFirstSelection={false}
                  />
                )}
              </Box>
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button
              className="clear-btn"
              onClick={HandleClear}
              autoFocus
              sx={{ position: "absolute", right: 850, left: 5, fontSize: 12 }}
            >
              Temizle
            </Button>
            <Button autoFocus onClick={onSearch} variant="contained">
              Uygula
            </Button>
            <Button onClick={handleClose} autoFocus>
              Vazgeç
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
}
