import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  TextField,
  MenuItem,
  Box,
  Paper,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material/";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import "./EditTask.css";
import axios from "axios";

import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Cookies from "js-cookie";
import moment from "moment";
import Swal from "sweetalert2";

const sitil = makeStyles({
  margin: {
    marginTop: 20,
  },
});

const currencies = [
  {
    value: "Yeni Görev",
    text: "Yeni Görev",
  },
  {
    value: "Üzerinde Çalışıyor",
    text: "Üzerinde Çalışıyor",
  },
  {
    value: "Takılmış",
    text: "Takılmış",
  },
  {
    value: "Tamamlandı",
    text: "Tamamlandı",
  },
  {
    value: "Beklemede",
    text: "Beklemede",
  },
];

const newCurrencies = [
  {
    value: "Üzerinde Çalışıyor",
    text: "Üzerinde Çalışıyor",
  },
  {
    value: "Takılmış",
    text: "Takılmış",
  },
  {
    value: "Tamamlandı",
    text: "Tamamlandı",
  },
  {
    value: "Beklemede",
    text: "Beklemede",
  },
];

const currencies2 = [
  {
    value: "Yüksek",
    label: "Yüksek ",
  },
  {
    value: "Orta",
    label: "Orta ",
  },
  {
    value: "Düşük",
    label: "Düşük ",
  },
];

export default function EditTasks(props) {
  const createSitil = sitil();
  const [tasks, setTasks] = useState();
  const [localTasks, setLocalTasks] = useState({});

  const navigate = useNavigate();
  const { id } = useParams();
  const [completeDate, setCompleteDate] = useState();

  const [currency, setCurrency] = useState(currencies[0].value);

  const [currency2, setCurrency2] = useState("Orta");

  const [StartDate, setStartDate] = useState();
  const [EndDate, setEndDate] = useState();
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [newtask, setNewTask] = useState(false);

  const handleChange = (event, value) => {
    setCurrency(event.target.value);

    if (event.target.value === "Tamamlandı") {
      setCompleteDate(
        new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() / 1000
      );
    } else {
      setCompleteDate("");
    }
  };

  const handleChangeStatus = (event) => {
    setCurrency2(event.target.value);
  };

  const onSubmit = async (e) => {
    try {
      if (completeDate !== "" && tasks?.TaskCompleteDate === "") {
        var data = JSON.stringify({
          Action: currency,
          Status: currency2,
          TaskCompleteDate: completeDate,
          StartDate:
            new Date(
              moment(StartDate).format("YYYY.MM.DD HH:mm:ss")
            ).getTime() / 1000,
          EndDate:
            new Date(moment(EndDate).format("YYYY.MM.DD HH:mm:ss")).getTime() /
            1000,
        });
        e.preventDefault();

        var config = {
          method: "post",

          url:
            process.env.react_app_productMode === "true"
              ? process.env.react_app_productUrl + `task/edit/${id}`
              : process.env.react_app_localUrl + `task/edit/${id}`,

          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        await axios(config).then(({ data }) => {
          Swal.fire({
            position: "center",
            icon: data.status ? "success" : "error",
            title: data.msg,
            showConfirmButton: false,
            timer: 1500,
          });

          // setLocalTasks(data?.updated);

          if (JSON.parse(localStorage.getItem("selectedUserLocal"))) {
            const _id = id;

            const storedArray = JSON.parse(
              localStorage.getItem("selectedUserData")
            );
            const localFilter = storedArray?.filter((el) => el._id !== _id);
            localStorage.setItem(
              "selectedUserData",
              JSON.stringify(localFilter)
            );

            const parsedArray = JSON.parse(
              localStorage.getItem("selectedUserData")
            );
            const newArray = [...parsedArray, data?.updated];
            localStorage.setItem("selectedUserData", JSON.stringify(newArray));
          }
          data.status && navigate("/");
        });
      } else if (completeDate !== "" && tasks?.TaskCompleteDate !== "") {
        var data = JSON.stringify({
          Action: currency,
          Status: currency2,
          TaskCompleteDate: completeDate,
        });
        e.preventDefault();
        var config = {
          method: "post",

          url:
            process.env.react_app_productMode === "true"
              ? process.env.react_app_productUrl + `task/edit/${id}`
              : process.env.react_app_localUrl + `task/edit/${id}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        await axios(config).then(({ data }) => {
          Swal.fire({
            position: "center",
            icon: data.status ? "success" : "error",
            title: data.msg,
            showConfirmButton: false,
            timer: 1500,
          });
          // setLocalTasks(data?.updated);
          if (JSON.parse(localStorage.getItem("selectedUserLocal"))) {
            const _id = id;

            const storedArray = JSON.parse(
              localStorage.getItem("selectedUserData")
            );
            const localFilter = storedArray?.filter((el) => el._id !== _id);
            localStorage.setItem(
              "selectedUserData",
              JSON.stringify(localFilter)
            );

            const parsedArray = JSON.parse(
              localStorage.getItem("selectedUserData")
            );
            const newArray = [...parsedArray, data?.updated];
            localStorage.setItem("selectedUserData", JSON.stringify(newArray));
          }

          data.status && navigate("/");
        });
      } else {
        const data = {
          Action: currency,
          Status: currency2,
          StartDate:
            new Date(
              moment(StartDate).format("YYYY.MM.DD HH:mm:ss")
            ).getTime() / 1000,
          EndDate:
            new Date(moment(EndDate).format("YYYY.MM.DD HH:mm:ss")).getTime() /
            1000,
        };
        // console.log(data);

        e.preventDefault();
        var config = {
          method: "post",

          url:
            process.env.react_app_productMode === "true"
              ? process.env.react_app_productUrl + `task/edit/${id}`
              : process.env.react_app_localUrl + `task/edit/${id}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        await axios(config).then(({ data }) => {
          Swal.fire({
            position: "center",
            icon: data.status ? "success" : "error",
            title: data.msg,
            showConfirmButton: false,
            timer: 1500,
          });
          // setLocalTasks(data?.updated);
          if (JSON.parse(localStorage.getItem("selectedUserLocal"))) {
            const _id = id;
            const storedArray = JSON.parse(
              localStorage.getItem("selectedUserData")
            );
            const localFilter = storedArray?.filter((el) => el._id !== _id);
            localStorage.setItem(
              "selectedUserData",
              JSON.stringify(localFilter)
            );

            const parsedArray = JSON.parse(
              localStorage.getItem("selectedUserData")
            );
            const newArray = [...parsedArray, data?.updated];
            localStorage.setItem("selectedUserData", JSON.stringify(newArray));
          }
          data.status && navigate("/");
        });
      }
    } catch (error) {
      console.log(error);
      localStorage.removeItem("guncel");
      localStorage.removeItem("selectedUserFilter");
      localStorage.removeItem("selectedUserFilterDate");

      localStorage.removeItem("selectedUserData");
      localStorage.removeItem("selectedUserLocal");
    }
  };
  const loadTask = async () => {
    axios
      .get(
        process.env.react_app_productMode === "true"
          ? process.env.react_app_productUrl + `task/${id}`
          : process.env.react_app_localUrl + `task/${id}`
      )
      .then(({ data }) => {
        setTasks(data);
        setCurrency(data.Action);
        setCurrency2(data.Status);

        setStartDate(
          moment(new Date(Number(data.StartDate) * 1000)).format(
            "YYYY.MM.DD HH:mm:ss"
          )
        );
        setEndDate(
          moment(new Date(Number(data.EndDate) * 1000)).format(
            "YYYY.MM.DD HH:mm:ss"
          )
        );
      });
  };

  useEffect(() => {
    loadTask();
  }, []);

  const onInputChange = (e) => {
    setTasks({ ...tasks, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    Cookies.get("username");

    // console.log(localFilter);
  }, [currency, currency2, StartDate, EndDate]);

  return (
    <div>
      <Paper>
        <Container className="edittask" sx={{ mt: 5 }}>
          <Box
            component="form"
            onSubmit={(e) => onSubmit(e)}
            className={createSitil}
          >
            <TextField
              value={tasks?.TaskAdmin ?? ""}
              disabled
              label=" Kullanıcı"
              margin="normal"
              name="Username"
              fullWidth
              onChange={(e) => onInputChange(e)}
            >
              Task User
            </TextField>
            <TextField
              margin="normal"
              className="date-given"
              disabled
              id="date"
              label="Görev Veriliş Tarihi "
              name="GivenDate"
              required
              fullWidth
              value={
                moment(new Date(Number(tasks?.GivenDate) * 1000)).format(
                  "YYYY.MM.DD HH:mm:ss"
                ) ?? ""
              }
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              disabled
              value={tasks?.Title ?? ""}
              label="Görev Başlığı"
              margin="normal"
              name="Title"
              fullWidth
              required
              onChange={(e) => onInputChange(e)}
            >
              Task Title
            </TextField>
            <TextField
              disabled
              label="Açıklama"
              margin="normal"
              name="Description"
              value={tasks?.Description ?? ""}
              fullWidth
              required
              multiline
              rows={4}
              onChange={(e) => onInputChange(e)}
            >
              Task Description
            </TextField>
            <TextField
              onClick={() => setNewTask(true)}
              style={{
                borderRadius: "5px",
                backgroundColor: !!(currency === "Takılmış")
                  ? "#EE315D"
                  : currency === "Tamamlandı"
                  ? "#009B35"
                  : currency === "Üzerinde Çalışıyor"
                  ? "#e3711c"
                  : currency === "Beklemede"
                  ? "#0074ff"
                  : "#8363b4",
                color: "black",
              }}
              id="outlined-select-currency"
              select
              name="Action"
              label={"Durum"}
              value={currency}
              margin="normal"
              fullWidth
              onChange={handleChange}
            >
              {tasks?.Action === "Yeni Görev"
                ? currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))
                : newCurrencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
            </TextField>
            {/* <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Durum</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // defaultValue={tasks?.Action}
                value={tasks?.Action}
                label="Age"
                onChange={handleChange}
              >
                <MenuItem value="Üzerinde Çalışıyor">
                  Üzerinde Çalışıyor
                </MenuItem>
                <MenuItem value="Takılmış">Takılmış</MenuItem>
                <MenuItem value="Tamamlandı">Tamamlandı</MenuItem>
                <MenuItem value="Beklemede">Beklemede</MenuItem>
              </Select>
            </FormControl> */}
            {/* <TextField
              disabled={currency !== "Beklemede"}
              label="Öncelik"
              margin="normal"
              name="Statu"
              value={tasks?.Status ?? ""}
              fullWidth
              onChange={(e) => onInputChange(e)}
            >
              Task Description
            </TextField> */}
            <TextField
              disabled={currency !== "Beklemede"}
              id="outlined-select-currency"
              select
              name="Statu"
              label="Öncelik"
              value={currency2}
              margin="normal"
              fullWidth
              onChange={(event) => handleChangeStatus(event)}
            >
              {currencies2.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>{" "}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                disabled={currency !== "Beklemede"}
                className="date-start"
                name="StartDate"
                label="Görev Başlangıç Tarihi"
                variant="filled"
                required
                value={StartDate}
                onChange={(event) => setStartDate(event)}
                open={open2}
                onOpen={() => setOpen2(true)}
                onClose={() => setOpen2(false)}
                margin="normal"
                renderInput={(params) => {
                  return (
                    <TextField
                      margin="normal"
                      fullWidth
                      required
                      {...params}
                      onClick={() =>
                        currency == "Beklemede"
                          ? setOpen2(true)
                          : setOpen2(false)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  );
                }}
              />
              <DateTimePicker
                disabled={currency !== "Beklemede"}
                className="date-start"
                name="EndDate"
                label="Görev Tahmini Bitiş Tarihi"
                variant="filled"
                required
                value={EndDate}
                onChange={(event) => setEndDate(event)}
                open={open3}
                onOpen={() => setOpen3(true)}
                onClose={() => setOpen3(false)}
                margin="normal"
                renderInput={(params) => {
                  return (
                    <TextField
                      margin="normal"
                      fullWidth
                      required
                      {...params}
                      onClick={() =>
                        currency == "Beklemede"
                          ? setOpen3(true)
                          : setOpen3(false)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  );
                }}
              />{" "}
            </LocalizationProvider>
            {/*    <DateTimePicker
                className="date-end"
                disabled={currency !== "Beklemede"}
                name="EndDate"
                label="Görev Tahmini Bitiş Tarihi"
                variant="filled"
                required
                value={tasks?.EndDate}
                onChange={(event) => setEndDate(event.target.value)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth margin="normal" disabled />
                )}
              />

           <DateTimePicker
                type="hidden"
                className="TaskCompleteDate"
                name="TaskCompleteDate"
                label="TaskCompleteDate"
                variant="filled"
                required
                value={tasks?.TaskCompleteDate ?? ""}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="normal"
                    disabled
                    style={{ visibility: "hidden" }}
                  />
                )}
              />*/}
            {/* <TextField
              margin="normal"
              className="date-given"
              disabled
              id="date"
              label="Görev Veriliş Tarihi "
              name="GivenDate"
              required
              fullWidth
              value={
                moment(new Date(Number(tasks?.StartDate) * 1000)).format(
                  "YYYY.MM.DD HH:mm:ss"
                ) ?? ""
              }
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              margin="normal"
              className="date-given"
              disabled
              id="date"
              label="Görev Veriliş Tarihi "
              name="GivenDate"
              required
              fullWidth
              value={
                moment(new Date(Number(tasks?.EndDate) * 1000)).format(
                  "YYYY.MM.DD HH:mm:ss"
                ) ?? ""
              }
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{ shrink: true }}
            /> */}
            <Button
              className="button-edit"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Güncelle
            </Button>
          </Box>
        </Container>
      </Paper>
    </div>
  );
}
