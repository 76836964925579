import "./index.css";
import "./styles.css";
import { BrowserRouter } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";
import Layout from "./components/Layout";
import TaskContextProvider from "./Contexts/TaskContext";
import { ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EE315D",
      secondary: blueGrey,
    },
  },
});

function App() {
  return (
    <div className="App">
      <TaskContextProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Layout />
          </BrowserRouter>
        </ThemeProvider>
      </TaskContextProvider>
    </div>
  );
}

export default App;
