import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
//method girilmezse GET otomatik çalışır.
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const useLogout = () => {
  const { dispatch } = useAuthContext();
  const [hata, setHata] = useState(null);
  const [yukleniyor, setYukleniyor] = useState(false);
  const navigate = useNavigate();

  const logout = async () => {
    setHata(null);
    setYukleniyor(true);
    const userAgent = navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
      Cookies.remove("userID");
      Cookies.remove("username");
      Cookies.remove("permission");
      Cookies.remove("departments");
      dispatch({ type: "LOGOUT" });
      setYukleniyor(false);
      setHata(null);
      navigate("/login");
      return;
    }

    const Username = Cookies.get("username");
    axios
      .post(
        process.env.react_app_productMode === "true"
          ? process.env.react_app_productUrl + "resetNotfcToken"
          : process.env.react_app_localUrl + "resetNotfcToken",
        { Username }
      )
      .then(({ data }) => {
        if (data) {
          Cookies.remove("userID");
          Cookies.remove("username");
          Cookies.remove("permission");
          Cookies.remove("NotfcToken");
          Cookies.remove("departments");
          dispatch({ type: "LOGOUT" });
          setYukleniyor(false);
          setHata(null);
          navigate("/login");
        }
      })
      .catch((error) => {
        console.log(error.message);
        setYukleniyor(false);
        setHata(error.message);
      });
  };

  return { hata, yukleniyor, logout };
};
