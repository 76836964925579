import * as React from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Stack,
  Chip,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
} from "@mui/material/";
import { useEffect, useState } from "react";
import axios from "axios";
import ScheduleIcon from "@mui/icons-material/Schedule";
import "./View.css";
import GroupIcon from "@mui/icons-material/Group";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import Swal from "sweetalert2";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  "& > :not(style) + :not(style)": {
    marginTop: theme.spacing(2),
    textAlign: "left",
  },
}));

export default function View(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [noteID, setNoteID] = useState([]);
  const [ID, setID] = useState([]);
  const { id } = useParams();
  const UserName = Cookies.get("username");
  const UserID = Cookies.get("userID");
  const [currentDate, setCurrentDate] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );
  const [tasks, setTasks] = useState();
  let [notes, setNote] = useState([]);
  let [Users, setUsers] = useState([]);

  if (!window.location.hash) {
    window.location = window.location + "#loaded";
    window.location.reload();
  }

  const loadTask = async () => {
    axios
      .get(
        process.env.react_app_productMode === "true"
          ? process.env.react_app_productUrl + `task/${id}`
          : process.env.react_app_localUrl + `task/${id}`
      )
      .then(({ data }) => setTasks(data));
  };

  const loadNotes = async () => {
    const result = await axios.get(
      process.env.react_app_productMode === "true"
        ? process.env.react_app_productUrl + `task/note/${id}`
        : process.env.react_app_localUrl + `task/note/${id}`
    );

    const Notes = result.data;
    const EditNote = Notes.map((el) => ({ ...el }));
    setNote(EditNote);
  };
  const LoadUser = async () => {
    try {
      var axios = require("axios");
      var config = {
        method: "get",
        url:
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + `Users`
            : process.env.react_app_localUrl + `Users`,
        headers: {},
      };
      const Result = await axios(config);
      const Users = Result.data;
      const EditUsers = Users.map((el) => ({
        ...el,
        label: el.Username,
        value: el.Username,
        UserID: el._id,
      }));
      setUsers(EditUsers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadTask();
    loadNotes();
    LoadUser();
  }, []);

  const RemoveNote = (_id) => {
    setNote((prevRows) => prevRows.filter((row) => row.id !== _id));
    try {
      fetch(
        process.env.react_app_productMode === "true"
          ? process.env.react_app_productUrl + `/note/delete/` + _id
          : process.env.react_app_localUrl + `/note/delete/` + _id,

        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
        }
      );
    } catch (error) {
      alert("Başarısız oldu ");
    }
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  const handleDelete = (_id) => {
    Swal.fire({
      title: "Emin Misiniz?",
      text: "Bu notu silmek üzeresiniz bunu geri alamazsınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "İptal",
      confirmButtonText: "Evet, Sil!",
    }).then((result) => {
      if (result.isConfirmed) {
        RemoveNote(_id);
        Swal.fire("Silindi!", "Bu Not Başarıyla Silindi.", "success");
      }
    });
  };

  const handleEdit = async (e) => {
    var data = {
      Date:
        new Date(moment(currentDate).format("YYYY-MM-DD HH:mm:ssZ")).getTime() /
        1000,

      Note: noteID,
    };

    e.preventDefault();

    var config = {
      method: "post",

      url:
        process.env.react_app_productMode === "true"
          ? process.env.react_app_productUrl + `note/edit/${ID}`
          : process.env.react_app_localUrl + `note/edit/${ID}`,

      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config);
    // console.log(data);
    setOpen(false);
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Görev Başarıyla Güncellendi!",
      showConfirmButton: false,
      timer: 1500,
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (id) => {
    setID(id);
    setOpen(true);
    const findNoteID = notes?.find((el) => el._id == id);
    setNoteID(findNoteID);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const AddNote = (id) => {
    navigate(`/popup/${id}`);
  };

  return (
    <div className="view-class">
      <div>
        <Button variant="contained" onClick={() => AddNote(id)}>
          Not Ekle
        </Button>{" "}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Notunuzu Düzenleyebilirsiniz."}
        </DialogTitle>
        <DialogContent>
          <TextField
            variant="standard"
            name="currentdate"
            size="small"
            disabled
            value={currentDate}
            id="currentdate"
            onChange={(e) => setCurrentDate(e.target.value)}
          />
          <TextField
            sx={{
              wordWrap: "break-word",
              wordBreak: "break-all",
              flex: 1,
              flexWrap: "wrap",
              flexDirection: "row",
              color: "#EE315D",
            }}
            required
            name="note"
            value={noteID?.Note}
            margin="dense"
            id="note"
            fullWidth
            variant="standard"
            multiline
            maxRows={6}
            minRows={4}
            onChange={(e) => setNoteID(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEdit} variant="outlined">
            Kaydet
          </Button>
          <Button onClick={handleClose} autoFocus>
            Vazgeç
          </Button>
        </DialogActions>
      </Dialog>
      <div className="view-title">
        <h2>{tasks?.Title}</h2>
      </div>
      <div className="date-user">
        <div className="users">
          <Typography sx={{ textAlign: "left" }}>
            <i className="user-icon">
              <GroupIcon sx={{ fontSize: 18 }} className="GroupIcon" />{" "}
            </i>
            Kullanıcılar :
            {tasks?.Users.map((task, index) => (
              <label key={index}>
                {task.Username.charAt(0).toUpperCase() + task.Username.slice(1)}
                ({task.Department}),
              </label>
            ))}
          </Typography>
        </div>
        <div className="date">
          <Typography sx={{ textAlign: "left" }}>
            <i className="time-icon">
              <AccessTimeIcon
                sx={{ fontSize: 18 }}
                className="AccessTimeIcon"
              />
            </i>
            Görev Veriliş Tarihi :{" "}
            {moment(new Date(Number(tasks?.GivenDate) * 1000)).format(
              "YYYY.MM.DD HH:mm:ss"
            )}
          </Typography>
        </div>
      </div>
      <div className="action-statu">
        <Stack direction="row" spacing={1}>
          <Chip
            label={`Durum : ${tasks?.Action}`}
            color="primary"
            variant="outlined"
            sx={{ height: 21 }}
          />
          <Chip
            label={`Statu : ${tasks?.Status}`}
            color="primary"
            variant="outlined"
            sx={{ height: 21 }}
          />
        </Stack>
      </div>
      <Root className="notes">
        <Box sx={{ textAlign: "left" }}>
          <h3 style={{ marginBottom: "2px" }}>Açıklama</h3>
          <Divider></Divider>
          <br />
          <Typography variant="subtitle2" gutterBottom component="div">
            {tasks?.Description}
          </Typography>

          <br />
          <h3 style={{ marginBottom: "2px" }}>Notlar</h3>
          <Divider textAlign="left"></Divider>
          {notes.map((note) => (
            <ul key={note._id} className="list">
              <List
                sx={{
                  width: "100%",
                  maxWidth: 600,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem alignItems="flex-start">
                  {Users?.filter((el) => el.UserID == note.UserID).map(
                    (ele, i) => (
                      <ListItemAvatar key={i}>
                        <Avatar
                          // alt={note.Username.toUpperCase()}
                          src={ele.ProfilePic}
                        />
                      </ListItemAvatar>
                    )
                  )}

                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="caption"
                          color="text.main"
                        >
                          Eklenme Tarihi:
                        </Typography>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="caption"
                          color="text.secondary"
                        >
                          {moment(new Date(Number(note.Date) * 1000)).format(
                            "YYYY.MM.DD HH:mm:ss"
                          )}
                        </Typography>
                        <br />

                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="caption"
                          color="black"
                        >
                          <span> Ekleyen:</span>
                        </Typography>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="caption"
                          color="text.secondary"
                        >
                          <span>
                            {note.Username}({note.Department})
                          </span>
                        </Typography>

                        <br />
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{
                            whiteSpace: "pre-line",
                            color: "black ",
                          }}
                          component="span"
                          variant="subtitle1"
                        >
                          Not:
                        </Typography>

                        <Typography
                          sx={{
                            whiteSpace: "pre-line",
                          }}
                          component="span"
                          variant="subtitle1"
                        >
                          {note.Note}
                        </Typography>
                        <br />
                      </React.Fragment>
                    }
                  />

                  {note.Username == UserName && (
                    <IconButton
                      onClick={() => handleDelete(note._id)}
                      aria-label="edit"
                    >
                      <DeleteIcon className="noteEdit" />
                    </IconButton>
                  )}
                  {note.Username == UserName && (
                    <IconButton
                      onClick={() => handleClickOpen(note._id)}
                      aria-label="edit"
                    >
                      <EditIcon className="noteEdit" />
                    </IconButton>
                  )}
                </ListItem>
                {/* <Divider variant="inset" component="li" /> */}
              </List>
            </ul>
          ))}
        </Box>
      </Root>
      <br />
      <Root>
        <br />
        <Divider textAlign="left"></Divider>
        <List
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            maxWidth: 300,
            bgcolor: "background.paper",
          }}
        >
          {/*
            <li>
            <Typography
              sx={{ mt: 0.5, ml: 1 }}
              color="text.secondary"
              display="block"
              variant="caption"
            >
              Start
            </Typography>
          </li> */}
          <ListItem>
            <ListItemText
              primary="Görev Başlangıç Tarihi"
              secondary={moment(
                new Date(Number(tasks?.StartDate) * 1000)
              ).format("YYYY.MM.DD HH:mm:ss")}
            />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText
              primary="Görev Tahmini Bitiş Tarihi"
              secondary={moment(new Date(Number(tasks?.EndDate) * 1000)).format(
                "YYYY.MM.DD HH:mm:ss"
              )}
            />
          </ListItem>
          <Divider component="li" variant="inset" />
          {/* <li>
            <Typography
              sx={{ mt: 0.5, ml: 9 }}
              color="text.secondary"
              display="block"
              variant="caption"
            >
              End
            </Typography>
          </li> */}
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ ml: 0 }}>
                <ScheduleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Görev Bitiş Tarihi "
              secondary={
                tasks?.TaskCompleteDate
                  ? moment(
                      new Date(Number(tasks?.TaskCompleteDate) * 1000)
                    ).format("YYYY.MM.DD HH:mm:ss")
                  : ""
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
      </Root>
      <br />
    </div>
  );
}
