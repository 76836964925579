import axios from "axios";
import "./Profile.css";
import {
  Avatar,
  TextField,
  Button,
  Container,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const Accordion = styled((AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...AccordionProps} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...AccordionSummaryProps}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Dene() {
  const currentDate =
    new Date(moment().format("YYYY-MM-DD 23:59:59Z")).getTime() / 1000;

  let startDate =
    new Date(
      moment().startOf("week").format("YYYY-MM-DD 23:59:59Z")
    ).getTime() / 1000;

  let endDate =
    new Date(moment().endOf("week").format("YYYY-MM-DD 23:59:59Z")).getTime() /
    1000;

  let FutureStartDate =
    new Date(
      moment().startOf("week").add(7, "days").format("YYYY-MM-DD 23:59:59Z")
    ).getTime() / 1000;

  let FutureEndDate =
    new Date(
      moment().endOf("week").add(7, "days").format("YYYY-MM-DD 23:59:59Z")
    ).getTime() / 1000;

  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const Permission = Cookies.get("permission");
  const UserID = Cookies.get("userID");
  const Department = Cookies.get("departments");
  const ProfilePic = Cookies.get("ProfilePic");
  const username = Cookies.get("username");

  const [photoUrl, setPhotoUrl] = useState();
  const [gidecekData, setGidecekData] = useState({});
  const [picture, setPicture] = useState(null);
  const [today, setToday] = useState([]);
  const [lastDate, setLastDate] = useState([]);
  const [thisWeek, setThisWeek] = useState([]);
  const [futureWeek, setFutureWeek] = useState([]);

  const [data, setdata] = useState([]);

  const loadTasks = async () => {
    try {
      var axios = require("axios");
      const userCheck = {
        Permission: Permission,
        UserID: UserID,
        Department: Department,
      };
      axios

        .post(
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "tasks"
            : process.env.react_app_localUrl + "tasks",

          userCheck
        )
        .then(({ data }) => {
          const HelperArray = data?.map((el) => ({ ...el, id: el._id }));
          setdata(HelperArray);
          const findDate = data?.filter(
            (el) => el.EndDate == currentDate && el.TaskCompleteDate == ""
          );
          setToday(findDate);
          const lastDate = data?.filter(
            (el) => el.EndDate < currentDate && el.TaskCompleteDate == ""
          );
          setLastDate(lastDate);
          const thisWeek = data?.filter(
            (el) =>
              el.EndDate >= startDate &&
              el.EndDate <= endDate &&
              el.TaskCompleteDate == ""
          );
          setThisWeek(thisWeek);

          const FutureWeek = data?.filter(
            (el) =>
              el.EndDate >= FutureStartDate &&
              el.EndDate <= FutureEndDate &&
              el.TaskCompleteDate == ""
          );
          setFutureWeek(FutureWeek);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      gidecekData?.Password === gidecekData?.ConfirmPassword &&
      gidecekData?.Password !== "" &&
      gidecekData?.ConfirmPassword !== ""
    ) {
      const fd = new FormData();
      fd.append("image", picture);
      axios
        .post(
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl +
                `useredit/${UserID}/${gidecekData?.Password}`
            : process.env.react_app_localUrl +
                `useredit/${UserID}/${gidecekData?.Password}`,
          fd
        )
        .then(({ data }) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Güncelleme Başarılı",
            showConfirmButton: false,
            timer: 2800,
          });
          Cookies.set("ProfilePic", photoUrl);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Şifreler Eşit Değil!",
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };
  useEffect(() => {
    loadTasks();
    Cookies.get("ProfilePic");
  }, [ProfilePic]);

  return (
    <div>
      <div className="profile">
        <form onSubmit={(e) => onSubmit(e)}>
          <Accordion
            className="accordionProfile"
            square
            expanded={expanded === "panel0"}
            onChange={handleChange("panel0")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <h2> Hesap Ayarları</h2>
            </AccordionSummary>
            <Paper
              sx={{ mt: 5 }}
              variant="outlined"
              square
              elevation={0}
              className="profile-paper"
            >
              <Container className="profile-container">
                <Typography
                  sx={{
                    mt: 4,
                    textAlign: "center",
                    color: "#EE315D",
                  }}
                >
                  ♦ Şifre veya Profil Fotoğrafınızı Güncelleyebilirsiniz.
                </Typography>

                <br />
                <div className="text-pic">
                  <div className="profile-pic">
                    <label htmlFor="profilePhoto">
                      <Avatar
                        src={picture === null ? ProfilePic : photoUrl}
                        sx={{
                          width: 100,
                          height: 100,
                          cursor: "pointer",
                        }}
                      />

                      <Typography sx={{ ml: -20, mb: 4 }}>
                        {username}
                      </Typography>
                      <input
                        type="file"
                        name="image"
                        onChange={(e) => {
                          setPicture(e.target.files[0]);
                          setPhotoUrl(URL.createObjectURL(e.target.files[0]));
                        }}
                        accept="image/*"
                      />
                    </label>
                  </div>
                  <TextField
                    size="small"
                    helperText="En az 4 karakterlik şifrenizi belirleyiniz! "
                    name="password"
                    type="password"
                    autoFocus
                    label="Yeni Şifreyi Giriniz."
                    margin="normal"
                    inputProps={{ minLength: 4 }}
                    variant="standard"
                    onChange={(e) =>
                      setGidecekData({
                        ...gidecekData,
                        Password: e.target.value,
                      })
                    }
                  />
                  <br />
                  <TextField
                    size="small"
                    helperText="İlk girdiğiniz parola ile aynı olmasına dikkat ediniz. "
                    name="confirmPassword"
                    type="password"
                    label="Yeni Şifreyi Tekrar Giriniz."
                    margin="normal"
                    inputProps={{ minLength: 4 }}
                    variant="standard"
                    onChange={(e) =>
                      setGidecekData({
                        ...gidecekData,
                        ConfirmPassword: e.target.value,
                      })
                    }
                  />
                  <br />
                  <Button
                    className="button-save"
                    type="submit"
                    name="upload"
                    variant="contained"
                    color="secondary"
                  >
                    Güncelle
                  </Button>
                </div>
              </Container>
            </Paper>{" "}
          </Accordion>
        </form>
      </div>

      <div>
        <Paper elevation={0} className="profile-paper">
          <div className="view-title">
            <h1 style={{ marginLeft: "10px", marginBottom: "30px" }}>
              Görevler /
              <span style={{ fontWeight: "lighter", fontSize: 22 }}>
                {" "}
                {data?.length}
              </span>
            </h1>
          </div>
          <Divider />
        </Paper>
      </div>
      <div className="Accordions">
        {" "}
        <Accordion
          sx={{
            border: "1px solid black",
            mt: 5,
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
          }}
          className="accordion"
          square
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
              Gecikmiş /
              <span style={{ fontWeight: "lighter", fontSize: 14 }}>
                {" "}
                {lastDate?.length} görev
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {lastDate?.length > 0 && (
                <Typography
                  sx={{
                    fontSize: 14,
                    paddingLeft: 3,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    mb: 1,
                  }}
                >
                  <span
                    className="titleSpan"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  ></span>

                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Açıklama
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Kullanıcılar
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Durum
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "20%",
                      overflow: "initial",
                    }}
                  >
                    Öncelik
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Başlangıç-Bitiş Tarihi
                  </span>
                </Typography>
              )}
            </div>
            {lastDate?.map((el) => (
              <Typography
                key={el._id}
                sx={{
                  border: "0.5px solid lightgray  ",
                  fontSize: 14,
                  paddingLeft: 1,
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: "5px",
                  mb: 1,
                  backgroundColor: "blueviolet",
                }}
              >
                <span
                  className="titleSpan"
                  style={{
                    borderRight: "0.5px solid lightgray",
                    backgroundColor: "white",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {" "}
                  {el.Title}
                </span>

                <span
                  style={{
                    borderRight: "0.5px solid lightgray",
                    backgroundColor: "white",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {" "}
                  {el.Description}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {" "}
                  {el.Users.map((el) => el.Username).join("," + " ")}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",

                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {el.Action}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",

                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "20%",
                    overflow: "initial",
                  }}
                >
                  {el.Status}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {moment(new Date(Number(el.StartDate) * 1000)).format(
                    "YYYY.MM.DD "
                  )}
                  -{" "}
                  {moment(new Date(Number(el.EndDate) * 1000)).format(
                    "YYYY.MM.DD "
                  )}
                </span>
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ mt: 2 }}
          className="accordion"
          square
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography
              sx={{ paddingLeft: "4px", fontWeight: "bold", fontSize: 15 }}
            >
              Bugün /
              <span style={{ fontWeight: "lighter", fontSize: 14 }}>
                {" "}
                {today?.length} görev
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {today?.length > 0 && (
                <Typography
                  sx={{
                    fontSize: 14,
                    paddingLeft: 3,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    mb: 1,
                  }}
                >
                  <span
                    className="titleSpan"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  ></span>

                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Açıklama
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Kullanıcılar
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Durum
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "20%",
                      overflow: "initial",
                    }}
                  >
                    Öncelik
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Başlangıç-Bitiş Tarihi
                  </span>
                </Typography>
              )}
            </div>
            {today?.map((el) => (
              <Typography
                key={el._id}
                sx={{
                  fontSize: 14,
                  paddingLeft: "20px",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  border: "0.5px solid lightgray  ",
                  borderRadius: "5px",
                  mb: 1,
                  pl: 1,
                  backgroundColor: "blueviolet",
                }}
              >
                <span
                  className="titleSpan"
                  style={{
                    borderRight: "0.5px solid lightgray",
                    backgroundColor: "white",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {el.Title}
                </span>

                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {" "}
                  {el.Description}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {" "}
                  {el.Users.map((el) => el.Username).join("," + " ")}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {el.Action}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {el.Status}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "5px",
                    paddingRight: "5px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {moment(new Date(Number(el.StartDate) * 1000)).format(
                    "YYYY.MM.DD "
                  )}
                  -{" "}
                  {moment(new Date(Number(el.EndDate) * 1000)).format(
                    "YYYY.MM.DD "
                  )}
                </span>
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ mt: 2 }}
          className="accordion"
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
            <Typography
              sx={{ paddingLeft: "4px", fontWeight: "bold", fontSize: 15 }}
            >
              Bu Hafta /
              <span style={{ fontWeight: "lighter", fontSize: 14 }}>
                {" "}
                {thisWeek?.length} görev
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {thisWeek?.length > 0 && (
                <Typography
                  sx={{
                    fontSize: 14,
                    paddingLeft: 3,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    mb: 1,
                  }}
                >
                  <span
                    className="titleSpan"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  ></span>

                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Açıklama
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Kullanıcılar
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Durum
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "20%",
                      overflow: "initial",
                    }}
                  >
                    Öncelik
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Başlangıç-Bitiş Tarihi
                  </span>
                </Typography>
              )}
            </div>
            {thisWeek?.map((el) => (
              <Typography
                key={el._id}
                sx={{
                  fontSize: 14,
                  paddingLeft: "20px",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  border: "0.5px solid lightgray",
                  borderRadius: "5px",
                  mb: 1,
                  pl: 1,
                  backgroundColor: "blueviolet",
                }}
              >
                <span
                  className="titleSpan"
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {el.Title}
                </span>

                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {" "}
                  {el.Description}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {" "}
                  {el.Users.map((el) => el.Username).join("," + " ")}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {el.Action}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {el.Status}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "5px",
                    paddingRight: "5px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {moment(new Date(Number(el.StartDate) * 1000)).format(
                    "YYYY.MM.DD "
                  )}
                  -{" "}
                  {moment(new Date(Number(el.EndDate) * 1000)).format(
                    "YYYY.MM.DD "
                  )}
                </span>
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ mt: 2 }}
          className="accordion"
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
            <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
              Gelecek Hafta /
              <span style={{ fontWeight: "lighter", fontSize: 14 }}>
                {" "}
                {futureWeek?.length} görev
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {futureWeek?.length > 0 && (
                <Typography
                  sx={{
                    fontSize: 14,
                    paddingLeft: 3,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                  }}
                >
                  <span
                    className="titleSpan"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  ></span>

                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Açıklama
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Kullanıcılar
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Durum
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "20%",
                      overflow: "initial",
                    }}
                  >
                    Öncelik
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "30%",
                      overflow: "initial",
                    }}
                  >
                    Başlangıç-Bitiş Tarihi
                  </span>
                </Typography>
              )}
            </div>
            {futureWeek?.map((el) => (
              <Typography
                key={el._id}
                sx={{
                  fontSize: 14,
                  paddingLeft: "20px",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  border: "0.5px solid lightgray",
                  borderRadius: "5px",
                  mb: 1,
                  pl: 1,
                  backgroundColor: "blueviolet",
                }}
              >
                <span
                  className="titleSpan"
                  style={{
                    borderRight: "0.5px solid lightgray",
                    backgroundColor: "white",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {el.Title}
                </span>

                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {" "}
                  {el.Description}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {" "}
                  {el.Users.map((el) => el.Username).join("," + " ")}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {el.Action}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",

                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {el.Status}
                </span>
                <span
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.5px solid lightgray",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "30%",
                    overflow: "initial",
                  }}
                >
                  {moment(new Date(Number(el.StartDate) * 1000)).format(
                    "YYYY.MM.DD "
                  )}
                  -{" "}
                  {moment(new Date(Number(el.EndDate) * 1000)).format(
                    "YYYY.MM.DD "
                  )}
                </span>
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
