import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Paper } from "@mui/material/";
import { TaskContext } from "../../Contexts/TaskContext";
import "./Login.css";
import Cookies from "js-cookie";
import { useAuthContext } from "../../hooks/useAuthContext";
import Swal from "sweetalert2";
import logo from "../../assets/logoblack.svg";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getToken } from "firebase/messaging";
import { messaging } from "../../utils/firebase";

export default function Login() {
  const { dispatch } = useAuthContext();
  const { setuserID } = useContext(TaskContext);
  const { setPermission } = useContext(TaskContext);
  const { setDepartment } = useContext(TaskContext);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        login();
      }
    };
    localStorage.removeItem("filters");
  }, []);

  const handleClikShowPassword = () => {
    setShowPassword(true);
  };

  const handleForgetPassword = () => {
    Swal.fire({
      title: "Lütfen e-posta adresinizi giriniz.",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Mail Gönder",
      cancelButtonText: "İptal Et",
      confirmButtonColor: "#EE315D",
      showLoaderOnConfirm: true,
      inputValidator: (girilenMail) => {
        if (!girilenMail) {
          return "Sistemde Kayıtlı Mail Adresinizi Giriniz !";
        }
      },
      preConfirm: async (girilenMail) => {
        const { data } = await axios.post(
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + `forgotPassword`
            : process.env.react_app_localUrl + `forgotPassword`,
          { girilenMail: girilenMail }
        );

        if (data.status) {
          return Swal.fire("İşlem Başarılı!", `${data.msg}`, "success");
        } else {
          return Swal.fire(
            "Dikkat Geçersiz Mail Adresi !",
            `${data.msg}`,
            "error"
          );
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  function login() {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
      console.log("cihaz iphone veya ipad");
      const userCheck = {
        Username: Username,
        Password: Password,
      };

      axios
        .post(
          process.env.react_app_productMode === "true"
            ? process.env.react_app_productUrl + "login"
            : process.env.react_app_localUrl + "login",
          userCheck
        )
        .then(({ data }) => {
          if (data.status === true) {
            const UserID = data.user._id;
            const Permission = data.user.Permission;
            const Department = data.user.Department;
            setuserID(UserID);
            setPermission(Permission);
            setDepartment(Department);
            Cookies.set("username", data.user.Username);
            Cookies.set("permission", data.user.Permission);
            Cookies.set("userID", data.user._id);
            Cookies.set("departments", data.user.Department);
            Cookies.set("ProfilePic", data.user.ProfilePic);

            localStorage.removeItem("guncel");
            localStorage.removeItem("selectedUserFilter");
            localStorage.removeItem("selectedUserFilterDate");

            localStorage.removeItem("selectedUserData");
            localStorage.removeItem("selectedUserLocal");

            navigate("/");

            dispatch({
              type: "LOGIN",
              payload: Cookies.get("username"),
            });
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Oturum Başarı ile Açıldı!",
              showConfirmButton: false,
              timer: 2000,
            });

            setTimeout(() => {
              window.location.reload();
            }, 2500);
          } else {
            Swal.fire({
              title: "HATA!",
              text: "Bilgilerinizi Kontrol Ediniz !",
              icon: "error",
              confirmButtonText: "OK",
            });
            setOpen(true);
          }
        });
    } else {
      Notification.requestPermission()
        .then((permission) => {
          if (permission == "denied") {
            return Swal.fire(
              "Uyarı",
              "Lütfen site bildirimini açın, Aksi halde giriş yapılamayacaktır."
            );
          }

          getToken(messaging, {
            vapidKey:
              "BNpDhuwSv8Pq4Vq4mY9_4uZ526COBP47IAjB7_rVvbhmCY-xTyHLBFo0TreWyucwhbBmMUlwML-veJ12KQcsAMY",
          }).then((currentToken) => {
            console.log(currentToken);
            if (currentToken) {
              const userCheck = {
                Username: Username,
                Password: Password,
                NotfcToken: currentToken,
              };

              axios
                .post(
                  process.env.react_app_productMode === "true"
                    ? process.env.react_app_productUrl + "login"
                    : process.env.react_app_localUrl + "login",
                  userCheck
                )
                .then(({ data }) => {
                  if (data.status === true) {
                    const UserID = data.user._id;
                    const Permission = data.user.Permission;
                    const Department = data.user.Department;
                    setuserID(UserID);
                    setPermission(Permission);
                    setDepartment(Department);
                    Cookies.set("username", data.user.Username);
                    Cookies.set("permission", data.user.Permission);
                    Cookies.set("userID", data.user._id);
                    Cookies.set("departments", data.user.Department);
                    Cookies.set("ProfilePic", data.user.ProfilePic);
                    Cookies.set("NotfcToken", currentToken);

                    localStorage.removeItem("guncel");
                    localStorage.removeItem("selectedUserFilter");
                    localStorage.removeItem("selectedUserFilterDate");

                    localStorage.removeItem("selectedUserData");
                    localStorage.removeItem("selectedUserLocal");

                    navigate("/");
                    dispatch({
                      type: "LOGIN",
                      payload: Cookies.get("username"),
                    });
                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Oturum Başarı ile Açıldı!",
                      showConfirmButton: false,
                      timer: 2000,
                    });
                    setTimeout(() => {
                      window.location.reload();
                    }, 2500);
                  } else {
                    Swal.fire({
                      title: "HATA!",
                      text: "Bilgilerinizi Kontrol Ediniz !",
                      icon: "error",
                      confirmButtonText: "OK",
                    });
                    setOpen(true);
                  }
                });
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
            }
          });
        })
        .catch(console.log);
    }
  }

  return (
    <div className="login">
      <Paper variant="outlined" square elevation={0}>
        <Container className="container">
          <div className="arasta-logo">
            <img alt="logo" src={logo} style={{ height: 150, width: 200 }} />
            <Typography
              variant="button"
              display="block"
              color={"CaptionText"}
              gutterBottom
              style={{ fontWeight: 700, textAlign: "center" }}
            >
              <br />
              Görev Takip Sistemi Kullanıcı Girişi
            </Typography>
          </div>

          <FormControl fullWidth sx={{ mt: 5 }}>
            <InputLabel htmlFor="username" />
            <TextField
              sx={{ ml: 1, mr: 1 }}
              className="User-text"
              required
              onKeyPress={(e) => e.key === "Enter" && login()}
              onChange={(e) => setUsername(e.target.value)}
              id="username"
              label="Kullanıcı Adı"
            />
          </FormControl>
          <FormControl fullWidth sx={{ my: 5 }}>
            <InputLabel htmlFor="password" />
            <TextField
              sx={{ ml: 1, mr: 1 }}
              required
              onKeyPress={(e) => e.key === "Enter" && login()}
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              label="Parola"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle Password"
                      onClick={handleClikShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <span
            onClick={handleForgetPassword}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Şifremi Unuttum
          </span>
          <Button
            className="button-login"
            variant="contained"
            color="secondary"
            type="submit"
            size="large"
            sx={{ mt: 5 }}
            onClick={login}
          >
            Giriş
          </Button>
        </Container>
      </Paper>
    </div>
  );
}
